

export default function shallowMerge(obj1, obj2) {
	let merged = { ...obj1 }

	for (let key in obj2) {
		if (obj2.hasOwnProperty(key)) {
			// If both objects have the same key and their values are objects, merge them
			if (typeof obj2[key] === 'object' && obj2[key] !== null && typeof merged[key] === 'object' && merged[key] !== null) {
				merged[key] = {
					...merged[key],
					...obj2[key]
				}
			} else {
				// Otherwise, simply set/overwrite the value in the merged object
				merged[key] = obj2[key]
			}
		}
	}
    return merged
}

import { useState } from 'react'
import { gql, useQuery, useLazyQuery } from '@apollo/client'
import Title from '../utils/Title'
import { useImmer } from 'use-immer'

import { GET_COLLECTIONS } from '../layout/Collections'
import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'
import useCollection from './useCollection'

const EXPORT_COLLECTION = gql`
	query Export($collectionId:ID!, $format:String!, $fields:[String]!) {
		export(collectionId:$collectionId, format:$format, fields:$fields)
	}`


export default function ExportsPage() {

	const { collection } = useCollection()
	const [format, setFormat] = useState('csv')
	const [fields, setFields] = useImmer(collection.fields.map(field => field.fieldId))

	const [exportCollection, { loading: exportLoading, error: exportError }] = useLazyQuery(EXPORT_COLLECTION)

	function handleCheckField(event) {
		const fieldId = event.target.value
		setFields(draft => {
			if (draft.includes(fieldId)) draft.splice(draft.indexOf(fieldId), 1)
				else draft.push(fieldId)
		})
	}

	async function handleExport() {
		const response = await exportCollection({ variables: { 
			collectionId: collection.collectionId, 
			format,
			fields,
		} })
		const csv = response?.data?.export
		if (!!csv) downloadCSV(csv, `${collection.collectionId}.csv`)
		console.log('response', response)
	}

	return <>

		<Title>Export</Title>

		<fieldset>
			<legend>Format</legend>
			<div>
				<label><input type='checkbox' onChange={event => setFormat('csv')} checked={format==='csv'} />CSV</label>
			</div>
		</fieldset>

		<fieldset>
			<legend>Fields</legend>
			<div>
				{collection.fields.map(field => 
					<label key={field.fieldId}>
						<input type='checkbox' value={field.fieldId} onChange={handleCheckField} checked={fields.includes(field.fieldId)} />{field.fieldId}
					</label>
				)}
			</div>
		</fieldset>

		<button onClick={() => handleExport()} disabled={exportLoading}>Export</button>
		{ exportLoading && <Spinner /> }
		{ !!exportError && <Alert error={exportError} /> }

	</>
}


function downloadCSV(csvString, filename) {
	var blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
	var url = URL.createObjectURL(blob)
	var downloadLink = document.createElement("a")
	downloadLink.href = url
	downloadLink.download = filename
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
	URL.revokeObjectURL(url);
}

import { createContext } from 'react'
import { gql, useQuery } from '@apollo/client'

import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'

export const GET_COLLECTION = gql`
	query GetCollection($collectionId: ID!) {
		collection(collectionId: $collectionId) {
			collectionId
			name
			icon
			fields
			columns
			snippet
			title
		}
	}
`

export const CollectionContext = createContext({})


export default function CollectionProvider({ collectionId, children }) {

	const { error, data } = useQuery(GET_COLLECTION, { 
		variables: { collectionId },
		fetchPolicy: 'cache-and-network',
	})
	const collection = data?.collection || null

	if (error) return <Alert error={error} />
	if (!collection) return <Spinner />

	return <CollectionContext.Provider value={collection}>
		{ children }
	</CollectionContext.Provider>
}


import Field from './Field.js'
import TextField from './TextField.js'


export default class FileField extends Field {

	type = 'FILE'
	_columnType = 'TEXT'
	_gqlType = 'DaisyFile'

	// isReferenceType = true

	constructor(initVals) {
		super(initVals)
	}

	_parseValue(value) {
		return TextField.parseValue(value)
	}
	
}
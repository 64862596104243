
import { useMemo, useState, useEffect } from 'react'
import { useQuery, gql, useLazyQuery } from '@apollo/client'
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move'
import { produce } from 'immer'

// import useCollection from '../collections/useCollection'
import EntrySnippet from '../collections/EntrySnippet'
import Entries from '../entries/Entries'
// import CollectionProvider from '../collections/CollectionProvider'
import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'
// import { GET_COLLECTION } from '../collections/CollectionProvider'

const GET_ENTRY_SNIPPETS = gql`
	query GetReferenceFieldEntrySnippets($collectionId:ID!, $entryIds:[ID]) {
		entrySnippets(collectionId:$collectionId, entryIds:$entryIds) {
			collectionId
			entryId
			values
		}
	}
`

const GET_COLLECTION = gql`
	query GetCollectionStuff($collectionId: ID!) {
		collection(collectionId: $collectionId) {
			collectionId
			name
			icon
			fields
			snippet
			title
		}
	}
`


export default function ReferenceInput({ field, value, update }) {

	const { loading, error, data } = useQuery(GET_COLLECTION, { 
		variables: { collectionId: field.config?.collectionId || '' },
		// fetchPolicy: 'network-only',
	})	

	if (loading) return <Spinner />
	if (error) return <Alert error={error} />
	if (!data?.collection) return <Alert>No collection</Alert>

	return <ReferenceInputPicker field={field} value={value} update={update} collection={data.collection} />
}


function ReferenceInputPicker({ field, value, update, collection }) {

	// const { collection } = useCollection()
	const [showPicker, setShowPicker] = useState(false)

	const selected = useMemo(() => 
		Array.isArray(value)
			? value : (typeof(value) === 'string')
			? [value] : []
	, [value])

	const [loadEntries, { loading, error, data }] = useLazyQuery(GET_ENTRY_SNIPPETS)

	const entries = useMemo(() => 
		selected.map(entryId => data?.entrySnippets.find(e => e.entryId === entryId) ?? { entryId })
	, [selected, data])

	useEffect(() => {
		// if (!selected.length || !collection) return;
		// console.log('loadEntries', selected)
		loadEntries({ variables: {
			collectionId: collection.collectionId,
			entryIds: selected,
		}})
	}, [selected, collection])

	function handleChange(entryIds, hidePicker = true) {
		if (!Array.isArray(entryIds)) entryIds = []
		update(!!field.config?.multi ? entryIds : (entryIds[0] || null))
		if (!field.config?.multi && hidePicker) setShowPicker(false)
	}

	// const handleSelect = useCallback((entryId, add = true) => {
	// 	const value = produce(selected, draft => {
	// 		const idx = draft.indexOf(entryId)
	// 		if (add && idx === -1) {
	// 			draft.push(entryId)
	// 		} else if (idx > -1) draft.splice(idx, 1)
	// 	})
	// 	// console.log('handleSelect', value)
	// 	if (value.length === 0) return update(null)
	// 	if (!field.config?.multi) {
	// 		update(value[0])
	// 		setShowPicker(false)
	// 		return
	// 	}
	// 	update(value)
	// }, [selected])

	function handleRemove(entryIdx) {
		handleChange(produce(selected, draft => { draft.splice(entryIdx, 1)}), false)
	}

	function handleReorder(entryIds) {
		update(entryIds)
	}

	return <>

		<div className='FieldInput REFERENCEinput'>

			{ (!!selected.length && !!collection) && <>
				<EntriesSortableList 
					multi={field.config.multi}
					collection={collection} 
					entries={entries} 
					handleRemove={handleRemove} 
					onReorder={handleReorder} 
					hideMeta 
				/>
				{ loading && <Spinner /> }
			</>}

		</div>

		<div className='FieldExtras REFERENCEextras'>

			{ !!error && <Alert error={error} /> }

			{ showPicker
				?	<button onClick={() => setShowPicker(false)}>Done</button>
				:	<button onClick={() => setShowPicker(true)}>Pick from {collection?.icon} {collection?.name}</button>
			}

			{ showPicker && <div className='ReferenceInputEntries'>
				<Entries
					forcedCollection={collection}
					selected={selected}
					selectMode={true}
					onSelected={handleChange}
				/>
			</div>}

		</div>

	</>
}


function EntriesSortableList({ 
	collection, 
	entries, 
	handleRemove,
	onReorder,
	multi
}) {

	function onSortEnd(oldIndex, newIndex) {
		const newOrder = arrayMoveImmutable(entries.map(e => e.entryId), oldIndex, newIndex)
		onReorder(newOrder)
	}

	return <SortableList className='GridTable REFERENCEgrid' 
		draggedItemClassName='dragged' 
		onSortEnd={onSortEnd} 
		allowDrag={!!multi}
		style={{ '--columns': collection.snippet.length + (!!multi ? 2 : 1) }}
	>
		{ entries.map((entry, idx) =>
			<SortableItem key={entry.entryId}>
				<div className='Item'>

					{ !!multi && <SortableKnob><div>↕️</div></SortableKnob> }

					{ !entry.values
						?	<Alert>Missing entry {entry.entryId}</Alert>
						:	<EntrySnippet key={entry.entryId} 
								snippet={collection.snippet} 
								entry={entry}
								hideMeta={true}
							/>
					}

					<div>
						<button onClick={() => handleRemove(idx)}>x</button> 
					</div>

					{/* <ReferenceEntry entry={entry} snippet={collection.snippet} handleRemove={handleRemove} /> */}

				</div>
			</SortableItem>
		)}
	</SortableList>
}

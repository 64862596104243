
import { useQuery, gql } from '@apollo/client'
import { NavLink, Link } from 'react-router-dom'

import UserSnippet from './UserSnippet'
import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'
import Title from '../utils/Title'

export const GET_USERS = gql`
	query GetUsers {
		users {
			userId
			name
			email
			emailHash
			role
		}
	}`


export default function UsersList() {

	const { loading, error, data } = useQuery(GET_USERS)
	const users = data?.users || []

	return <>

		<Title>Users</Title>
		<div className='Heading'>
			<h1>Users</h1>
			<nav>
				<NavLink to='/users/add'>Add user</NavLink>
			</nav>
		</div>

		<Alert error={error} />

		<div id='UsersList' className='ItemsList'>
			{ users.map(user => 
				<Link to={`/users/${user.userId}`} 
					key={user.userId}
				>
					<UserSnippet user={user} />
				</Link>
			)}
		</div>

		{ loading && <Spinner /> }

	</>
}

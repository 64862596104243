
import { useState, useCallback, useEffect } from 'react'


export default function IdInput({ 
	field, 
	value, 
	update 
}) {

	const { config } = field
	const [draft, setDraft] = useState(value || "")
	
	const [badRegexp, setBadRegexp] = useState(false)
	const placeholder = config.placeholder || config.regExp || ''

	const onChange = useCallback((event) => {
		let value = event.target.value
		if (config.maxLength > 0) value = value.slice(0, config.maxLength)
		if (config.noNewline) value = value.replace(/\n\r?/g, '')
		if (config.regExp) {
			if (value === '') {
				setBadRegexp(false)
			} else {
				const re = new RegExp(config.regExp)
				setBadRegexp(!re.test(value))
			}
		}
		setDraft(value)
	}, [config, badRegexp])

	const onBlur = useCallback(() => {
		if (!!update) update(draft === '' ? null : draft)
		// if (config.regExp) {
		// 	const re = new RegExp(config.regExp)
		// 	setBadRegexp(!re.test(draft))
		// }
	}, [draft])

	useEffect(() => {
		if (value === draft) return;
		setDraft(value || '')
	}, [value])
	
	return <div className='FieldInput IDinput'>
		
		<input type='text'
			value={draft}
			onChange={onChange}
			onBlur={onBlur}
			maxLength={config.maxLength || null}
			placeholder={placeholder}
			style={{ maxWidth: !!config.maxLength ? `${config.maxLength+1}em` : 'auto' }}
		/>

		{ badRegexp && <div className='InputInfo'><span className='warning'>Must match: <code>{config.regExp}</code></span></div>}

	</div>
}

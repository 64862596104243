
import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'

import Entries from './Entries'
import useCollection from '../collections/useCollection'
import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'
import { GET_ENTRIES } from './Entries'

const CREATE_ENTRY = gql`
	mutation CreateEntry($collectionId: ID!) {
		createEntry(collectionId: $collectionId)
	}`

const REMOVE_ENTRIES = gql`
	mutation RemoveEntries($collectionId: ID!, $entryIds: [ID!]!) {
		removeEntries(collectionId: $collectionId, entryIds: $entryIds)
	}`

export default function EntriesPage() {

	const navigate = useNavigate()
	const { collection: { collectionId } } = useCollection()
	const [selected, setSelected] = useState([])
	const [selectedAction, setSelectedAction] = useState('')

	const [createEntry, { loading: loadingCreate, error: createError }] = useMutation(CREATE_ENTRY)
	const [removeEntries, { loading: loadingRemove, error: removeError }] = useMutation(REMOVE_ENTRIES, {
		refetchQueries: [{ query: GET_ENTRIES, variables: { collectionId } }]
	})

	async function handleCreate() {
		const response = await createEntry({ variables: { collectionId }})
		navigate(`/collection/${collectionId}/id/${response.data?.createEntry || ''}`)
	}

	async function handleSelectedAction() {
		if (selectedAction === 'delete') {
			await removeEntries({ variables: { collectionId, entryIds: selected }})
			setSelected([])
		}
	}

	return <>

		<nav className='Menu'>

			<button onClick={() => handleCreate()} className='primary'>+ Add entry</button>

			<Link to='bulk'>Bulk edit</Link>

			{ !!selected.length && <div>
				Selected {selected.length}:{' '} 
				<select value={selectedAction} onChange={e => setSelectedAction(e.target.value)}>
					<option value=''></option>
					<option value='delete'>delete</option>
				</select>
				{ !!selectedAction && <button onClick={() => handleSelectedAction()}>&rarr;</button> }
			</div>}

		</nav>

		{ createError && <Alert error={createError} /> }
		{ removeError && <Alert error={removeError} /> }

		<Entries selected={selected} onSelected={setSelected} />
		
		{ loadingRemove && <Spinner /> }
		{ loadingCreate && <Spinner /> }

	</>
}


import { Routes, Route } from 'react-router-dom'

import UrlsPage from './UrlsPage'


export default function URLsRouter() {
	return <>
		<header>
			<h1>URLs</h1>
		</header>
		<Routes>
			<Route index element={<UrlsPage />} />
		</Routes>
	</>
}

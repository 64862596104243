
import EditSnippet from './EditSnippet'
import DeleteCollection from './DeleteCollection'
import EmptyCollection from './EmptyCollection'
import EditCollection from './EditCollection'
import ConfigEntryTitle from './ConfigEntryTitle'
import jason from '../utils/jason'
import useCollection from './useCollection'


export default function CollectionSettingsPage() {

	const { collection } = useCollection()

	return <>

		<EditCollection />

		<hr />

		<EditSnippet />

		<hr />

		<ConfigEntryTitle />

		<hr className='dangerZone' />
		
		<h3>Danger zone</h3>

		<hr />
		<DeleteCollection />
		<hr />
		<EmptyCollection />

		<hr />

		{jason(collection, 'collection')}

	</>
}

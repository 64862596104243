

export default function TextOptions({ config, update }) {

	return <div className='FieldOptions FieldOptionsCustom'>

		{/* <fieldset>
			<div className='RadioInput'>
				<label className='CheckboxInput'>
					<input type='radio'
						name='TextType'
						checked={!config.html}
						onChange={e => update(d => { d.html = !e.target.checked })}
					/> string
				</label>
				<label className='CheckboxInput'>
					<input type='radio'
						name='TextType'
						checked={config.html}
						onChange={e => update(d => { d.html = e.target.checked })}
					/> rich text
				</label>
			</div>
		</fieldset>
	 */}
		{/* <fieldset>
			<label className='CheckboxInput'>
				<input type='checkbox'
					checked={!!config.noNewline}
					onChange={e => update(d => { d.config.noNewline = e.target.checked })}
				/> no new line
			</label>
		</fieldset> */}

		<fieldset>
			<legend>Rows:</legend>
			<input type='number'
				value={config.rows || ''}
				onChange={e => update(d => { d.rows = Number(e.target.value) })}
			/>
		</fieldset>

		{/* <fieldset>
			<legend>Max length:</legend>
			<input type='number'
				value={config.maxLength || ''}
				onChange={e => update(d => { d.maxLength = Number(e.target.value) })}
			/>
		</fieldset> */}

		{/* <fieldset>
			<legend>RegExp validation:</legend>
			<input type='text'
				value={config.regExp || ''}
				onChange={e => update(d => { d.regExp = e.target.value })}
			/>
		</fieldset> */}

		{/* <label>
			Placeholder: 
			<input type='text'
				name='placeholder'
				defaultValue={config.placeholder}
				onBlur={handleChange}
				// onBlur={e => update(draft => {
				// 	if (!e.target.value && draft.placeholder) {
				// 		delete draft.placeholder
				// 	} else if (!!e.target.value) {
				// 		draft.placeholder = e.target.value
				// 	}
				// })}
			/>
		</label>
		<br /> */}

		{/* <label>
			<input type='checkbox'
				checked={!!config.url}
				onChange={e => update(draft => {
					if (!e.target.checked && draft.url) {
						delete draft.url
					} else if (e.target.checked) {
						draft.url = true
					}
				})}
			/> URL type
		</label>
		<br /> */}


		{/* <div>
			Suggested values:
			<textarea
				name='suggested'
				onBlur={handleChange}
				rows='5'
				defaultValue={Array.isArray(config.suggested) ? config.suggested.join(`
`) : ''}
			/> <small>One per line</small>
		</div> */}
	

		{/* { warning && <p className='warning'>{warning}</p> } */}
	
	</div>
}
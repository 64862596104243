
import { useState, useCallback } from 'react'
import { produce } from 'immer'

import Files from '../files/Files'
import getThumbUrl from '../utils/getThumbUrl'


function FileItem({ path, handleRemove, handleShow }) {
	const thumbUrl = getThumbUrl(path)
	return <div className='FileFieldItem'>
		<img src={thumbUrl}
			width='128px'
			onClick={handleShow}
		/>
		<div>
			<em>{path}</em> <button onClick={handleRemove}>x</button>
		</div>
	</div>
}


export default function FileInput({ field, value, update }) {

	const [showFiles, setShowFiles] = useState(false)

	const handleSelected = useCallback((paths) => {
		if (field.multi) {
			update(paths)
		} else {
			update(paths[0])
			setShowFiles(false)
		}
	}, [field.multi])

	const handleRemove = useCallback(idx => {
		update(produce(value, draft => { draft.splice(idx, 1) }))
	}, [value])

	return <div>

		{ Array.isArray(value) &&
			value.map((path, idx) => 
				<FileItem key={path} 
					path={path} 
					handleRemove={() => handleRemove(idx)} 
					handleShow={() => setShowFiles(path)}
				/>
			)
		}
		{ typeof(value) === 'string' &&
			<FileItem path={value} 
				handleRemove={() => update(null)} 
				handleShow={() => setShowFiles(value)}
			/>
		}

		{ !showFiles &&
			<button onClick={() => setShowFiles(true)} className='Button'>Pick {field.multi ? "files" : "file"}</button> }

		{ showFiles && <>
			<button onClick={() => setShowFiles(null)}>Close</button>
			<Files 
				isEmbed={true}
				onSelected={handleSelected}
				initialPath={'/'}
				// initialSelected={value}
				// onSelected={handleSelected} 
				// defaultPath={field.path}
			/> 
		</>}

	</div>
}


import { produce } from 'immer'

import MultiInput from './MultiInput'
import Field from './Field'
import jason from '../utils/jason'


function StructInputSingle({ field, value, update }) {

	return <div className='STRUCTfields'>

		{ Array.isArray(field?.config?.fields) && field.config.fields.map(field =>
			<Field key={field.fieldId}
				// fieldId={field.id}
				field={field}
				value={!!value && value[field.fieldId]}
				update={v => {
					// console.log(value, v)
					update(produce(value, draft => { 
						if (!draft) return { [field.fieldId]: v }
						draft[field.fieldId] = v
					}))
				}}
			/>
		)}

		{/* {jason(field, 'field')} */}
	</div>
}

export default function StructInput({ field, value, update }) {

	return <div className='FieldInput STRUCTinput'>
		
		{ !field?.config.multi
			?	<StructInputSingle field={field} value={value} update={update} />
			:	<MultiInput field={field} value={value} update={update}>
					<StructInputSingle />
				</MultiInput>
		}	
	</div>
}


import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'

import FieldEditor from './FieldEditor'
// import FieldInput from './Field'
// import InputLabel from './InputLabel'
// import Spinner from '../utils/Spinner'
// import Alert from '../utils/Alert'
import { GET_COLLECTION } from '../collections/CollectionProvider'

const UPDATE_FIELD = gql`
	mutation UpdateField($collectionId:ID, $fieldId:ID!, $values:JSON!) {	
		updateField(collectionId:$collectionId, fieldId:$fieldId, values:$values)
	}`


export default function FieldItem({ field }) {

	const [isEditing, setIsEditing] = useState(false)
	const [value, setValue] = useState()

	const [updateField, { loading: loadingUpdate, error: updateError }] = useMutation(UPDATE_FIELD, {
		refetchQueries: [{ query: GET_COLLECTION, variables: { collectionId: field.collectionId } }],
	})

	async function handleUpdate(values) {
		console.log(field, values)
		await updateField({ variables: { collectionId: field.collectionId, fieldId: field.fieldId, values } })
		setIsEditing(false)
	}

	return <>
	
		<div className='FieldItem'>
			<div>
				<button onClick={() => setIsEditing(true)}>Edit</button>
			</div>
			<span><strong>{ field.config?.name || ' ' }</strong></span>
			<code>{ field.type }</code>
			<code><em>{ field.fieldId }</em></code>
		</div>

		{ isEditing && <div className='FieldItemEditor'> 
			<FieldEditor field={field} 
				handleSave={handleUpdate}
				onDelete={() => setIsEditing(false)}
				onCancel={() => setIsEditing(false)}
			/>
		</div> }
	</>
}
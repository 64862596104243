
import { useState, useContext, useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import { FilesContext } from './Files'
import Spinner from '../utils/Spinner'


const DELETE_FILES = gql`
	mutation deleteFiles($paths: [String!]!) {
		deleteFiles(paths: $paths) 
	}
`

export default function SelectedFiles({ folder }) {

	const { selected, setSelected, refetch } = useContext(FilesContext)
	const [action, setAction] = useState('')

	const [deleteFiles, { loading: loadingDelete, error: deleteError }] = useMutation(DELETE_FILES, {
		// refetchQueries: [{ query: GET_COLLECTIONS }]
	})

	const onSelectAll = useCallback((event) => {
		setSelected(draft => {
			for (const file of folder.files) {
				const idx = draft.indexOf(file.path)
				if (idx === -1 && event.target.checked) {
					draft.push(file.path)
				} else if (idx > -1 && !event.target.checked) {
					draft.splice(idx, 1)
				}
			}
		})
	}, [folder.files])

	const handleChangeAction = useCallback(({target}) => {
		setAction(target.value)
	}, [])

	async function handleRunAction() {
		if (action === 'delete') {
			await deleteFiles({ variables: { paths: selected }})
			refetch()
		}
	}

	return <div>

		<label className='block'>
			<input type='checkbox'
				onChange={onSelectAll}
				disabled={!folder.files || folder.files.length === 0}
			/> Select all
		</label>

		{ selected.length > 0 && <>
			<span>Selected {selected.length} {selected.length === 1 ? "file" : "files"}:</span>
			<br />
			<select value={action} onChange={handleChangeAction}>
				<option value=''></option>
				<option value='delete'>delete</option>
				{/* <option value='move'>move here</option> */}
			</select><button disabled={action === ''} onClick={handleRunAction}>&rarr;</button>
		</>}
				
		{/* <QueryStatus loading={loadingDelete} error={deleteError} /> */}
		{ loadingDelete && <Spinner /> }
		{ deleteError && <code>{deleteError.toString()}</code> }
	
	</div>
}



export default function DetailsButton({ 
	isOpen,
	toggle,
	children 
}) {

	function onClick(e) {
		e.preventDefault()
		toggle()
	}

	return <details open={isOpen} onClick={onClick} className='DetailsButton'>
		<summary>{children}</summary>	
	</details>
}
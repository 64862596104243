
import { gql, useMutation } from '@apollo/client'

import useCollection from './useCollection'
import { GET_COLLECTION } from './CollectionProvider'
import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'
import CollectionEditor from './CollectionEditor'
import { GET_COLLECTIONS } from '../layout/Collections'

export const EDIT_COLLECTION = gql`
	mutation EditCollection($collectionId:ID!, $values:JSON!) {
		editCollection(collectionId:$collectionId, values:$values)
	}
`


export default function EditCollection() {

	const { collection } = useCollection()
	const { collectionId } = collection

	const [editCollection, { error, loading }] = useMutation(EDIT_COLLECTION, {
		refetchQueries: [{ query: GET_COLLECTION, variables: { collectionId } }, { query: GET_COLLECTIONS }]
	})

	return <>

		<h3>Edit Collection</h3>

		<CollectionEditor collection={collection} 
			handleSave={values => editCollection({ variables: { collectionId, values }})} 
		/>
		
		{ error && <Alert error={error} /> }
		{ loading && <Spinner /> }

	</>
}

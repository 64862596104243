
import { useEffect, useContext, useRef } from 'react'
import { useImmer } from 'use-immer'
import axios from 'axios'

import UploadProgress from './UploadProgress'
import { FilesContext } from './Files'

const UPLOAD_SERVER = `${process.env.DAISY_CMS_URL}/upload`


export default function FileUpload({ path }) {

	const [progress, setProgress] = useImmer(null)
	const inputRef = useRef(null)
	const { refetch } = useContext(FilesContext)

	async function onSelectFiles(event) {
		try {
			const progress = {
				fileCount: 0,
				loaded: 0,
				total: 0,
				done: false,
				error: null,
			}
			const formData = new FormData()
			formData.append('parentPath', path)
			for (let file of event.target.files) {
				progress.fileCount++
				progress.total += file.size
				formData.append('files', file)
			}
			setProgress(progress)
			const token = localStorage.getItem('token')
			const response = await axios.postForm(UPLOAD_SERVER, formData, {
				headers: { Authorization: token ? `Bearer ${token}` : '' },
				onUploadProgress: progress => setProgress(draft => { 
					draft.loaded = progress.loaded
					draft.total = progress.total
				}),
			})
			if (response.status === 200) setProgress(draft => { draft.done = true })
				else throw new Error("Files did not finish uploading successfuly.")
		} catch (error) { setProgress({ error: error.toString() }) }
	}

	function handleDiscard() {
		inputRef.current.value = ''
		setProgress(null)
	}

	// refetch() when all files are uploaded
	useEffect(() => {
		if (progress?.done) refetch()
	}, [progress])

	return <div className='FilesUpload'>
	
		<input type='file' 
			name='files'
			onChange={onSelectFiles}
			multiple
			disabled={!!progress && !progress.done}
			ref={inputRef}
		/>

		{ !!progress &&
			<UploadProgress 
				progress={progress} 
				onDiscard={handleDiscard}
			/> }

		{/* <button type='submit'>Upload</button> */}

		{/* {!!files && <pre>{JSON.stringify(files, null, 2)}</pre>} */}

		<pre>{JSON.stringify(progress, null, 2)}</pre>

	</div>
}


import { Link } from 'react-router-dom'


const LOGTYPES = {

	'BUILD_STARTED': () => 
		<>started a build</>,
	'BUILD_ABORTED': () => 
		<>aborted a build</>,
	
	'TOKEN_CREATED': log => 
		<>created a <Link to='/build/tokens'>developer token</Link>: "<em>{log.vals.label}</em>" <code>{log.vals.tokenId}</code></>,
	'TOKEN_DELETED': log => 
		<>deleted a <Link to='/build/tokens'>developer token</Link>: <code>{log.vals.tokenId}</code></>,
	
	'USER_CREATED': log => 
		<>created a new user <Link to={`/users/${log.vals.userId}`}>{log.vals.name} ({log.vals.email}) [{log.vals.role}]</Link></>,
	'USER_EDITED': log => 
		<>edited user profile <Link to={`/users/${log.vals.userId}`}>{log.vals.name} ({log.vals.email}) [{log.vals.role}]</Link></>,
	'PERMISSIONS_EDITED': ({vals}) => 
		<>edited <Link to={`/users/${vals.userId}`}>user permissions</Link>: {vals.action} {vals.scope}/{vals.target}</>,
	'USER_DELETED': ({vals}) => 
		<>deleted user {vals.userId}</>,
	'PASSWORD_RESET': ({vals}) => 
		<>reset password for user {vals.userId}</>,

	'COLLECTION_CREATED': ({vals}) => 
		<>created collection <Link to={`/collection/${vals.collectionId}`}>{vals.icon} {vals.name}</Link></>,
	'COLLECTION_FIELDS_EDITED': ({vals}) => 
		<>edited <Link to={`/collection/${vals.collectionId}`}>collection</Link> field "{vals.field.id}" [{vals.action}]</>,
	'COLLECTION_SNIPPET_EDITED': ({vals}) => 
		<>edited <Link to={`/collection/${vals.collectionId}`}>collection snippet</Link></>,
	'COLLECTION_URI_CONFIG_EDITED': ({vals}) => 
		<>edited <Link to={`/collection/${vals.collectionId}`}>collection URI config</Link>: {vals.uriPrefix}</>,
	'COLLECTION_DELETED': ({vals}) => 
		<>deleted collection {vals.collectionId}</>,
	
	'ENTRY_CREATED': ({vals}) => 
		<>created <Link to={`/collection/${vals.collectionId}/${vals.entryId}`}>a new entry in {vals.collectionId}</Link></>,
	'ENTRY_SAVED': ({vals}) => 
		<>saved changes in <Link to={`/collection/${vals.collectionId}/${vals.entryId}`}>{vals.collectionId} entry</Link></>,
	'ENTRY_URI_EDITED': ({vals}) => 
		<>changed URI of <Link to={`/collection/${vals.collectionId}/${vals.entryId}`}>{vals.collectionId} entry</Link> to {vals.uri}</>,
}

export default LOGTYPES
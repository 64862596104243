
// import { gql, useMutation } from '@apollo/client'
import { useContext } from 'react'

import { CollectionContext } from './CollectionProvider'


// const EDIT_COLLECTION_FIELDS = gql`
// 	mutation editCollectionFields($collectionId: ID!, $action: String!, $field: JSON, $prevFieldId: String) {
// 		editCollectionFields(collectionId: $collectionId, action: $action, field: $field, prevFieldId: $prevFieldId)
// 	}`


export default function useCollection() {

	const collection = useContext(CollectionContext)
	// const collectionId = collection.collectionId

	// const [editCollectionFields] = useMutation(EDIT_COLLECTION_FIELDS, {
	// 	refetchQueries: [{ query: GET_COLLECTION, variables: { collectionId } }]
	// })

	// function updateField(fieldId, field) {
	// 	return editCollectionFields({ 
	// 		variables: {
	// 			action: 'UPDATE',
	// 			collectionId, 
	// 			field,
	// 			prevFieldId: fieldId
	// 		}
	// 	})
	// }

	// function deleteField(fieldId) {
	// 	return editCollectionFields({ 
	// 		variables: {
	// 			action: 'REMOVE',
	// 			collectionId, 
	// 			field: { id: fieldId },
	// 		}
	// 	})
	// }

	return {
		collectionId: collection.collectionId,
		collection,
		// updateField,
		// deleteField,
	}
}


import { useState, useContext } from 'react'
import { gql, useMutation } from '@apollo/client'

import jason from '../utils/jason'
import { LIST_TASKS } from './TaskList'
import TaskRun from './TaskRun'
import TaskEditor from './TaskEditor'
import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'



export default function Task({ task, onSelect, isSelected }) {

	const [showEdit, setShowEdit] = useState(false)

	return <>
	
		<div className='Task Item'>

			<div>
				{ isSelected 
					? <button onClick={() => onSelect(null)}>Back</button>
					: <button onClick={() => onSelect(task)}>Select</button> }
			</div>

			<code>{task.type}</code>

			<span>{task.name}</span>

			{jason(task.config, 'config')}

			<div>
				<button onClick={() => setShowEdit(p=>!p)}>Edit</button>
			</div>

			{/* { !noButton && <AITaskRun task={task} draft={draft} update={update} /> } */}

			{/* { (!showEdit && !noButton) && <TaskRun task={task} /> } */}

		</div>

		{ showEdit && <div className='SubItem'>
			<TaskEditor task={task} onDiscard={() => setShowEdit(false)} onSave={() => setShowEdit(false)}/>
		</div> }
	</>
}


import { createContext } from 'react'
import { useImmer } from 'use-immer'

import TaskList from '../tasks/TaskList'
import BulkTaskRun from '../tasks/BulkTaskRun'


export const BulkEditContext = createContext({
	entryIds: [],
	task: null,
	setTask: null,
})


export default function BulkEditPage() {

	const [task, setTask] = useImmer(null)

	return <>

		<h2>Bulk edit</h2>

		<TaskList onSelect={task => setTask(task)} selected={task} />

		{ !!task && <BulkTaskRun task={task} />  }

	</>
}

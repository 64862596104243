
import { useMutation } from '@apollo/client'

import TaskRun from '../tasks/TaskRun'
import { UPDATE_ENTRY } from '../entries/EntryPage'
import useCollection from './useCollection'
import EntrySnippet from './EntrySnippet'


export default function BulkEntry({ 
	task,
	entry, 
	// setEntry,
	setEntries,
}) {

	const entryId = entry.entry.entryId
	const { collection } = useCollection()

	const [updateEntry, { loading: loadingUpdate, error: updateError }] = useMutation(UPDATE_ENTRY, {
		// refetchQueries: [{ query: GET_ENTRY }]
	})

	// function setEntry(vals) {
	// 	setEntries(draft => {
	// 		const entryIdx = draft.findIndex(e => e.entry.entryId === entryId)
	// 		if (entryIdx > -1) draft[entryIdx] = { ...draft[entryIdx], ...vals }
	// 	})
	// }

	function handleFinish(payload) {
		setEntries(draft => {
			if (!!payload?.errors) {
				draft[entryId].status = 'ERROR'
				draft[entryId].error = payload.errors.message || "ERROR"
			} else {
				draft[entryId].status = 'DONE'
			}
			// draft[entryId].draft = !payload.changes ? null 
			// 	:	Object.entries(payload.changes).reduce((acc, [fieldId, [value]]) => {
			// 			acc[fieldId] = value
			// 			return acc
			// 		}, {})
			// }
		})
	}

	async function handleUpdate(values) {
		const response = await updateEntry({ variables: { collectionId: collection.collectionId, entryId, values }})
		setEntries(draft => {
			if (!!response.errors) {
				draft[entryId].status = 'ERROR'
				draft[entryId].error = response.errors
			} else {
				draft[entryId].status = 'SAVED'
			}
		})
	}

	// useEffect(() => {
	// 	if (entry.status === 'SAVING' && !loadingUpdate && !!entry.draft) handleUpdate(entry.draft)
	// }, [entry.status, loadingUpdate])

	return <>
	
		<div className='BulkEntry Item'>

			<div>
				{/* { !entry.status && <span>ready</span> }
				{ entry.status === 'LOADING' && <Spinner /> }
				{ entry.status === 'DONE' && <span>Done.</span> }
				{ entry.status === 'SAVED' && <span>SAVED.</span> }
				{ entry.draft === null && <span>No changes.</span> } */}
				{ entry.status || ' '}
			</div>

			<EntrySnippet snippet={collection.snippet} entry={entry.entry} />

		</div>

		<div className='SubItem'>

			{ !!entry.error && <Alert error={entry.error} /> }

			<TaskRun task={task} entryId={entryId} bulkEntryStatus={entry.status} noButton isSaved={entry.status === 'SAVED'}
				onFinish={handleFinish} handleUpdate={handleUpdate}  />

				
		</div>

	</>
}

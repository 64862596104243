
import { useState, useCallback, useEffect } from 'react'
import classNames from 'classnames'
import parse, { attributesToProps, domToReact } from 'html-react-parser'


import MultiInput from './MultiInput'
import Icon from '../utils/Icon'


function HtmlInputSingle({ field, value, update }) {

	const [draft, setDraft] = useState(value)
	const [showOutput, setShowOutput] = useState(false)
	// const [showFullscreen, setShowFullscreen] = useState(false)

	const onChange = useCallback((event) => {
		let value = event.target.value
		setDraft(value)
	}, [field])

	const onBlur = useCallback(() => {
		update(draft === '' ? null : draft)
	}, [draft])

	useEffect(() => {
		if (value !== draft) setDraft(value)
	}, [value])
	
	return <div className='FieldInput HTMLinput'>

		<div className='input'>
			<textarea
				value={draft || ''}
				onChange={onChange}
				onBlur={onBlur}
				rows={12}
				// className='input'
			/>
			<details>
				<summary><small><em>HTML tags guide</em></small></summary>
				Example image tag:<br />
				<code>{'<img src="/path/to/image.jpg" alt="Image description" class="left small" />'}</code><br />
				The  <code>class</code> attribute can have <code>left</code>, <code>right</code>, <code>center</code>, and <code>stretch</code> for positioning, and <code>small</code>, <code>medium</code>, <code>large</code>, for size.<br />
			</details>
		</div>

		{ showOutput && 
			<div className='HTMLinputOutput'>
				<HtmlRenderer htmlString={draft} />
			</div> 
		}

		<div className='HTMLinputButtons'>
			<button onClick={() => setShowOutput(v => !v)} className={classNames({ primary: showOutput })}>
				<Icon i='html' />
			</button>
			{/* <button onClick={() => setShowFullscreen(v => !v)}><Icon i='fullscreen' /></button> */}
		</div>

	</div>
}

export default function HtmlInput({ field, value, update }) {
	return !field.multi
		?	<HtmlInputSingle field={field} value={value} update={update} />
		:	<MultiInput field={field} value={value} update={update}>
				<HtmlInputSingle />
			</MultiInput>
}


function HtmlRenderer({ htmlString }) {

	const parserOptions = {
		replace: node => {
			if (node.name === 'img') {
				const attrs = {
					...attributesToProps(node.attribs),
					src: `${process.env.DAISY_CMS_URL}/files${node.attribs.src}`,
				}
				return <img 
					{...attrs} 
				/>
	
			}
		}
	}

	return parse(htmlString, parserOptions)
}

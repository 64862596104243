
import { atom } from 'recoil'

export const userAtom = atom({
	key: 'userAtom',
	default: null,
})

export const buildDateAtom = atom({
	key: 'buildDateAtom',
	default: null,
})

import Field from './Field.js'
import TextField from './TextField.js'


export default class DatetimeField extends Field {

	type = 'DATETIME'
	_columnType = 'DATETIME'
	_gqlType = 'Date'
	
	static isValidDate(dateObj) {
		return dateObj instanceof Date && !isNaN(dateObj.getTime())
	}

	static parseValue(value) {
		// TODO: if value is a string, parse it as a date, if it's a number, parse it as a timestamp
		const textValue = TextField.parseValue(value)
		if (!textValue) return null
		// console.log('textValue', textValue)
		const date = new Date(textValue)
		return !DatetimeField.isValidDate(date) 
			? null
			: date.toISOString()
	}

	constructor(initVals) {
		super(initVals)
	}

	_parseValue(value) {
		return DatetimeField.parseValue(value)
	}

}

import { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
// import { NavLink, Link } from 'react-router-dom'

import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'
import Title from '../utils/Title'
import FieldItem from './FieldItem'
// import jason from '../utils/jason'

export const GET_FIELDS = gql`
	query GetFields {
		fields
	}`


export default function FieldsPage() {

	const { loading, error, data } = useQuery(GET_FIELDS)
	const fields = data?.fields || []
	const [isAdding, setIsAdding] = useState(false)

	return <>

		<Title>Fields</Title>
		<div className='Heading'>
			<h1>Fields</h1>
		</div>

		<p>
			<button onClick={() => setIsAdding(true)}>New field</button>
		</p>

		{/* { isAdding && <NewField /> } */}

		{ !!error && <Alert error={error} /> }

		{ fields.map(field => 
			<FieldItem key={`${field.fieldId}+${field.collectionId}`} field={field} />) }

		{/* <pre className='wrap'>{JSON.stringify(fields, null, 2)}</pre> */}

		{ loading && <Spinner /> }

		{/* {jason(fields, 'fields')} */}

	</>
}

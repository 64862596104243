
import DatetimeField from './DatetimeField.js'


export default class ModifiedField extends DatetimeField {

	type = 'MODIFIED'
	_columnType = 'DATETIME'
	_gqlType = 'Date'
	
	constructor(initVals) {
		super(initVals)
	}

}
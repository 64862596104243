
import useCollection from '../collections/useCollection'


export default function RegexReplace({ config, setConfig }) {

	const { collection: { fields } } = useCollection()

	function toggleField(fieldId) {
		setConfig(config => {
			if (!config.fields) config.fields = []
			if (config.fields.includes(fieldId)) {
				config.fields.splice(config.fields.indexOf(fieldId), 1)
			} else {
				config.fields.push(fieldId)
			}
		})
	}

	function toggleFlag(flag) {
		setConfig(config => {
			if (!config.flags) config.flags = ''
			if (config.flags.includes(flag)) config.flags = config.flags.replace(flag, '')
				else config.flags += flag
		})
	}

	return <>

		<p>Search and replace a string using regular expressions on a text type field.</p>

		<fieldset>
			<legend>Find and replace:</legend>
			<div>
				<label>Regular expression:</label>
				<input type='text' value={config.regex || ''} 
					onChange={e => setConfig(c => { c.regex = e.target.value })} />
				<label>
					<input type='checkbox' checked={!!config.flags?.includes('i')}
						onChange={() => toggleFlag('i')} />i
				</label>{' '}
				<label>
					<input type='checkbox' checked={!!config.flags?.includes('g')}
						onChange={e => toggleFlag('g')} />g
				</label>
			</div>
			<div>
				<label>Replace:</label>
				<input type='text' value={config.replace || ''} 
					onChange={e => setConfig(c => { c.replace = e.target.value })} />
			</div>
		</fieldset>

		<fieldset>
			<legend>Target fields:</legend>
			<ul>
				{ fields.map(({fieldId, config: { name }}) =>
					<li key={fieldId}><label>
						<input type='checkbox'
							checked={!!config.fields?.includes(fieldId)}
							onChange={() => toggleField(fieldId)}
						/> {name}
					</label></li>
				)}
			</ul>
		</fieldset>

	
	</>
}

import { useEffect, useCallback } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'

dayjs.extend(duration)
dayjs.extend(relativeTime)

import Timestamp from '../utils/Timestamp'
import LiveDuration from '../utils/LiveDuration'
import Alert from '../utils/Alert'
import Title from '../utils/Title'
import Spinner from '../utils/Spinner'

const GET_BUILD = gql`
	query GetBuildsInfo {
		latestFinishedBuild: latestBuild(status: "FINISHED") {
			buildId
			started
			finished
			status
		}
		latestBuild {
			buildId
			command
			started
			finished
			status
			log
		}
	}`

const BUILD_SUB = gql`
	subscription BuildSub {
		latestBuild {
			buildId
			command
			started
			finished
			status
			log
		}
	}`

const START_BUILD = gql`
	mutation StartBuild {
		startBuild
	}`
const ABORT_BUILD = gql`
	mutation AbortBuild {
		abortBuild
	}`
const GATSBY_CLEAN = gql`
	mutation GatbsyClean {
		gatsbyClean
	}`


export default function BuildPage() {

	const { data: buildData, loading: buildLoading, error: buildError, subscribeToMore } = useQuery(GET_BUILD)
	const [startBuild, { data: startBuildData, loading: startBuildLoading, error: startBuildError }] = useMutation(START_BUILD)
	const [abortBuild, { loading: abortBuildLoading, error: abortBuildError }] = useMutation(ABORT_BUILD)
	const [gatsbyClean, { loading: gatsbyCleanLoading, error: gatsbyCleanError }] = useMutation(GATSBY_CLEAN)

	const build = buildData?.latestBuild
	const latestFinishedBuild = buildData?.latestFinishedBuild

	const handleStartBuild = useCallback(async () => {
		try {
			const response = await startBuild()
			if (!!response?.data?.startBuild)
				console.log("build started")
		} catch (error) { console.error(error)}
	}, [])

	async function handleAbort() {
		try {
			await abortBuild()
			console.log('aborted')
		} catch (e) { console.error(e) }
	}

	useEffect(() => {
		subscribeToMore({
			document: BUILD_SUB,
			variables: {},
			updateQuery: (prev, { subscriptionData: { data: { latestBuild } } }) => {
				const nextVal = { latestBuild }
				return Object.assign({}, prev, nextVal )
			}
		})
	}, [])

	return <>

		<Title>Build</Title>

		<div className='Menu'>
				
			<button onClick={handleStartBuild} className='primary' 
				// disabled={build?.status === 'RUNNING'}
			>
				{ build?.status === 'RUNNING' ? 'Building...' : 'Start build' }
			</button>

			<button onClick={() => gatsbyClean()} className='delete small'>gatsby clean</button>

			{ gatsbyCleanLoading && <Spinner /> }
			{ build?.status === 'RUNNING' && <Spinner /> }

		</div>

		<Alert error={gatsbyCleanError} />
		<Alert error={startBuildError} />
		<Alert error={buildError} />

		{ (build?.status !== 'FINISHED' && !!latestFinishedBuild) && <>
			<h4>Latest successful build</h4>
			<FinishedBuild build={latestFinishedBuild} /> 
		</>}

		{ !!build && <h3>Last build</h3> }

		{ build?.status === 'FINISHED' && 
			<FinishedBuild build={build} /> }

		{ build?.status === 'RUNNING' && <p>
			Started <LiveDuration startIso={build.started} /> ago &mdash; <button onClick={() => handleAbort()} className='delete small'>
				{ abortBuildLoading ? "Aborting..." : "Abort" }
			</button>
		</p>}

		{ (build?.status === 'ERRORED' || build?.status === 'ABORTED' || build?.status === 'KILLED') && <p>
			❌ {build.status} <Timestamp live iso={build.finished} /> after <LiveDuration startIso={build.started} finishIso={build.finished} />.
		</p> }

		{ build?.status === 'ABORTING' || build?.status === 'KILLING' && 
			<p>⚠️ {build.status}</p> }

		{ !!build?.log && 
			<div id='BuildLog' dangerouslySetInnerHTML={{__html: build.log }} /> }

		{/* <pre>{JSON.stringify(build, null, 2)}</pre> */}

	</>
}


function FinishedBuild({ build }) {
	return <>
		✅ Finished <Timestamp live iso={build.finished} /> after <LiveDuration startIso={build.started} finishIso={build.finished} /> &mdash; <a href={process.env.DAISY_PREVIEW_URL} target='DaisyPreview'>See preview</a>
	</>
}


import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(duration)
dayjs.extend(relativeTime)


export default function LiveDuration({ 
	startIso, 
	finishIso,
	format = 'm[m] ss[s]' 
}) {

	// console.log(finishIso, startIso)
	const [formatted, setFormatted] = useState(() => {
		const finish = !finishIso ? dayjs() : dayjs(finishIso)
		const start = dayjs(startIso)
		return dayjs.duration(finish.diff(start)).format(format)
	})
	
	useEffect(() => {
		const intervalId = setInterval(() => {
			const finish = !finishIso ? dayjs() : dayjs(finishIso)
			const start = dayjs(startIso)
			setFormatted(dayjs.duration(finish.diff(start)).format(format))
		}, 1 * 1000)
		return () => clearInterval(intervalId)
	}, [startIso, finishIso])

	return <>
		{formatted}
	</>
}
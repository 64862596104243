
import { useEffect, useState, useCallback, useMemo, createContext } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useImmer } from 'use-immer'
import { produce } from 'immer'
import cn from 'classnames'

import FilesPath from './FilesPath'
import FileModal from './FileModal'
import FileUpload from './FileUpload'
import Folder from './Folder'
import SelectedFiles from './SelectedFiles'
import NewFolder from './NewFolder'
import Alert from '../utils/Alert'

const GET_FILES_PATH = gql`
	query getFilesPath($path: String!) {
		filesPath(path: $path) {
			path
			folders {
				path
				name
			}
			files {
				path
				name
			}
			file {
				path
	 			name
	 			mimeType
	 			size
	 			created
	 			modified
			}
		}
	}`

const FilesContext = createContext({})

export { FilesContext }


export default function Files({ 
	isEmbed = false,
	isModal = false,
	initialPath = '/',
	forcePath,
	onSelected,
}) {

	const [path, setPath] = useState(forcePath || initialPath)
	const { loading, error, data, refetch } = useQuery(GET_FILES_PATH, { variables: { path }})
	const [selected, setSelected] = useImmer([])

	const folder = useMemo(() => {
		if (!data?.filesPath)
			return null
		if (selected.length === 0 || data.filesPath.files.length === 0)
			return data.filesPath
		return produce(data.filesPath, draft => {
			draft.files.forEach((file, idx) => {
				if (selected.includes(file.path))
					draft.files[idx].selected = true
			})
		})
	}, [data, selected])

	const closeModal = useCallback(() => {
		const splitPath = path.split('/')
		splitPath.pop()
		setPath(splitPath.join('/'))
	}, [path])
 
	const filesCtx = useMemo(() => ({
		isEmbed,
		path,
		setPath,
		setSelected,
		selected,
		refetch
	}), [selected, path])

	useEffect(() => {
		if (!!onSelected && selected.length > 0) 
			onSelected(selected)
	}, [selected])

	useEffect(() => {		
		if (!!forcePath && path !== forcePath)
			setPath(forcePath)
		// refetch({ path })
	}, [forcePath])
	
	return <FilesContext.Provider value={filesCtx}>

		<div className={cn('FilesWrap', { isModal })}>
			<div className='Files'>

				<div className='FilesPath'>
					<FilesPath path={path} />
				</div>

				<div className='FilesOptions'>
					<NewFolder path={path} />
					{ !!folder && 
						<SelectedFiles folder={folder} /> }
					<FileUpload path={path} />
				</div>

				<div className='FilesFolder'>
					{ !!folder && <Folder folder={folder} /> }
				</div>

				<Alert error={error} />
				
			</div>
		</div>

		{ !!folder?.file &&
			<FileModal file={folder.file} handleClose={() => closeModal()} /> }

	</FilesContext.Provider>
}



import React, { useMemo } from 'react'

import FilesLink from './FilesLink'


export default function FilesPath({ path }) {

	const folderMap = useMemo(() => path === '/' ? [] : path.split('/').slice(1), [path])

	return <>

		<FilesLink path=''>🏠</FilesLink>
		<span>/</span>

		{ folderMap.map((folder, idx) => 
			<React.Fragment key={`${idx}-${folder}`}>
				
				{ idx === folderMap.length - 1
					?	<span>{folder}</span>
					:	<FilesLink path={`/${folderMap.slice(0, idx+1).join('/')}`} >
							{folder}</FilesLink>
				}
				<span>/</span>
				
			</React.Fragment> 
		)}

	</>
}

import { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client'

import { GET_COLLECTIONS } from '../layout/Collections'
import Title from '../utils/Title'
import CollectionEditor from './CollectionEditor'
import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'
import ReorderCollections from './ReorderCollections'

const CREATE_COLLECTION = gql`
	mutation CreateCollection($values:JSON!) {
		createCollection(values:$values)
	}
`

export default function CollectionsPage() {

	const navigate = useNavigate()

	const [createCollection, { loading, error }] = useMutation(CREATE_COLLECTION, {
		refetchQueries: [{ query: GET_COLLECTIONS }]
	})

	async function handleCreate(values) {
		try {
			await createCollection({ variables: { values }})
			navigate(`/collection/${values.collectionId}/fields`)
		} catch (e) { console.error(e) }
	}

	return <>

		<Title>Collections</Title>
				
		<h2>Create a collection</h2>
		{ error && <Alert error={error} /> }
		<CollectionEditor collection={{}} handleSave={handleCreate} withCopyFrom />
		{ loading && <Spinner /> }

		<ReorderCollections />

	</>
}

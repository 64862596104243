
import { createContext, useMemo, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useImmer } from 'use-immer'

import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'
import useCollection from '../collections/useCollection'


export const GET_ENTRY = gql`
	query GetEntry($collectionId:ID!, $entryId:ID!) {
		entry(collectionId:$collectionId, entryId:$entryId) {
			collectionId
			entryId
			values
		}
		tags(collectionId:$collectionId, entryId:$entryId) {
			id
			target
			value
			data
			created
			author {
				userId
				name
				emailHash
			}
		}				
	}
`

const MAX_TITLE_LENTH = 128

const EntryContext = createContext({})

export default EntryContext


export function EntryProvider({ 
	collectionId, 
	entryId, 
	children 
}) {

	const { loading, error, data } = useQuery(GET_ENTRY, { 
		variables: { collectionId, entryId },
	})
	const entry = data?.entry || null
	const tags = data?.tags || []

	const { collection } = useCollection(collectionId)

	const [draft, setDraft] = useImmer(entry?.values || {})

	const title = useMemo(() => {
		let title = []
		if (!!entry?.values) {
			if (!!collection.title) {
				for (let { fieldId } of collection.title)
					if (!!entry.values[fieldId]) title.push(`${entry.values[fieldId]}`.trim())
			}
			if (!title.length) {
				for (let fieldId of ['title', 'name', 'label', 'uri', 'id']) {
					if (!!entry.values[fieldId]) {
						title.push(`${entry.values[fieldId]}`.trim())
						break
					}
				}
			}
		}
		title = title.join(' · ')
		if (title.length > MAX_TITLE_LENTH) title = title.slice(0, MAX_TITLE_LENTH).trim() + "…"
		return (!title.length) ? (entryId ?? "Entry") : title
	}, [entryId, collection.title, entry?.values])

	const context = {
		collectionId,
		entryId,
		entry,
		tags,
		draft,
		setDraft,
		title,
	}

	useEffect(() => {
		// console.log(entry?.values)
		if (!loading && !!entry?.values) {
			setDraft(entry.values)
		}
	}, [loading, entry])

	if (error) return <Alert error={error} />
	if (!entry) return <Spinner />

	return <EntryContext.Provider value={context}>
		{ children }
	</EntryContext.Provider>
}


// export function useEntry() {

// 	const { collectionId, entryId, entry, tags, draft, setDraft } = useContext(EntryContext)

// 	// const [editCollectionFields] = useMutation(EDIT_COLLECTION_FIELDS, {
// 	// 	refetchQueries: [{ query: GET_COLLECTION, variables: { collectionId } }]
// 	// })

// 	// function updateField(fieldId, field) {
// 	// 	return editCollectionFields({ 
// 	// 		variables: {
// 	// 			action: 'UPDATE',
// 	// 			collectionId, 
// 	// 			field,
// 	// 			prevFieldId: fieldId
// 	// 		}
// 	// 	})
// 	// }

// 	// function deleteField(fieldId) {
// 	// 	return editCollectionFields({ 
// 	// 		variables: {
// 	// 			action: 'REMOVE',
// 	// 			collectionId, 
// 	// 			field: { id: fieldId },
// 	// 		}
// 	// 	})
// 	// }

// 	return {
// 		collectionId,
// 		entryId,
// 		entry,
// 		tags,
// 		draft,
// 		setDraft,
// 	}
// }



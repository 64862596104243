
import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'

import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'

const RESET_PASSWORD = gql`
	mutation ResetPassword($userId: ID!, $password: String!) {
		resetPassword(userId: $userId, password: $password)
	}`


export default function ResetPassword() {

	const { userId } = useParams()
	const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD, { variables: { userId }})

	const handleResetPassword = useCallback(async () => {
		const password = prompt("New password:")
		if (!password)
			return null
		if (password.length < 3) 
			return alert("Too short.")
		try {
			await resetPassword({ variables: { password }})
			alert("Password reset.")
		} catch (error) { console.error(error) }
	}, [])

	return <div className='my-4'>

		<button onClick={handleResetPassword} className='Button Danger text-sm'>
			Reset password</button>

		{ loading && <Spinner /> }
		<Alert error={error} />

	</div>
}


import Field from './Field.js'
import TextField from './TextField.js'
import generateId from '../utils/generateId.js'


export default class IdField extends Field {

	type = 'ID'
	_columnType = 'TEXT'
	columnOptions = 'PRIMARY KEY'
	_gqlType = 'ID'

	fieldId = 'id'

	static parseValue(value) {
		return TextField.parseValue(value)
	}

	static getDefault(seed = 'CMSIDFIELD') {
		return generateId(seed)
	}
	
	constructor(initVals) {
		super(initVals)
	}

	_parseValue(value) {
		return IdField.parseValue(value)
	}

	// _serializeValue(value) {
	// 	return !!this.config?.prefix ? `${this.config.prefix}${value}` : value
	// }

	_exportValue(value) {
		return `${this.collectionId}_${value}`
	}
	
}
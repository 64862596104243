
import { useContext, useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'

import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'
import Timestamp from '../utils/Timestamp'
import Avatar from '../users/Avatar'
// import jason from '../utils/jason'
import EntryChange from './EntryChange'
import EntryContext from './EntryContext'


const GET_ENTRY_CHANGES = gql`
	query EntryChanges($collectionId: ID!, $entryId: ID!) {
		entryChanges(collectionId: $collectionId, entryId: $entryId) {
			created
			changes
			user {
				userId
				name
				emailHash
			}
		}
	}`


export default function EntryChanges({ fields = [] }) {

	const { collectionId, entryId } = useContext(EntryContext)

	const { loading, error, data, refetch } = useQuery(GET_ENTRY_CHANGES, {
		variables: { collectionId, entryId }
	})
	const fieldsMap = useMemo(() => {
		const map = {}
		fields.forEach(field => map[field.id] = field.name)
		return map
	}, [fields])

	const changes = data?.entryChanges || []

	return <div className='EntryChanges'>

		{ !!error && <Alert error={error} /> }

		<div className='ItemsList'>
			{ changes.map(snap => <ChangesSnap snap={snap} key={snap.created} fieldsMap={fieldsMap} />) }
		</div>

		{ loading && <Spinner /> }

	</div>
}


function ChangesSnap({ snap, fieldsMap }) {

	return <div className='EntryChangesSnap'>

		<div className='userTimestamp'>
			<Avatar emailHash={snap.user.emailHash} /> <strong className='username'>{ snap.user.name }</strong> <Timestamp iso={snap.created} />
		</div>

		{ snap.changes.map(change =>
			<EntryChange key={change.key} change={change} fieldsMap={fieldsMap} /> )}

	</div>
}


		



import { useState, useEffect } from 'react'
// import { useImmer } from 'use-immer'
// import { gql, useQuery, useMutation } from '@apollo/client'
// import Spinner from '../utils/Spinner'
// import Alert from '../utils/Alert'
// import { LIST_REDIRECTS } from '../urls/RedirectsPage'
// import Redirect from '../urls/Redirect'
// import Icon from '../utils/Icon'

// const ADD_REDIRECT = gql`
// 	mutation AddRedirect($fromUrl: String!, $toUrl: String!, $code: Int!) {
// 		addRedirect(fromUrl: $fromUrl, toUrl: $toUrl, code: $code)
// 	}
// `

export default function UriInput({ field, value, update }) {

	const [draft, setDraft] = useState(value)
	// const [redirect, setRedirect] = useImmer({ fromUrl: null, toUrl: null, code: 301 })
	const prefix = field.config.prefix || ''

	// const { data, loading: loadingRedirects, error: redirectsError } = useQuery(LIST_REDIRECTS, {
	// 	variables: { toUrl: `${prefix}${value}` }
	// })

	// const [addRedirect, { loading: loadingAddRedirect, error: addRedirectError }] = useMutation(ADD_REDIRECT, {
	// 	refetchQueries: [{ query: LIST_REDIRECTS, variables: { toUrl: `${prefix}${value}` } }]
	// })

	function handleBlur() {
		// if (!!value && draft !== value)
		// 	setRedirect({ fromUrl:`${prefix}${value}`, toUrl: `${prefix}${draft}`, code: 301 })
		update(draft)
	}

	// async function handleAddRedirect() {
	// 	await addRedirect({ variables: { fromUrl: redirect.fromUrl, toUrl: redirect.toUrl, code: redirect.code } })
	// 	setRedirect({ fromUrl: null, toUrl: null, code: 301 })
	// }
	
	useEffect(() => {
		setDraft(value)
	}, [value])
	
	return <>
	
		<div className='FieldInput URIinput'>

			<div className='input fixWrap'>

				<span className='prefix'>/{field?.config?.prefix || ''}</span>
				
				<input type='text' value={draft ?? ''}
					onChange={e => setDraft(e.target.value)}
					onBlur={handleBlur}
				/>

			</div>

		</div>

		{/* <div className='FieldExtras URIextras'>

			{ redirect.fromUrl && <div>
				Redirect: /{redirect.fromUrl} &rarr; /{redirect.toUrl} ({redirect.code})
				{' '}
				<button onClick={handleAddRedirect}>Add</button>
			</div> }
			{ loadingAddRedirect && <Spinner /> }
			{ addRedirectError && <Alert error={addRedirectError} /> }

			<RedirectsList toUrl={`${prefix}${value}`} />

		</div> */}
		
	</>
}


// function RedirectsList({ toUrl }) {

// 	const { data, loading, error } = useQuery(LIST_REDIRECTS, {
// 		variables: { toUrl }
// 	})

// 	if (!!error) return <Alert error={error} />

// 	if (!data?.redirects?.length) return null

// 	return <details>
		
// 		<summary><Icon i='redirect' /> Redirects ({data.redirects.length})</summary>

// 		<div className='GridTable RedirectsList'>
// 			{ data.redirects.map(r => 
// 				<Redirect key={r.redirectId} redirect={r} noDest />
// 			) }
// 		</div>

// 	</details>
// }
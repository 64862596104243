
import { v4, v5 } from 'uuid'

export default function generateId(namespace, value, length = 8) {
	let id = (!namespace && !value)
		? v4()
		: !value
		? v5(namespace, v4())
		: v5(namespace, value)
	return id.slice(0, length)
}
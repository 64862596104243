
import Spinner from '../utils/Spinner'


export default function Pagination({ setOffset, offset, limit, totalEntries, loading}) {

	// if (totalEntries === 0) return null

	// if (totalEntries <= limit) 
	// 	return <div className='Pagination'>{totalEntries} {totalEntries === 1 ? "entry" : "entries"}</div>

	const isLastPage = offset + limit >= totalEntries

	return <div className='Pagination'>

		{ totalEntries <= limit && 
			<span>{totalEntries} {totalEntries === 1 ? "entry" : "entries"}</span> }

		{ totalEntries > limit && <>
			<button onClick={() => setOffset(o => o - limit)} disabled={offset === 0}>&larr;</button>
			<button onClick={() => setOffset(o => o + limit)} disabled={isLastPage} className='last'>&rarr;</button>
			<span>{offset + 1} - {isLastPage ? totalEntries : offset + limit} / {totalEntries} entries</span>
		</> }

		<Spinner show={loading} />

	</div>
}

import { useState } from 'react'
import useCollection from './useCollection'
import { gql, useQuery } from '@apollo/client'

import Alert from '../utils/Alert'

export const GET_SOURCE = gql`
	query GetSource($collectionId:ID!) {
		source(collectionId:$collectionId)
	}
`


export default function SourcePage({}) {

	const { collection, updateField, deleteField } = useCollection()
	const [showEditField, setShowEditField] = useState(null)

	const { loading, error, data, refetch } = useQuery(GET_SOURCE, { 
		variables: { collectionId: collection.collectionId },
	})
	const columns = data?.source?.columns || []
	const rows = data?.source?.rows || []

	// async function handleEdit(fieldId, field) {
	// 	try {
	// 		await updateField(fieldId, field)
	// 		setShowEditField(null)
	// 		refetch()
	// 	} catch (error) { console.error(error) }
	// }

	// async function handleDelete(fieldId) {
	// 	try {
	// 		await deleteField(fieldId, null)
	// 		setShowEditField(null)
	// 		refetch()
	// 	} catch (error) { console.error(error) }
	// }

	return <>

		{ error && <Alert error={error} /> }

		{ !!showEditField && 
			<div className='Modal'>
				{/* <FieldEditor 
					fieldId={showEditField}
					field={collection.fields.find(f => f.id === showEditField) || null}
					handleSave={handleEdit}
					handleDiscard={() => setShowEditField(null)}
					handleDelete={handleDelete}
				/> */}
			</div>
		}
		
		{/* <figure className='overflowWrapper'> */}
			<table className='CollectionSource'>
				<thead>
					<tr>
						{ columns.map(column => 
							<ColumnInfo key={column.name}
								column={column} 
								field={collection.fields.find(f => f.id === column.name)} 
								handleSelect={fieldId => setShowEditField(fieldId)}
							/>
						)}
					</tr>
				</thead>
				<tbody>
					{ rows.map((row, idx) => 
						<tr key={idx}>
							{ row.map((value, idx) => {
								return <td key={idx} className={`val ${value === null ? 'null' : ''}`}>
									{ value === null ? 'null' : value }
								</td>
							})}
						</tr>
					)}
				</tbody>
			</table>
		{/* </figure> */}

		{/* <pre>{JSON.stringify(data, null, 2)}</pre> */}

	</>
}


function ColumnInfo({ 
	column: {name, type, notnull, dflt_value, pk, stats: { 
		total,
		nullCount,
		minVal,
		minLen,
		maxVal,
		maxLen,
		distinctCount, 
	} },
	field,
	handleSelect
}) {


	let textType = !['NUMERIC', 'INTEGER'].includes(type)

	return <th>

		<strong><code>{name}</code></strong><br />
		<div className='ColumnType'>
			<code>{type}</code>
			{ !!notnull && <code>NOTNULL</code> }
			{ !!dflt_value && <code>DEFAULT VALUE {dflt_value}</code> }
			{ !!pk && <code>PRIMARY KEY</code> }
		</div>

		<dl className='ColumnStats'>
			
			{ !textType && <>
				<dt>Value range:</dt>
				<dd>{minVal} - {maxVal}</dd>
			</>}
			
			{ textType && <>
				<dt>Length range:</dt>
				<dd>{minLen} - {maxLen}</dd>
			</>}
			
			<dt>Distinct values:</dt>
			<dd>{distinctCount}/{total} { distinctCount < 2 && '⚠️' }</dd>

			<dt>nulls:</dt>
			<dd>{nullCount}{nullCount > total-2 && '⚠️'}</dd>

		</dl>

		{ !!field && <div className='ColumnField'>

			<strong>{ field.name }</strong><br />
			{ field.kind }<br />
			<button onClick={() => handleSelect(field.id)} className='small'>Edit</button>

		</div>}

	</th>
}
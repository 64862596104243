
import MultiInput from './MultiInput'


function BooleanInputSingle({ field, value, update }) {
	return <div className='BooleanInput'>

		{ field.inputType === 'radio' && <>
			<label>
				<input type='radio'
					checked={!!value}
					onChange={e => update(!!e.target.checked)}
				/> { field.trueLabel ?? 'yes' }
			</label>
			<label>
				<input type='radio'
					checked={value !== null && !value}
					onChange={e => update(!e.target.checked)}
				/> { field.falseLabel ?? 'no' }
			</label>
		</>}

		{ (!field.inputType || field.inputType === 'checkbox') && 
			<label>
				<input type='checkbox'
					checked={!!value}
					onChange={e => update(e.target.checked ? true : null)}
				/> { !!field.trueLabel && field.trueLabel }
			</label>
		}

	</div>
}


export default function BooleanInput({ field, value, update }) {
	return !field.multi
		?	<BooleanInputSingle field={field} value={value} update={update} />
		:	<MultiInput field={field} value={value} update={update}>
				<BooleanInputSingle />
			</MultiInput>
}


import { useState } from 'react'
import { useImmer } from 'use-immer'
import { gql, useMutation } from '@apollo/client'

import TASKS from './tasks.js'
import { LIST_TASKS } from './TaskList'
import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'

const TASK_TYPES = Object.keys(TASKS)

export const DEFAULT_VALUES = {
	id: null,
	type: TASK_TYPES[0],
	name: "Task",
	config: {}
}


const MODIFY_TASK = gql`
	mutation ModifyTask($taskId:ID!, $name:String!, $type:String!, $config:JSON!) {
		modifyTask(taskId:$taskId, name:$name, type:$type, config:$config)
	}`

const REMOVE_TASK = gql`
	mutation RemoveTask($taskId:ID!) {
		removeTask(taskId:$taskId)
	}`


export default function TaskEditor({ 
	task = DEFAULT_VALUES,
	handleSave, 
	onSave = () => {},
	onDiscard = () => {},
}) {

	const [name, setName] = useState(task?.name || 'Task')
	const [type, setType] = useState(task?.type || TASK_TYPES[0])
	const [config, setConfig] = useImmer(task?.config || {})

	const [modifyTask, { loading: loadingModify, error: modifyError }] = useMutation(MODIFY_TASK, {
		refetchQueries: [LIST_TASKS]
	})
	// async function handleModify(draft) {
	// 	await modifyTask({ variables: { taskId: task.taskId, ...draft } })
	// 	setShowEdit(false)
	// }

	const [removeTask, { loading: loadingRemove, error: removeError }] = useMutation(REMOVE_TASK, {
		refetchQueries: [LIST_TASKS]
	})
	function handleRemove() {
		if (!confirm('Are you sure you want to delete this task?')) return;
		removeTask({ variables: { taskId: task.taskId } })
	}

	async function handleSubmit() {
		if (!!handleSave) return handleSave({ name, type, config })
		await modifyTask({ variables: { taskId: task.taskId, name, type, config } })
		onSave()
	}

	const TypeConfig = TASKS[type]

	return <div className='Form TaskEditor'>

		<fieldset>
			<legend>Name:</legend>
			<input type='text' value={name} onChange={e => setName(e.target.value)} />
		</fieldset>		

		<fieldset>
			<legend>Type:</legend>
			<select value={type} onChange={e => setType(e.target.value)} className='w-fit'>
				{ TASK_TYPES.map(type =>
					<option key={type} value={type}>{type}</option>
				)}
			</select>
		</fieldset>

		{ !!TypeConfig && <TypeConfig 
			config={config} 
			setConfig={setConfig}
		/> }

		<div className='Submit'>
			
			<button className='small delete' onClick={handleRemove}>Remove</button>
			<button onClick={() => onDiscard()}>Discard</button>
			<button className='primary' onClick={handleSubmit}>
				Save
			</button>

			{ loadingModify && <Spinner /> }
			{ modifyError && <Alert error={modifyError} /> }

		</div>

	</div>
}


import { cloneElement, useMemo, useCallback } from 'react'
import { produce } from 'immer'
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move'


export default function MultiField({ field, value, update, InputComponent }) {

	function handleAdd() {
		update(produce(value, draft => {
			if (!Array.isArray(draft)) return [null]
			draft.push(null)
		}))
	}

	function handleRemove(idx) {
		update(produce(value, draft => { draft.splice(idx, 1) }))
	}

	// const items = useMemo(() => {
	// 	if (!Array.isArray(value)) return []
	// 	const keyFreshness = Date.now()
	// 	return value.map((val, idx) => (
	// 		<div key={`${idx}-${keyFreshness}`}>
	// 			{ cloneElement(children, { 
	// 					field, 
	// 					value: val,
	// 					update: val => update(produce(value, draft => { draft.splice(idx, 1, val) }))
	// 				})
	// 			}
	// 			<button onClick={() => handleRemove(idx)}>x</button>
	// 		</div>
	// 	))
	// }, [field, value, children])

	function handleSortEnd(oldIndex, newIndex) {
		const newOrder = arrayMoveImmutable(value, oldIndex, newIndex)
		update(newOrder)
	}

	// const handleSelect = useCallback((entryId, add = true) => {
	// 	const value = produce(selected, draft => {
	// 		const idx = draft.indexOf(entryId)
	// 		if (add && idx === -1) {
	// 			draft.push(entryId)
	// 		} else if (idx > -1) draft.splice(idx, 1)
	// 	})
	// 	if (value.length === 0) return update(null)
	// 	if (!field.multi) {
	// 		update(value[0])
	// 		setShowPicker(false)
	// 		return
	// 	}
	// 	update(value)
	// }, [selected])

	// const handleReorder = useCallback(entryIds => {
	// 	update(entryIds)
	// }, [])

	return <>

		{ (Array.isArray(value) && value.length > 0) && 
			<SortableList 
				className='MultiFieldSortableList'
				draggedItemClassName='dragged'
				onSortEnd={handleSortEnd}
			>
				{ value.map((val, idx) => (
					<SortableItem key={`${idx}-${Date.now()}`}>
						<div className='SortableItem'>
							<SortableKnob><div>↕️</div></SortableKnob>
							<InputComponent
								field={field}
								value={val}
								update={newValue => update(produce(value, draft => { draft.splice(idx, 1, newValue) }))}
							/>
							{/* { cloneElement(inputComponent, { 
									field, 
									value: val,
									update: val => update(produce(value, draft => { draft.splice(idx, 1, val) }))
								})
							} */}
							<button onClick={() => handleRemove(idx)}>x</button>
						</div>
					</SortableItem>
				))}
			</SortableList>
		}

		<button onClick={handleAdd}>Add item</button>
	</>
}


import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import Timestamp from '../utils/Timestamp'
import { buildDateAtom } from '../layout/atoms'


export default function EntrySnippet({ 
	entry, 
	snippet, 
	onSelect,
	hideMeta = false,
}) {

	const buildDate = useRecoilValue(buildDateAtom)

	const infoTag = useMemo(() => {
		if (hideMeta || !buildDate || !entry.modified) return null
		const modified = new Date(entry.modified)
		if (modified > buildDate) return 'M'
		return null
	}, [buildDate, entry.modified, hideMeta])

	const handleClick = useCallback(e => {
		if (!onSelect) return true
		e.preventDefault()
		onSelect(entry.entryId)
	}, [onSelect, entry.entryId])

	// if (snippet.length === 0) snippet.push({ fieldId: 'id' })

	return <Link to={`/collection/${entry.collectionId}/id/${entry.entryId}`} className='EntrySnippet' onClick={handleClick}>

		{ snippet.map(field => <SnippetValue key={field.fieldId} field={field} value={entry.values[field.fieldId]} /> )}

		{/* { !hideMeta && <>
			<div className='infoColumn'>
				{ infoTag && 
					<span className={`tooltip infoTag it-${infoTag}`} 
						data-title={ infoTag === 'N' ? "New entry" : "Modified since last build" }
					>
						{infoTag}
					</span>
				}
				{ entry.tags?.length > 0 && <Tags tags={entry.tags} /> }
			</div>
			{ !!entry.modified && <div className='metaColumn'>
				<Timestamp live iso={entry.modified} />
			</div>}
		</>} */}
		
	</Link>
}


// function Tags({ tags }) {
// 	return tags.map((tag, idx) => 
// 		<span key={tag+idx}>{tag.split(' ')[0]}</span>)
// }


function SnippetValue({ field, value }) {
	if (value === null) return <code className='null'>null</code>
	if (field.type === 'URI') return <div><code>/{field?.config?.prefix || ''}</code>{value}</div>
	if (field.type === 'MODIFIED') return <code><Timestamp live iso={value} /></code>
	if (field.type === 'ID') return <div><code>{value}</code></div>
	return <div>
		{value}
	</div>
}
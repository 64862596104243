
import { useMemo, useState } from 'react'
import { useMutation, useQuery, gql } from '@apollo/client'

import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'
import Task from './Task'
import TaskEditor from './TaskEditor'

export const LIST_TASKS = gql`
	query Tasks {
		tasks {
			taskId
			name
			type
			config
			modified
		}
	}`

const CREATE_TASK = gql`
	mutation CreateAITask($name: String!, $type: String!, $config: JSON!) {
		createTask(name: $name, type: $type, config: $config)
	}`


export default function TaskList({ onSelect, selected }) {

	const [showCreate, setShowCreate] = useState(false)
	const { loading, error, data } = useQuery(LIST_TASKS)

	const [createTask, { loading: loadingCreate, error: createError }] = useMutation(CREATE_TASK, {
		refetchQueries: [LIST_TASKS]
	})

	const tasks = useMemo(() => {
		const tasks = data?.tasks || []
		return !!selected ? tasks.filter(t => t.taskId === selected.taskId) : tasks
	}, [data, selected])

	async function handleCreate(task) {
		await createTask({ variables: task })
		setShowCreate(false)
	}

	return <>

		<div className='GridTable TaskList'>
			{ tasks.map(task => 
				<Task key={task.taskId} task={task} 
					onSelect={onSelect} 
					isSelected={task.taskId === selected?.taskId}
				/> 
			)}
		</div>

		{ (!showCreate && !selected) && <button onClick={() => setShowCreate(true)}>New Task</button> }

		{ showCreate && <TaskEditor handleSave={handleCreate} onDiscard={() => setShowCreate(false)} /> }

		<Alert error={error} />

		<Alert error={createError} />
		{ loadingCreate && <Spinner /> }
		{ loading && <Spinner /> }

	</>
}



const RedirectIcon = () => (
	<svg viewBox="0 0 24 24" fill="currentColor" className="Icon">
		<path fillRule="evenodd" clipRule="evenodd" d="M16.2929 3.29286C16.6834 2.90234 17.3166 2.90234 17.7071 3.29286L22.7071 8.29286C23.0976 8.68339 23.0976 9.31655 22.7071 9.70708L17.7071 14.7071C17.3166 15.0976 16.6834 15.0976 16.2929 14.7071C15.9024 14.3166 15.9024 13.6834 16.2929 13.2929L19.5858 9.99997H8.76619L13.8575 18.4855C14.0429 18.7944 14.0477 19.1792 13.8702 19.4927C13.6927 19.8062 13.3603 20 13 20H2C1.44772 20 1 19.5523 1 19C1 18.4477 1.44772 18 2 18H11.2338L6.14251 9.51447C5.95715 9.20553 5.95229 8.82078 6.1298 8.50727C6.30731 8.19376 6.63973 7.99997 7 7.99997H19.5858L16.2929 4.70708C15.9024 4.31655 15.9024 3.68339 16.2929 3.29286Z" />
	</svg>
)


const OutIcon = () => (
	<svg viewBox="0 0 24 24" fill="currentColor" className="Icon">
		<path d="M20.2929 9.70708C20.5789 9.99307 21.009 10.0786 21.3827 9.92385C21.7564 9.76907 22 9.40443 22 8.99997V2.99997C22 2.44768 21.5523 1.99997 21 1.99997H15C14.5955 1.99997 14.2309 2.24361 14.0761 2.61729C13.9213 2.99096 14.0069 3.42108 14.2929 3.70708L16.2322 5.64641L9.58574 12.2929C9.19522 12.6834 9.19522 13.3166 9.58574 13.7071L10.2928 14.4142C10.6834 14.8048 11.3165 14.8048 11.7071 14.4142L18.3536 7.76774L20.2929 9.70708Z" />
		<path d="M4.5 8.00006C4.5 7.72392 4.72386 7.50006 5 7.50006H10.0625C10.6148 7.50006 11.0625 7.05234 11.0625 6.50006V5.50006C11.0625 4.94777 10.6148 4.50006 10.0625 4.50006H5C3.067 4.50006 1.5 6.06706 1.5 8.00006V19.0001C1.5 20.9331 3.067 22.5001 5 22.5001H16C17.933 22.5001 19.5 20.9331 19.5 19.0001V13.9376C19.5 13.3853 19.0523 12.9376 18.5 12.9376H17.5C16.9477 12.9376 16.5 13.3853 16.5 13.9376V19.0001C16.5 19.2762 16.2761 19.5001 16 19.5001H5C4.72386 19.5001 4.5 19.2762 4.5 19.0001V8.00006Z" />
	</svg>
)

const HtmlIcon = () => (
	<svg viewBox="0 0 20 20" fill="currentColor" className="Icon">
		<path d="M4 16v-2H2v2H1v-5h1v2h2v-2h1v5H4zM7 16v-4H5.6v-1h3.7v1H8v4H7zM10 16v-5h1l1.4 3.4h.1L14 11h1v5h-1v-3.1h-.1l-1.1 2.5h-.6l-1.1-2.5H11V16h-1zM19 16h-3v-5h1v4h2v1zM9.4 4.2L7.1 6.5l2.3 2.3-.6 1.2-3.5-3.5L8.8 3l.6 1.2zm1.2 4.6l2.3-2.3-2.3-2.3.6-1.2 3.5 3.5-3.5 3.5-.6-1.2z" />
	</svg>
)

const FullscreenIcon = () => (
	<svg fill="currentColor" className="Icon" viewBox="0 0 16 16">
		<path d="M14 3.414L9.414 8 14 12.586v-2.583h2V16h-6v-1.996h2.59L8 9.414l-4.59 4.59H6V16H0v-5.997h2v2.583L6.586 8 2 3.414v2.588H0V0h16v6.002h-2V3.414zm-1.415-1.413H10V0H6v2H3.415L8 6.586 12.585 2z" fillRule="evenodd" />
	</svg>
)

export default function Icon({ i }) {
	switch (i) {
		case 'out': return <OutIcon />
		case 'redirect': return <RedirectIcon />
		case 'html': return <HtmlIcon />
		case 'fullscreen': return <FullscreenIcon />
		default: return <div>Icon not found</div>
	}
}


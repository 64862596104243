
export function parseJsonValue(value) {
	let parsedValue = value
	if (typeof value === 'string') try { parsedValue = JSON.parse(value) } catch (_) { }
	if (typeof parsedValue === 'undefined') return null
	return parsedValue
}

export function serializeJsonValue(value) {
	return JSON.stringify(value)
}

export function deserializeJsonValue(value) {
	return JSON.parse(value)
}



const Diff = require('diff')
import classNames from 'classnames'


export default function Change({ change, fieldsMap, onRevert }) {

	// const added = change.old === null && change.new !== null
	// const removed = change.old !== null && change.new === null
	// const modified = change.old !== null && change.new !== null	&& change.old !== change.new
	// console.log(change)
	const diff = Diff.diffWords(`${change.old || ''}`, `${change.new || ''}`)

	return <div className='EntryChange'>
		<h4>{change.key}</h4>
		<div className='deltas'>
			<ChangeValue diff={diff} hide='added' isNull={change.old === null} />
			<div className='action'>&rarr;</div>
			<ChangeValue diff={diff} hide='removed' isNull={change.new === null} />
		</div>

	</div>
}

function ChangeValue({ isNull, diff, hide }) {

	if (isNull) return <div className='value'><code className='null'>null</code></div>

	return <div className='value'>
		{ diff.map((part, idx) => part[hide] ? null
			: <span key={idx} className={classNames({ added: part.added, removed: part.removed })}>{part.value}</span>
		)}
	</div>
}


import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'

import { GET_USERS } from './UsersList'

const DELETE_USER = gql`
	mutation DeleteUser($userId: ID!) {
		deleteUser(userId: $userId)
	}`


export default function DeleteUser({ userId }) {

	const navigate = useNavigate()

	const [deleteUser, { loading, error }] = useMutation(DELETE_USER, {
		refetchQueries: [{ query: GET_USERS }]
	})

	const handleDelete = useCallback(async () => {
		if (!confirm("Are you sure you want to delete this user account?"))
			return null
		try {
			await deleteUser({ variables: { userId }})
			alert("User deleted.")
			navigate('/users')
		} catch (error) { console.error(error) }
	}, [userId])

	return <div className='my-4'>
	
		<button onClick={handleDelete} className='delete small'>
			Delete user</button>

		{ loading && <Spinner /> }
		<Alert error={error} />

	</div>
}

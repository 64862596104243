

export default function Alert({ error, sticky = false }) {

	if (!error) return null

	return <details className={`Alert ${sticky ? 'isSticky' : ''}`} open>

		<summary>
			{ error.message || "Something went wrong." } <code>[{ error.name || "ERROR" }]</code>
		</summary>

		<pre>{JSON.stringify(error, null, 2)}</pre>

	</details>
}

import dayjs from 'dayjs'

import formatFileSize from '../utils/formatFileSize'


export default function FileModal({ file, handleClose }) {

	// const { navigate, isEmbed } = useContext(FilesContext)

	return <div className='FileModal'>

		<div className='Image'>

			{ file.mimeType?.startsWith('image/') &&
				<img 
					src={`${process.env.DAISY_CMS_URL}/files${file.path}`} 
					alt=''
				/> }

		</div>

		<div className='Info'>

			<button className='Close' onClick={handleClose}>
				Close
			</button>

			<p>{file.path}</p>
			<p>{file.mimeType}</p>
			<p>{formatFileSize(file.size)}</p>
			<p>Created {dayjs(file.created).format(`DD/MM/YYYY HH:mm`)}</p>
		</div>

	</div>
}
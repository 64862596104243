
import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import { USER_ROLES } from './roles'
import { GET_USER } from './UserPage'
import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'


const EDIT_USER = gql`
	mutation EditUser($userId: ID!, $name: String!, $email: String!, $role: String!) {
		editUser(userId: $userId, name: $name, email: $email, role: $role)
	}`

export default function EditUser({ user }) {

	const [editUser, { loading, error }] = useMutation(EDIT_USER, {
		refetchQueries: [{ query: GET_USER, variables: { userId: user.userId } }]
	})

	const handleSubmit = useCallback(async (event) => {
		event.preventDefault()
		const form = new FormData(event.target)
		try {
			await editUser({ variables: { 
				userId: user.userId,
				name: form.get('name'),
				email: form.get('email'),
				role: form.get('role'),
			}})			
		} catch (error) { console.error(error) }
	}, [])

	return <>
	
		<form onSubmit={handleSubmit} className='Form width-sm'>

			<h2>Edit account</h2>

			<fieldset>
				<legend>Name:</legend>
				<input type='text' required
					name='name'
					defaultValue={user.name}
				/>
			</fieldset>

			<fieldset>
				<legend>Email:</legend>
				<input type='email' required
					name='email'
					defaultValue={user.email}
				/>
			</fieldset>

			<fieldset>
				<legend>Role:</legend>
				<div className='RadioInput'>
					{ USER_ROLES.map(role => 
						<label key={role}>
							<input type='radio'
								name='role'
								value={role}
								defaultChecked={user.role === role}
							/> {role}
						</label>
					)}
				</div>
			</fieldset>

			<div>
				<button type='submit' disabled={loading} className='Button'>
					Edit account</button>

				{ loading && <Spinner /> }

			</div>

		</form>

		<Alert error={error} />
	
	</>
}


import { useEffect, useState, useContext, useMemo } from 'react'
import useCollection from '../collections/useCollection'
import { gql, useMutation } from '@apollo/client'
import { useImmer } from 'use-immer'

import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'
// import jason from '../utils/jason'
import EntryChange from '../entries/EntryChange'
// import { BulkEditContext } from '../collections/BulkEdit'
// import EntryContext from '../entries/EntryContext'


export const RUN_TASK = gql`
	mutation RunTask($taskId:ID!, $collectionId:ID!, $entryId:ID!) {
		runTask(taskId:$taskId, collectionId:$collectionId, entryId:$entryId)
	}`


export default function TaskRun({ 
	task, 
	entryId, 
	onFinish = () => {}, 
	handleUpdate = () => {}, 
	bulkEntryStatus,
	noButton = false,
	isSaved,
}) {

	const { collection: { collectionId } } = useCollection()
	const [runTask, { loading, error, data }] = useMutation(RUN_TASK)
	// const payload = data?.runTask
	// const changes = payload?.changes || null

	function handleRun() {
		console.log('run task', task)
		runTask({ variables: { taskId: task.taskId, collectionId, entryId }})
	}

	function handleSave() {
		if (!data?.runTask?.changes) return;
		handleUpdate(Object.fromEntries(Object.entries(data.runTask.changes).map(([key, change]) => [key, change[0]])))
	}

	useEffect(() => {
		if (!!error) { 
			onFinish({ error })
			return 
		}
		if (!!data?.runTask) 
			onFinish(data.runTask)
	}, [data, error])

	useEffect(() => {
		if (bulkEntryStatus === 'LOADING' && !loading && !data) {
			handleRun()
		} else if (bulkEntryStatus === 'SAVING' && !!data?.runTask?.changes) {
			handleSave()
		}
	}, [bulkEntryStatus, loading, data])

	return <>

		{ !noButton && <div>
			<button onClick={handleRun} disabled={loading} className='primary'>
				Run task
			</button>
		</div> }

		{ loading && <Spinner /> }

		{ !!data?.runTask && 
			<TaskChanges changes={data.runTask.changes} isSaved={isSaved} handleSave={handleSave} 
			/>
		}

		{ error && <Alert error={error} /> }

	</>
}


function TaskChanges({ changes, handleSave, isSaved = false }) {

	// const [usedKeys, setUsedKeys] = useImmer([])

	// function handleUse(key, change) {
	// 	handleUpdate({ [key]: change[ usedKeys.includes(key) ? 1 : 0 ] })
	// 	setUsedKeys(draft => {
	// 		if (draft.includes(key)) draft.splice(draft.indexOf(key), 1)
	// 			else draft.push(key)
	// 	})
	// }

	// function handleSave() {
	// 	handleUpdate(Object.fromEntries(Object.entries(changes).map(([key, change]) => [key, change[0]])))
	// }

	return <div className='TaskChanges'>

		{ !changes && <em>No changes.</em> }

		{ !!changes && <>
			<div className='TaskChangesList'>
				{ Object.entries(changes).map(([key, change]) => 
					<EntryChange key={key} change={{ key, old: change[1], new: change[0] }} />
				)}
			</div>

			<button onClick={() => handleSave()}>
				{ isSaved ? "✅" : "Save" }
			</button>
		</>}

	</div>
}


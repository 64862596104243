
export default function FieldLabel({ field, empty = false }) {

	const { fieldId, config: { name, required, description } } = field

	return <div className='FieldLabel'>
		
		<h4 data-title={`[${field.type}] ${fieldId}`} className='tooltip'>
			{ !name ? <em>{fieldId}</em> : name }:
		</h4>

		{ (required && empty) && <small className='required'>required</small> }

		{ !!description && <p>{description}</p> } 

	</div>
}
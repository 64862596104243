
import { useState, useMemo, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { produce } from 'immer'

import EntrySnippet from '../collections/EntrySnippet'
import Alert from '../utils/Alert'
import useCollection from '../collections/useCollection'
import Pagination from './Pagination'
// import jason from '../utils/jason'

export const GET_ENTRIES = gql`
	query GetEntries($collectionId:ID!, $offset:Int, $limit:Int, $search:String) {	
		entries(collectionId:$collectionId, offset:$offset, limit:$limit, search:$search) {
			collectionId
			entryId
			values
			tags 
		}
	}
`

const GET_COLLECTION_STATS = gql`
	query GetEntries($collectionId:ID!) {	
		collectionStats(collectionId:$collectionId)
	}
`

export default function Entries({ 
	selected = [], 
	onSelected = () => {},
	selectMode = false,
	forcedCollection,
}) {

	const { collection: contextCollection } = useCollection()
	const collection = forcedCollection || contextCollection

	const [offset, setOffset] = useState(0)
	const limit = 250
	const [search, setSearch] = useState('')

	const { data: statsData } = useQuery(GET_COLLECTION_STATS, {
		variables: { collectionId: collection.collectionId }
	})

	const { loading, error, data, fetchMore } = useQuery(GET_ENTRIES, { 
		variables: { 
			collectionId: collection.collectionId,
			offset,
			limit,
			search,
		},
		fetchPolicy: 'cache-and-network'
	})
	const entries = data?.entries || []
	const collectionStats = statsData?.collectionStats || {}
	const totalEntries = collectionStats.totalEntries || 0
	const entriesCount = entries.length

	const snippetHeaders = useMemo(() => {
		const headers = collection.snippet.map(field => field?.config?.name || "")
		if (headers.length === 0) headers.push('ID')
		return headers
	}, [collection.snippet])

	function handleChange(event) {
		handleToggle(event.target.name)
	}

	function handleToggle(entryId) {
		onSelected(produce(selected, draft => {
			const idx = draft.indexOf(entryId)
			if (idx === -1) draft.push(entryId)
				else draft.splice(idx, 1)
		}))
	}

	function handleToggleAll(event) {
		onSelected(event.target.checked ? entries.map(entry => entry.entryId) : [])
	}

	function handleSearch(event) {
		event.preventDefault()
		setOffset(0)
		fetchMore({ variables: { search }})
	}

	useEffect(() => {
		fetchMore({ variables: { offset }})
	}, [offset])

	return <>

		<div className='Menu EntriesMenu'>

			<form onSubmit={handleSearch} className='EntriesSearch'>

				<input type='search' placeholder='Search' value={search} onChange={e => setSearch(e.target.value)} />

				{ !!search && <>
					{ entriesCount === 0 ? "No" : entriesCount }{ entriesCount === limit ? '+' : ''} {entriesCount === 1 ? "entry" : "entries"} found
				</>}
			</form>

				
			{ !search &&
				<Pagination offset={offset} setOffset={setOffset} limit={limit} totalEntries={totalEntries} loading={loading} />
			}

		</div>

		{ error && <Alert error={error} /> }

		{ entries.length > 0 &&
			<div className='Entries'
				style={{ 
					gridTemplateColumns: `min-content [snippet-start] repeat(${snippetHeaders.length}, auto) [snippet-end]` 
				}}
			>
				<div className='EntriesHeaders'>
					<div>
						<input type='checkbox' checked={selected.length === entries.length} onChange={handleToggleAll} />
					</div>
					{/* <div>[meta]</div> */}
					<div className='EntriesSnippetHeaders'>
						{ snippetHeaders.map((fieldName, idx) =>
							<div key={`${fieldName}-${idx}`}>{fieldName}</div> )}
					</div>
				</div>

				{ entries.map(entry => <div className='EntriesEntry' key={entry.entryId}>
					<div>
						<input type='checkbox' name={entry.entryId} checked={selected.includes(entry.entryId)} onChange={handleChange} />
					</div>
					{/* <div>meta</div> */}
					<EntrySnippet snippet={collection.snippet} entry={entry} onSelect={selectMode && handleToggle} />
				</div>)}

			</div>
		}

		{ !search && totalEntries > limit &&
			<Pagination
				setOffset={setOffset}	
				offset={offset}
				limit={limit}
				totalEntries={totalEntries}
				loading={loading}
			/>
		}

		{/* {jason(collection, 'collection')} */}

	</>
}


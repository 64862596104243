
import { Link } from 'react-router-dom'
import { useCallback } from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

import Title from '../utils/Title'
import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'


const GET_TOKENS = gql`
	query Tokens {
		tokens {
			tokenId
			label
			created
			expiresIn
			token
			created
		}
	}`

const DELETE_TOKEN = gql`
	mutation DeleteToken($tokenId: ID!) {
		deleteToken(tokenId: $tokenId)
	}`


export default function TokensPage() {

	const { data, loading, error } = useQuery(GET_TOKENS)
	const tokens = data?.tokens || []

	const [deleteToken] = useMutation(DELETE_TOKEN, {
		refetchQueries: [{ query: GET_TOKENS }]
	})

	const handleDelete = useCallback(tokenId => {
		deleteToken({ variables: { tokenId }})
	}, [])

	return <>

		<Title>Access Tokens</Title>

		<AddToken />

		<Alert error={error} />

		<br />

		<div className='ItemsList'>
			{ tokens.map(token =>
				<Token token={token} key={token.tokenId} handleDelete={handleDelete} /> )}
		</div>

		{ loading && <Spinner /> }

	</>
}


const ADD_TOKEN = gql`
	mutation AddToken($label: String!, $expiresIn: String!) {
		addToken(label: $label, expiresIn: $expiresIn)
	}
`

function AddToken() {

	const [addToken, { data, loading, error }] = useMutation(ADD_TOKEN, {
		refetchQueries: [{ query: GET_TOKENS }]
	})

	const handleSubmit = useCallback(async (event) => {
		event.preventDefault()
		try {
			const form = new FormData(event.target)
			await addToken({ variables: { 
				label: form.get('label'),
				expiresIn: form.get('expiresIn'),
			}})
			event.target.reset()
		} catch (error) { console.error(error) }
	}, [])

	return <>
	
		<form onSubmit={handleSubmit} className='Form width-sm'>

			<h2>Add token</h2>

			<fieldset>
				<legend>Label:</legend>
				<input required
					type='text'
					name='label'
					defaultValue=''
					placeholder=''
					className='Input'
				/>
			</fieldset>

			<fieldset>
				<legend>Expires in:</legend>
				<select required
					name='expiresIn'
					defaultValue='1 year'
					className='Input'
				>
					<option>1 year</option>
					{/* <option>6 months</option> */}
					<option>30 days</option>
					<option>7 days</option>
					<option>1 day</option>
				</select>
			</fieldset>

			<div className='Submit'>
				<button type='submit' disabled={loading}>Add token</button>
				{ loading && <Spinner /> }
			</div>

		</form>
		
		<Alert error={error} />

	</>
}


function Token({ token, handleDelete }) {

	const expiresSplit = token.expiresIn.split(' ')
	const expires = dayjs(token.created).add(parseInt(expiresSplit[0]), expiresSplit[1])
	const expired = expires.isBefore()

	return <div className='DeveloperToken'>

		<button 
			className='delete'
			onClick={() => handleDelete(token.tokenId)}>
			Delete
		</button>

		<h3>{token.label}</h3>

		<p>
			<span className={expired ? 'danger' : ''}>{ expired ? "Expired" : "Expires" } {expires.fromNow()}</span> &mdash; {expires.format('D MMMM YYYY')}
		</p>
		
		<code>
			{token.token}</code>

	</div>
}


import { useContext, useState } from 'react'

import TaskList from '../tasks/TaskList'
import TaskRun from '../tasks/TaskRun'
// import Task from '../tasks/Task'
import EntryContext from './EntryContext'


export default function EntryTasks() {

	const [task, setTask] = useState(null)

	const { entryId, setDraft } = useContext(EntryContext)

	function handleUpdate(values) {
		console.log(values)
		setDraft(draft => { 
			for (const [fieldId, value] of Object.entries(values)) {
				draft[fieldId] = value
			}
			// console.log(fieldId, value)
			// draft.provider = 'dupa'
		})
	}

	return <>

		<p><small>Note: Tasks are performed on saved values only. Save changes first to run tasks on them.</small></p>

		<TaskList onSelect={t => setTask(t)} selected={task} />

		{ !!task && <>
			{/* <Task task={task} /> */}
			<TaskRun task={task} entryId={entryId} handleUpdate={handleUpdate} />
		</>}

		{/* <TaskRun task={task} entryId={entryId} onFinish={handleFinish} handleUpdate={handleUpdate} bulkEntryStatus={entry.status} /> */}

	</>
}

import { useContext, useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import { FilesContext } from './Files'
import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'

const NEW_FOLDER = gql`
	mutation NewFolder($folderPath: String!) {
		newFolder(folderPath: $folderPath)
	}
`


export default function NewFolder() {

	const { path, refetch } = useContext(FilesContext)
	const [newFolder, { error, loading }] = useMutation(NEW_FOLDER, {
		// refetchQueries: [{ query: GET_COLLECTION, variables: { collectionId } }]
	})

	const onMkdir = useCallback(async () => {
		let name = prompt("Folder name:", "New folder")
		if (!name) return false
		name = `${name}`.trim()
		if (name === "") name = "New folder"
		newFolder({ variables: { folderPath: `${path}/${name}`}})
		refetch()
	}, [path])
	
	return <div>
	
		<button onClick={onMkdir}>New folder</button>

		{ loading && <Spinner /> }
		<Alert error={error} />

	</div>
}

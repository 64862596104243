
import { NavLink, Routes, Route } from 'react-router-dom'

import TokensPage from './TokensPage'
import BuildPage from './BuildPage'


export default function BuildRouter() {

	return <>
		<header>
			<nav>
				<NavLink to={`/build`} end>Build</NavLink>
				<NavLink to={`/build/tokens`} end>Tokens</NavLink>
			</nav>
		</header>

		<Routes>
			<Route index element={<BuildPage />} />
			<Route path='/tokens' element={<TokensPage />} />
		</Routes>

	</>
}


export default function NumberOptions({ field, update }) {

	return <div className='FieldOptions FieldOptionsCustom'>
		
		{/* <fieldset>
			<label>
				<input type='checkbox'
					checked={field.isFloat || ''}
					onChange={e => update(d => { d.isFloat = e.target.value })}
				/> allow floating point values
			</label>
		</fieldset> */}

		{/* <fieldset>
			<legend>Min value:</legend>
			<input type='number'
				value={field.minValue || ''}
				onChange={e => update(d => { d.minValue = Number(e.target.value) })}
			/>
		</fieldset>

		<fieldset>
			<legend>Max value:</legend>
			<input type='number'
				value={field.maxValue || ''}
				onChange={e => update(d => { d.maxValue = Number(e.target.value) })}
			/>
		</fieldset>

		<fieldset>
			<legend>Step:</legend>
			<input type='number'
				value={field.step || ''}
				onChange={e => update(d => { d.step = e.target.value })}
			/>
		</fieldset>

		<fieldset>
			<legend>Prefix:</legend>
			<input type='text'
				value={field.prefix || ''}
				onChange={e => update(d => { d.prefix = e.target.value.trim() })}
			/>
		</fieldset>

		<fieldset>
			<legend>Unit:</legend>
			<input type='text'
				value={field.unit || ''}
				onChange={e => update(d => { d.unit = e.target.value.trim() })}
			/>
		</fieldset> */}

			{/* <div>
			Decimal points:
			<input type='number'
				min='0'
				value={field.decimals || ''}
				onChange={e => update(d => { d.decimals = parseInt(e.target.value) })}
			/>
		</div> */}

		{/* <div>
			Placeholder:
			<input
				type='text'
				name='placeholder'
				onBlur={handleChange}
				defaultValue={field.placeholder ?? ''}
			/>
		</div> */}

		{/* <div>
			Suggested values:
			<textarea
				name='suggested'
				onBlur={handleChange}
				rows='5'
				defaultValue={Array.isArray(field.suggested) ? field.suggested.join(`
`) : ''}
			/> <small>One per line</small>
		</div> */}
	
	</div>
}
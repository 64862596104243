
import { useState, useEffect, useMemo } from 'react'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)


export default function Timestamp({ iso, live = false }) {

	const [fromNow, setFromNow] = useState(() => dayjs(iso).fromNow())
	const format = useMemo(() => dayjs(iso).format('llll'), [iso])

	useEffect(() => {
		if (!iso) return
		setFromNow(dayjs(iso).fromNow())
		if (!live) return
		const intervalId = setInterval(() => {
			setFromNow(dayjs(iso).fromNow())
		}, 30 * 1000)
		return () => clearInterval(intervalId)
	}, [iso, live])

	return <span data-title={format} className='timestamp tooltip'>
		{fromNow}
	</span>
}

import { useContext } from 'react'
import mime from 'mime'

import FilesLink from './FilesLink'
import { FilesContext } from './Files'
import getThumbUrl from '../utils/getThumbUrl'


export default function FileItem({ item }) {

	const { setSelected } = useContext(FilesContext)

	const thumbUrl = getThumbUrl(item.path)

	function onChange(event) {
		setSelected(draft => {
			if (event.target.checked) {
				draft.push(item.path)
			} else {
				const idx = draft.indexOf(item.path)
				if (idx > -1) draft.splice(idx, 1)
			}
		})
	}

	// const path = useMemo(() => {
	// 	const regex = /\/(?!.*\/)/
	// 	return item.path.replace(regex, '#')
	// }, [item.path])

	const isImage = mime.getType(item.path)?.startsWith('image/')

	return <div className='ImageFile'>

		<FilesLink path={item.path} type='file'>
			{ isImage
				? <img src={thumbUrl} alt={thumbUrl} />
				: '📄' }
		</FilesLink>

		<label>
			<input type='checkbox'
				onChange={onChange}
				checked={!!item.selected}
			/>
			<span>{item.name}</span>
		</label>

	</div>
}
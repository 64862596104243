
import { useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'

import useCollection from './useCollection'
import { GET_COLLECTION } from './CollectionProvider'
import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'

const DROP_COLUMN = gql`
	mutation DropColumn($collectionId:ID!, $columnName:String!) {
		dropColumn(collectionId:$collectionId, columnName:$columnName)
	}`


export default function UnclaimedColumns() {

	const { collection } = useCollection()

	const [dropColumn, { loading: loadingDropColumn, error: dropColumnError }] = useMutation(DROP_COLUMN, {
		refetchQueries: [{ query: GET_COLLECTION, variables: { collectionId: collection.collectionId } }],
	})

	const unclaimedColumns = useMemo(() => {
		const claimedColumns = collection.fields.map(field => field.fieldId)
		return collection.columns.filter(column => !claimedColumns.includes(column.name))
	}, [collection])

	function handleDropColumn(columnName) {
		if (!window.confirm(`Are you sure you want to drop column ${columnName}? All data in it will be lost.`)) return
		console.log('dropping column', columnName)
		dropColumn({ variables: { collectionId: collection.collectionId, columnName } })
	}
	
	return <>

		{ loadingDropColumn && <Spinner /> }
		{ !!dropColumnError && <Alert error={dropColumnError} /> }

		{ unclaimedColumns.length > 0 && <>
			<h3>Unclaimed columns</h3>
			{ unclaimedColumns.map(column => <div key={column.name} className='box'>
				<button onClick={() => handleDropColumn(column.name)} className='small delete float-right'>Drop column</button>
				<pre>{JSON.stringify(column, null, 2)}</pre>
			</div>)}

		</>}

	</>
}


import { useState, useEffect } from 'react'


export default function JsonInput({ field, value, update }) {

	const { config } = field
	const [draft, setDraft] = useState(JSON.stringify(value, null, 2))

	function onBlur() {
		if (!!update) update(draft === '' ? null : draft)
	}

	useEffect(() => {
		if (value !== draft) setDraft(JSON.stringify(value, null, 2))
	}, [value])
	
	return <div className='FieldInput JSONinput'>
		
		<textarea
			value={draft}
			onChange={e => setDraft(e.target.value)}
			onBlur={onBlur}
			rows={config.rows || 5}
			className='monospace input'
		/>
		{/* <details><summary>raw</summary><code>{JSON.stringify(value, null, 2)}</code></details> */}

		{/* { badRegexp && <div className='InputInfo'><span className='warning'>Must match: <code>{config.regExp}</code></span></div>} */}

	</div>
}


import shallowMerge from '../utils/shallowMerge.js'
import { parseJsonValue, serializeJsonValue, deserializeJsonValue } from '../utils/json.js'

export default class Field {

	prevId = null

	type = null
	fieldId = null
	collectionId = null
	isCommon = false

	config = {
		name: "",
		description: "",
		multi: false,
		required: false,
	}

	// options = {}

	_columnType = 'TEXT'
	columnOptions = ''
	_gqlType = 'JSON'
	
	constructor(initVals) {
		this.modify(initVals)
	}

	get columnType() {
		return this.config.multi ? `JSON` : this._columnType
	}

	get gqlType() {
		return this._gqlType
	}

	get columnDefinition() {
		return `${this.fieldId} ${this.columnType} ${this.columnOptions || ''}`.trim()
	}

	
	get json() {
		return {
			type: this.type,
			fieldId: this.fieldId,
			collectionId: this.collectionId,
			isCommon: this.isCommon,
			config: this.config,
			// options: this.options,
		}
	}

	
	modify(values = {}) {
		this.type = values.type ?? this.type
		if (this.type === 'ID') this.fieldId = 'id'
		if (!!values.fieldId && this.prevId === null) this.prevId = this.fieldId
		this.fieldId = values.fieldId ?? this.fieldId
		this.collectionId = values.collectionId ?? this.collectionId
		this.config = shallowMerge(this.config, values.config)
		if (!this.config.name) this.config.name = this.fieldId
		// this.options = shallowMerge(this.options, values.options)
	}

	_parseValue(value) {
		return value
	}
	parseValue(value) {
		return this.config.multi ? parseJsonValue(value) : this._parseValue(value)
	}

	_serializeValue(value) {
		return value
	}
	serializeValue(value) {
		return this.config.multi ? serializeJsonValue(value) : this._serializeValue(value)
	}

	_exportValue(value) {
		return value
	}
	exportValue(value) {
		return this._exportValue(value)
	}
	
}

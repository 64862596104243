

export default function getValuesDiff(prevObj, nextObj) {

	const changedValues = []
	const allKeys = new Set([...Object.keys(prevObj), ...Object.keys(nextObj)])

	for (const key of allKeys) {
		const oldVal = prevObj[key] ?? null
		const newVal = nextObj[key] ?? null
		if (oldVal !== newVal) {
			changedValues.push({
				key,
				old: oldVal,
				new: newVal
			})
		}
	}

	return changedValues
}
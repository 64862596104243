
import { useCallback, useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { gql, useMutation } from '@apollo/client'

dayjs.extend(relativeTime)

import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'
import { GET_LOGS } from './LogsPage'

const DELETE_LOGS = gql`
	mutation DeleteLogs($beforeDate: DateTime!) {
		deleteLogs(beforeDate: $beforeDate)
	}
`

export default function DeleteLogs() {

	const [date, setDate] = useState(dayjs().subtract(1, 'month').format('YYYY-MM-DD'))
	
	const [deleteLogs, { loading, error, data }] = useMutation(DELETE_LOGS, {
		refetchQueries: [{ query: GET_LOGS }]
	})

	const handleSubmit = useCallback((event) => {
		event.preventDefault()
		deleteLogs({ variables: { beforeDate: date } })
	}, [date])

	console.log(data)
	
	return <form onSubmit={handleSubmit}>

		Remove logs before date:
		<input type='date'
			value={date}
			onChange={e => setDate(e.target.value)}
		/>
		<button type='submit'>Remove</button>

		{ loading && <Spinner/> }
		{ error && <Alert error={error.message} /> }

		{ data && <p>Removed: {data.deleteLogs} logs</p> }

	</form>
}

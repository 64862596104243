
import { useState, useEffect } from 'react'

import MultiInput from './MultiInput'


function NumberInputSingle({ field, value, update }) {
	
	const [draft, setDraft] = useState(value)

	// const props = {}
	// if (typeof field.minValue === 'number') props.min = field.minValue
	// if (typeof field.maxValue === 'number') props.max = field.maxValue
	// if (!!field.step) props.step = field.step
	// const fieldId = `field-${field.id}`
	// if (Array.isArray(field.suggested)) props.list = fieldId

	function onChange(event) {
		setDraft(event.target.value)
	}

	function onBlur() {
		update(draft === '' ? null : Number(draft))
	}

	useEffect(() => {
		setDraft(value)
	}, [value])

	return <div>

		{/* { !!field.prefix && 
			<span>{field.prefix}</span> } */}

		<input type='number' value={draft ?? ''}
			onChange={onChange}
			onBlur={onBlur}
			// {...props}
		/>

		{/* { !!field.unit && 
			<em>{field.unit}</em> } */}

		{/* { Array.isArray(field.suggestedValues) && 
			<datalist id={fieldId}>
				{ field.suggested.map(d => <option key={d} value={d} />) }
			</datalist> 
		} */}

	</div>
}


export default function NumberInput({ field, value, update }) {
	return !field.multi
		?	<NumberInputSingle field={field} value={value} update={update} />
		:	<MultiInput field={field} value={value} update={update}>
				<NumberInputSingle />
			</MultiInput>
}

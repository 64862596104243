
import { useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'

import { GET_COLLECTIONS } from '../layout/Collections'
import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'
import useCollection from './useCollection'

const EMPTY_COLLECTION = gql`
	mutation EmptyCollection($collectionId:ID!) {
		emptyCollection(collectionId:$collectionId)
	}
`

export default function EmptyCollection() {

	const { collection: { collectionId } } = useCollection()
	const navigate = useNavigate()
	const [emptyCollection, { loading, error }] = useMutation(EMPTY_COLLECTION, {
		refetchQueries: [{ query: GET_COLLECTIONS }]
	})

	async function handleDelete() {
		if (!confirm(`Are you sure? All data will be lost. The collection schema will be preserved.`)) return false
		await emptyCollection({ variables: { collectionId } })
		navigate(`/collection/${collectionId}`)
	}

	return <>

		<button onClick={() => handleDelete()} className='delete small' disabled={loading}>
			Empty collection
		</button>

		{ loading && <Spinner /> }
		{ error && <Alert error={error} /> }

	</>
}

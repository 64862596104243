
import { Link, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import EditUser from './EditUser'
import DeleteUser from './DeleteUser'
import ResetPassword from './ResetPassword'
import Permissions from './Permissions'
import UserSnippet from './UserSnippet'
import Title from '../utils/Title'
import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'

const GET_USER = gql`
	query GetUser($userId: ID!, $token: String) {
		user(userId: $userId, token: $token) {
			userId
			name
			email
			emailHash
			role
			permissions
		}
	}`

export { GET_USER }


export default function UserPage() {

	const { userId } = useParams()
	const { data, loading: loadingUser, error: userError } = useQuery(GET_USER, { variables: { userId }})
	const user = data?.user || null
	const title = user?.name || "User"

	return <>

		<Title>{title}</Title>
		<div className='Heading SubHeading'>
			<h4><Link to='/users/'>Users</Link></h4>
			<h1>{title}</h1>
		</div>

		<Alert error={userError} />

		{ !!user && <>

			<UserSnippet user={user} />
			<hr />

			<EditUser user={user} />
			<hr />
			
			{ user.role === 'EDITOR' && <>
				<Permissions user={user} />
				<hr />
			</>}

			<h3>Danger zone</h3>
			<ResetPassword userId={userId} />
			<br />
			<DeleteUser userId={userId} />

		</>}

		{ loadingUser && <Spinner /> }

	</>
}

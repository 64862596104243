
import { useQuery } from '@apollo/client'

import { GET_COLLECTIONS } from '../layout/Collections'
import QueryStatus from '../utils/QueryStatus'


export default function ReferenceOptions({ config, update }) {

	const { loading, error, data } = useQuery(GET_COLLECTIONS)
	const collections = data?.collections || []

	return <div className='FieldOptions FieldOptionsCustom'>

		<fieldset>
			<legend>Collection:</legend> 
			<select
				value={config.collectionId || ''}
				onChange={e => update(d => { d.collectionId = e.target.value })}
			>
				<option value=''></option>
				{ collections.map(({ collectionId, name, icon }) => 
					<option key={collectionId} value={collectionId}>
						{icon} {name}
					</option> 
				)}
			</select>
		</fieldset>

		<QueryStatus loading={loading} error={error} />

	</div>
}

import { cloneElement, useMemo } from 'react'
import { produce } from 'immer'
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move'


export default function MultiInput({ field, value, update, children }) {

	function handleAdd() {
		update(produce(value, draft => {
			if (!Array.isArray(draft)) return [{}]
			draft.push({})
		}))
	}

	function handleRemove(idx) {
		update(produce(value, draft => { draft.splice(idx, 1) }))
	}

	function handleSortEnd(oldIndex, newIndex) {
		// update(produce(value, draft => arrayMoveImmutable(draft, oldIndex, newIndex)))
	}

	const items = useMemo(() => {
		if (!Array.isArray(value)) return []
		const keyFreshness = Date.now()
		return value.map((val, idx) => 
			<SortableItem key={`${idx}-${keyFreshness}`}>
				<div className='Item'>

					<SortableKnob>
						<div> ↕️ </div>
					</SortableKnob>
					
					{ cloneElement(children, { 
							field, 
							value: val,
							update: val => update(produce(value, draft => { draft.splice(idx, 1, val) }))
						})
					}

					<div>
						<button onClick={() => handleRemove(idx)}>x</button>
					</div>

				</div>
			</SortableItem>
		)
	}, [field, value, children])

	return <>

		<SortableList className='GridTable MultiInputList' draggedItemClassName='dragged'
			onSortEnd={handleSortEnd} 
		>
			{ items }
		</SortableList>

		<button onClick={handleAdd}>Add item</button>

	</>
}


import Alert from '../utils/Alert'
import formatFileSize from '../utils/formatFileSize'


export default function UploadProgress({ progress, onDiscard }) {

	if (progress?.error)
		return <Alert error={progress.error} />

	return <div>

		{ progress.done && <>
			Done uploading {progress.fileCount} files.<br />
			{formatFileSize(progress.total)}<br />
		</>}

		{!progress.done && <>
			Uploading {progress.fileCount} files...<br />
			{Math.round((progress.loaded * 100) / progress.total)}% of {formatFileSize(progress.total)}<br />
		</>}

		<button onClick={onDiscard}>x</button>

	</div>
}
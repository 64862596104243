
import { useMutation, gql, useQuery } from '@apollo/client'
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move'

import { GET_COLLECTIONS } from '../layout/Collections'
import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'

const REORDER_COLLECTIONS = gql`
	mutation ReorderCollections($positions:[ID]!) {
		reorderCollections(positions:$positions)
	}
`


export default function ReorderCollections({ }) {

	const { loading, error, data } = useQuery(GET_COLLECTIONS)

	const [reorderCollections, { loading: loadingReorder, error: reorderError }] = useMutation(REORDER_COLLECTIONS, {
		refetchQueries: [{ query: GET_COLLECTIONS }],
	})

	const collections = data?.collections ?? []

	function handleSortEnd(oldIndex, newIndex) {
		const newOrder = arrayMoveImmutable(collections, oldIndex, newIndex)
		const positions = newOrder.map(({collectionId}) => collectionId)
		reorderCollections({ variables: { positions } })
	}

	return <>

		<h2>Reorder collections</h2>

		{ (!!error || !!reorderError) && <Alert error={error || reorderError} /> }

		{ (Array.isArray(collections) && collections.length > 0) && 
			<SortableList className='MultiFieldSortableList' draggedItemClassName='dragged' onSortEnd={handleSortEnd}>
				{ collections.map(c => (
					<SortableItem key={c.collectionId}>
						<div className='SortableItem'>
							<SortableKnob>
								<div>↕️ {c.icon} {c.name}</div>
							</SortableKnob>
						</div>
					</SortableItem>
				))}
			</SortableList>
		}

		{ loading || loadingReorder && <Spinner /> }


	</>
}
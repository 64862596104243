
export default function generateGPTPrompt(config, fields, values) {

	let prompt = `${config.prompt}`

	if (config.input?.length > 0 && !!values && typeof values === 'object') {
		prompt += `\n\nUse the information below to generate the answer:\n\n`
		const inputValues =	config.input.map(id => {
			const field = fields.find(f => f.fieldId === id)
			if (!field || !values[field.fieldId]) return ''
			return `${field.config?.name || field.fieldId}${!!field.config?.desc ? ` (${field.config?.desc})` : '' }:\n${values[field.fieldId]}\n`
		})
		prompt += inputValues.join('\n')
	}

	if (config.output?.length > 0) {
		prompt += `\n\nReply in JSON object format with `
		const outputFields = config.output.map(fieldId => {
			const field = fields.find(f => f.fieldId === fieldId)
			return !field ? '' : `"${field.config?.name || field.fieldId}" under the key "${field.fieldId}"`
		})
		prompt += outputFields.join(', ') + "."
	}

	return prompt
}


export default function Spinner({ children, show = true }) {

	if (typeof show === 'undefined') show = true

	return <div role='status' className='spinner'>

		<svg className="spinner-svg" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">

			{ !!show && 
				<circle className="spinner-circle"
					cx="25"
					cy="25"
					r="20"
					fill="none"
					strokeWidth="4"
				/>
			}
		</svg>

		{ !!children && <span>{children}</span> }

	</div>
}

import { gql, useQuery, useMutation } from '@apollo/client'

import Title from '../utils/Title'
// import ImportUpload from './ImportUpload'
import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'
import ImportMap from './ImportMap'
// import Timestamp from '../utils/Timestamp'
// import formatFileSize from '../utils/formatFileSize'
// import { Note } from '../utils/Notifications'


export const GET_IMPORT_STUFF = gql`
	query GetImportMaps {
		# importFiles {
		# 	filename
		# 	mimeType
		# 	size
		# 	created
		# }
		importMaps {
			importMapId
			config
			modified
		}
	}`

const CREATE_IMPORT_MAP = gql`
	mutation CreateImportMap($config:JSON!) {
		createImportMap(config:$config)
	}`

// const DELETE_IMPORT_FILE = gql`
// 	mutation DeleteImportFile($filename:String!) {
// 		deleteImportFile(filename:$filename)
// 	}`


export default function ImportPage() {

	const { data, loading, error, refetch } = useQuery(GET_IMPORT_STUFF)
	const [createImportMap, { loading: loadingCreate, error: createError }] = useMutation(CREATE_IMPORT_MAP)
	// const [deleteImportFile, { loading: loadingDelete, error: deleteError }] = useMutation(DELETE_IMPORT_FILE)

	const importFiles = data?.importFiles || []
	const importMaps = data?.importMaps || []

	async function handleCreate() {
		const name = prompt('Name for new Import Map:') || "An Import Map"
		await createImportMap({
			variables: { config: { name } },
			refetchQueries: [{ query: GET_IMPORT_STUFF }]
		})
	}

	// async function deleteFile(filename) {
	// 	if (confirm(`Delete source file ${filename}?`)) await deleteImportFile({
	// 		variables: { filename },
	// 		refetchQueries: [{ query: GET_IMPORT_STUFF }]
	// 	})
	// }

	return <>

		<Title>Import</Title>

		<h1>Import</h1>

		{ error && <Alert error={error} /> }

		{/* <h2>Source Files</h2> */}

		{/* <ImportUpload onUpload={() => refetch()} /> */}
		{/* <ul>
			{ importFiles.map(file => <li key={file.filename}>
				{file.filename} &mdash; {formatFileSize(file.size)} &mdash; <Timestamp iso={file.created} /> &mdash; <button onClick={() => deleteFile(file.filename)} className='delete small'>delete</button>
			</li>) }
		</ul>
		<Note small>Uploaded source files are stored in a temporary folder ignored by version control.</Note>
		{ loadingDelete && <Spinner /> }
		{ deleteError && <Alert error={deleteError} /> } */}
		
		{/* <h2>Import Maps</h2> */}

		<button onClick={() => handleCreate()} disabled={loadingCreate}>Add import map</button>
		{ createError && <Alert error={createError} /> }
		{ loadingCreate && <Spinner /> }

		{ importMaps.map(map => <ImportMap key={map.importMapId} importMap={map} files={importFiles} />) }

		{ loading && <Spinner /> }

		{/* {jason(data, 'data')} */}

	</>
}
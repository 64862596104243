
import Field from './Field.js'


export default class TextField extends Field {

	type = 'TEXT'
	_columnType = 'TEXT'
	_gqlType = 'String'

	options = {
		rows: 5,
	}

	constructor(initVals) {
		super(initVals)
	}

	static parseValue(value) {
		let parsedValue = value
		switch (typeof value) {
			case 'undefined':
				return null
			case 'object':
				if (value === null) return null
				try { parsedValue = JSON.stringify(value) } catch (_) { }
				parsedValue = `${parsedValue}`
				break
			case 'boolean':
				parsedValue = value ? 'true' : 'false'
				break
			default:
				parsedValue = `${parsedValue}`
				break
		}
		return parsedValue ?? null
	}

	_parseValue(value) {
		return TextField.parseValue(value)
	}
}

import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

import Title from '../utils/Title'
import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'
import Avatar from '../users/Avatar'
import DeleteLogs from './DeleteLogs'
import LOGTYPES from './logTypes'


export const GET_LOGS = gql`
	query Logs {
		logs {
			logId
			userId
			created
			type
			vals
			name
			email
			emailHash
		}
	}`


export default function LogPage() {
	const { data, loading, error } = useQuery(GET_LOGS)
	const logs = data?.logs || []
	return <>

		<Title>Logs</Title>
				
		<div className='Heading'>
			<h1>Logs</h1>
		</div>

		<DeleteLogs />
		<hr />

		{ loading && <Spinner /> }
		<Alert error={error} />

		<div className='Logs'>
			{ logs.map(log =>
				<Log log={log} key={log.logId} /> )}
		</div>
	</>
}


function Log({ log }) {
	return <div className='LogLine'>

		<div className='LogDate'>
			{ dayjs(log.created).format('HH:mm ddd DD/MM ') }
		</div>

		<div className='LogUser'>
			<Avatar emailHash={log.emailHash} 
				className='LogAvatar' 
				size={64} 
			/>
		</div>

		<div className='LogDesc'>
			<strong>{log.name}</strong> &nbsp;
			{ !!LOGTYPES[log.type]
				? LOGTYPES[log.type](log) 
				: log.type
			}
		</div>

	</div>
}

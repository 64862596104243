
import { useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useSetRecoilState } from 'recoil'

import Timestamp from '../utils/Timestamp'
import LiveDuration from '../utils/LiveDuration'
import Spinner from '../utils/Spinner'
import { buildDateAtom } from './atoms'


const GET_BUILD = gql`
	query GetBuild {
		latestBuild {
			buildId
			started
			finished
			status
		}
		latestFinishedBuild: latestBuild(status: "FINISHED") {
			buildId
			started
			finished
			status
		}
	}`

const BUILD_SUB = gql`
	subscription BuildSub {
		latestBuild {
			buildId
			started
			finished
			status
		}
	}`


export default function BuildInfo() {

	const setBuildDate = useSetRecoilState(buildDateAtom)
	const { data, loading, error, subscribeToMore } = useQuery(GET_BUILD)
	// const build = buildData?.latestBuild || {}
	const build = data?.latestBuild
	const finishedBuild = data?.latestBuild?.status === 'FINISHED' ? build : data?.latestFinishedBuild

	useEffect(() => {
		subscribeToMore({
			document: BUILD_SUB,
			variables: {},
			updateQuery: (prev, { subscriptionData: { data: { latestBuild } } }) => {
				return Object.assign({}, prev, { latestBuild } )
			}
		})
	}, [])

	useEffect(() => {
		if (finishedBuild?.started) setBuildDate(new Date(finishedBuild.started))
	}, [finishedBuild])

	return <div id='BuildInfo'>

		{ loading && <Spinner /> }

		{ build?.status === 'RUNNING'
			? <><Spinner /> <LiveDuration startIso={build.started} finishIso={build.finished} /></>
			: !!finishedBuild
			? <>✅ <Timestamp live iso={finishedBuild.started} /></>
			: !!build
			? <>❌ <Timestamp live iso={build.finished} /></>
			: null
		}

	</div>
}


import { useCallback } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'

import { USER_ROLES } from './roles'
import { GET_USERS } from './UsersList'
import Title from '../utils/Title'
import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'

const ADD_USER = gql`
	mutation AddUser($name: String!, $email: String!, $password: String!, $role: String!) {
		addUser(name: $name, email: $email, password: $password, role: $role) {
			userId
		}
	}`


export default function AddUserPage() {

	const navigate = useNavigate()

	const [addUser, { data, loading, error }] = useMutation(ADD_USER, {
		refetchQueries: [{ query: GET_USERS }]
	})

	const handleSubmit = useCallback(async (event) => {
		event.preventDefault()
		const form = new FormData(event.target)
		try {
			const res = await addUser({ variables: { 
				name: form.get('name'),
				email: form.get('email'),
				password: form.get('password'),
				role: form.get('role'),
			}})
			navigate(`/users/${res.data.addUser.userId}`)
		} catch (error) { console.error(error) }
	}, [])

	return <>

		<Title>Add user</Title>
		<div className='Heading'>
			<h1>Add user</h1>
			<nav>
				<NavLink to='/users' end>&larr; Users list</NavLink>
			</nav>
		</div>

		<form onSubmit={handleSubmit} className='Form width-sm'>

			<fieldset>
				<legend>Name:</legend>
				<input required
					type='text'
					name='name'
					defaultValue=''
					className='Input'
				/>
			</fieldset>

			<fieldset>
				<legend>Email:</legend>
				<input required
					type='email'
					name='email'
					className='Input'
					defaultValue=''
				/>
			</fieldset>

			<fieldset>
				<legend>Password:</legend>
				<input required
					type='text'
					name='password'
					className='Input'
					defaultValue=''
				/>
			</fieldset>

			<fieldset>
				<legend>Role:</legend>
				<div className='RadioInput'>
					{ USER_ROLES.map(role => 
						<label key={role}>
							<input type='radio'
								name='role'
								value={role}
							/> {role}
						</label>
					)}
				</div>
			</fieldset>

			<div className='Submit'>
				<button type='submit' className='primary'>
					Add user
				</button>
				{ loading && <Spinner /> }
			</div>

		</form>

		<Alert error={error} />

	</>
}


import { useState } from 'react'
import EmojiPicker from 'emoji-picker-react'


export default function IconPicker({ icon, onPick, forceShowPicker = false }) {

	const [showPicker, setShowPicker] = useState(forceShowPicker) 

	return <>

		{ showPicker && <>
			<EmojiPicker
				width='20em'
				height='30em'
				emojiStyle='native'
				lazyLoadEmojis={true}
				skinTonePickerLocation='PREVIEW'
				onEmojiClick={emoji => {
					setShowPicker(false)
					onPick(emoji.emoji)
				}}
			/>
		</>}

		{ (!forceShowPicker && showPicker) &&
			<button 
				onClick={e => { e.preventDefault(); setShowPicker(false)}} 
				type='button'
				className='Button'
			>Close</button>
		}

		{ (icon && !forceShowPicker) &&
			<button 
				onClick={() => setShowPicker(true)} 
				type='button'
				className='Button'
			>{ icon }</button> 
		}

		{ (!icon && !forceShowPicker && !showPicker) && 
			<button 
				onClick={e => { e.preventDefault(); setShowPicker(true) }}
				type='button'
				className='Button'
			>Choose icon</button> 
		}

	</>
}

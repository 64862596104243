
import { useState, useMemo, useEffect } from 'react'
import { produce } from 'immer'

import ColumnStats from '../utils/ColumnStats'
import { Note } from '../utils/Notifications'
import { FIELD_TYPES } from '../../common/fields/fields'


export default function ImportMapExisting({ collection, fieldsMap, setFieldsMap, sourceFields }) {

	// const sourceFields = useMemo(() => {
	// 	if (!stats) return []
	// 	return Object.keys(stats)
	// }, [stats])

	const rows = useMemo(() => {

		const rows = []

		if (!!sourceFields) {
			for (const sourceKey of Object.keys(sourceFields)) {
				const row = rows.find(([sKey]) => sKey === sourceKey)
				if (!row) rows.push([sourceKey, null])
			}
		}

		if (!!fieldsMap) {
			for (let [sourceKey, field] of Object.entries(fieldsMap)) {
				const rowWithSource = rows.find(([sKey]) => sourceKey === sKey)
				if (!!rowWithSource) {
					rowWithSource[1] = field
				}
			}
		}

		if (!!collection?.fields) {
			for (let field of collection.fields) {
				const row = rows.find(([sKey, f]) => field.fieldId === f?.fieldId)
				if (!row) rows.push([null, field])
			}
		}
					// else row[1] = field
		// 		const rowWithField = rows.find(([_, f]) => field?.fieldId === f?.fieldId)
				// if (!rowWithField && !rowWithSource) {
		// 			rows.push([{ key: sourceKey }, field])
		// 		} else if (!!rowWithSource) {
		// 			rowWithSource[1] = !!rowWithField ? rowWithField[1] : field
		// 		}
		// 		// if (!!rowWithField) {
		// 		// 	rowWithField[0] = sourceKey
		// 		// }
		// 		//  else if (!rowWithSource) {
		// 	// 		rows.push([sourceKey, field])
		// 	// 	}
			// }
		// }

		// if (!!collection?.fields) {
		// 	for (let field of collection.fields) {
		// 		const row = rows.find(([sKey, f]) => field.fieldId === f?.fieldId)
		// 		if (!row) rows.push([null, field])
		// 			else row[1] = field
		// 	}
		// }

		return rows.map(([sourceKey, field]) => {
			return [
				(!!sourceKey && !!sourceFields?.[sourceKey]) 
					? { key: sourceKey, stats: sourceFields[sourceKey] } 
					: sourceKey, 
				field
			]
		})
	}, [collection, fieldsMap, sourceFields])

	// console.log(rows)

	function handleUpdate(sourceKey, fieldId) {
		// console.log(sourceKey, fieldId)
		setFieldsMap(produce(fieldsMap, draft => {
			if (!fieldId) {
				delete draft[sourceKey]
			} else {
				draft[sourceKey] = { fieldId }
			}
		}))
	}

	return <>

		{/* { !!profile.stats && <p>Entries count: {profile.entryCount ?? 0}</p> } */}

		{/* { !hasIdField && !!profile.stats && <Note>No ID field specified. An ID field will be created with random values.</Note> } */}

		{/* <div className='IMLinks'> */}

			{/* { !!profile.stats && <div className='ImportMapLink'>
				<div></div>
				<div>
					<input type='checkbox' 
						checked={!!config?.fieldsMap && rows.length === Object.keys(config.fieldsMap).length} 
						disabled={!profile.stats} 
						onChange={toggleSelectAll}
					/>
				</div>
				<div><em>select all</em></div>
			</div>} */}

			{ rows.map(([source, field]) => 
				<div key={`${source?.key || `${source}`}-${field?.fieldId || '_field'}`} className='IMLink'>

					<div className='IMSource'>
						{ !!source?.stats
							?	<div>
									<code className='float-right'>{source.stats?.count}/{source.stats?.duplicates}/{source.stats?.empty}</code>
									<strong>{source.key}</strong>
									<ColumnStats stats={source.stats} />
								</div>
							: 	!!source?.key
							?	<em>{source.key}</em>
							:	<em>-</em>
						}
					</div>

					<div className='IMDestination'>
						
						{/* <input type='checkbox' checked={!!dest} 
							onChange={() => toggleLink(source?.key)} 
							disabled={!source?.stats}
						/> */}

						<select value={field?.fieldId || ''} 
							onChange={e => handleUpdate(source.key, e.target.value)}
							disabled={!source?.key}
						>
							<option value=''></option>
							{ collection?.fields?.map(f => <option value={f.fieldId} key={f.fieldId}>{f.fieldId} [{f.type}]</option>) }
						</select>

					</div>
				</div>
			)}

		{/* </div> */}

	</>
}


import Alert from './Alert'


export default function QueryStatus({
	loading,
	error
}) {

	if (loading) 
		return <div>Loading...</div>

	if (!!error) return <Alert error={error} />
	
	return null
}


// function Error({ error }) {
// 	return <div className='border-4 border-red-700 rounded-lg p-3 text-red-800'>

// 		<h4 className='font-bold text-lg'>
// 			{ error.name || "Error" }</h4>

// 		<p className='font-semibold'>
// 			{ error.message || <em>Unknown</em> }</p>

// 		<details className='text-sm'>
// 			<summary className='py-1'>
// 				details</summary>
// 			<pre>
// 				{JSON.stringify(error, null, 2)}
// 			</pre>
// 		</details>

// 	</div>
// }

import { useLocation, NavLink, useParams, Routes, Route } from 'react-router-dom'

import CollectionProvider from './CollectionProvider'
import EntriesPage from '../entries/EntriesPage'
import useCollection from './useCollection'
import FieldsPage from './FieldsPage'
import SourcePage from './SourcePage'
import Title from '../utils/Title'
import SettingsPage from './SettingsPage'
import EntryPage from '../entries/EntryPage'
import ExportPage from './ExportPage'
import BulkEdit from './BulkEdit'


export default function CollectionPageWrap() {
	const { collectionId } = useParams()
	// const loc = useLocation()
	return <CollectionProvider collectionId={collectionId}>
		{/* {JSON.stringify(loc)} */}
		<CollectionPage />
	</CollectionProvider>
}


function CollectionPage({ }) {

	const { collection } = useCollection()
	const title = `${collection?.icon || '📁'} ${collection?.name || '...'}`
	const { pathname } = useLocation()
	const isEntryPage = pathname.startsWith(`/collection/${collection?.collectionId}/id/`)

	return <>
		<Title>{title}</Title>

		<header>

			<h1>{title}</h1>

			<nav>
				{[	['', "Entries"],
					['/fields', "Fields"],
					['/source', "Source"],
					['/export', "Export"],
					['/settings', "Settings"],
				].map(([url, label]) =>
					<NavLink key={label} to={`/collection/${collection?.collectionId || '_'}${url}`} end className={url === '' &&!!isEntryPage ? 'active' : ''}>
						{label}
					</NavLink>
				)}
			</nav>

		</header>

		{ !!collection &&
			<Routes>
				<Route index element={<EntriesPage />} />
				<Route path='/id/:entryId' element={<EntryPage />} />
				<Route path='/fields' element={<FieldsPage />} />
				<Route path='/source' element={<SourcePage />} />
				<Route path='/export' element={<ExportPage />} />
				<Route path='/settings' element={<SettingsPage />} />
				<Route path='/bulk' element={<BulkEdit />} />
			</Routes>
		}

	</>
}


import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { useImmer } from 'use-immer'

import IconPicker from '../utils/IconPicker'
import { GET_COLLECTIONS } from '../layout/Collections'


export default function CollectionEditor({ collection, handleSave, withCopyFrom = false }) {

	const { data } = useQuery(GET_COLLECTIONS)

	const [draft, setDraft] = useImmer({ 
		collectionId: collection.collectionId ?? '',
		name: collection.name ?? '',
		icon: collection.icon ?? '',
		// typeName: collection.typeName ?? '',
	})

	const [copyFrom, setCopyFrom] = useState(null)
	const [withData, setWithData] = useState(false)

	function handleSubmit(event) {
		event.preventDefault()
		handleSave({ ...draft, copyFrom, withData })
	}

	// const handleSubmit = useCallback(async (event) => {
	// 	event.preventDefault()
	// 	try {
	// 		setStatus("Adding collection...")
	// 		await addCollection({ variables: { 
	// 			collectionId, name, icon, typeName, 
	// 			copyFrom: copyFrom === '' ? null : copyFrom
	// 		}})
	// 		navigate(`/collection/${collectionId}/fields`)
	// 	} catch (error) { setStatus(error.toString())}
	// }, [collectionId, name, icon, typeName, copyFrom])

	return <form onSubmit={handleSubmit} className='Form width-sm'>

		<fieldset>
			<legend>Name:</legend>
			<input required type='text' value={draft.name ?? ''}
				onChange={e => setDraft(d => { d.name = e.target.value })}
			/> e.g. "Best Movies"
		</fieldset>

		<fieldset>
			<legend>ID:</legend>
			<input required type='text' value={draft.collectionId ?? ''}
				onChange={e => setDraft(d => { d.collectionId = e.target.value })}
			/> e.g. "BestMovies"
		</fieldset>

		{/* <fieldset>
			<legend>GraphQL type:</legend>
			<input required type='text' value={draft.typeName ?? ''}
				onChange={e => setDraft(d => { d.typeName = e.target.value })}
			/> e.g. "BestMovie"
		</fieldset> */}

		<fieldset>
			<legend>Icon:</legend>
			<IconPicker icon={draft.icon ?? ''} onPick={icon => setDraft(d => { d.icon = icon })} />
		</fieldset>

		{ !!withCopyFrom && <>
			<fieldset>
				<legend>Copy from:</legend>
				<select value={copyFrom ?? ''} onChange={e => setCopyFrom(e.target.value)}>
					<option value=''></option>
					{ data?.collections.map(({ collectionId, icon, name }) => 
						<option key={collectionId} value={collectionId}>
							{icon} { name }
						</option>
					)}
				</select>
				<label>
					<input type='checkbox' checked={withData} onChange={e => setWithData(e.target.checked)} /> with entries
				</label>
			</fieldset>
		</>}

		<button type='submit' className='primary'>
			Save
		</button>

	</form>
}


import { useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'

import { GET_COLLECTIONS } from '../layout/Collections'
import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'
import useCollection from './useCollection'

const DELETE_COLLECTION = gql`
	mutation DeleteCollection($collectionId:ID!) {
		deleteCollection(collectionId:$collectionId)
	}
`

export default function DeleteCollection() {

	const { collection: { collectionId } } = useCollection()
	const navigate = useNavigate()
	const [deleteCollection, { loading, error }] = useMutation(DELETE_COLLECTION, {
		refetchQueries: [{ query: GET_COLLECTIONS }]
	})

	async function handleDelete() {
		if (!confirm(`Are you sure? All data will be lost.`)) return false
		await deleteCollection({ variables: { collectionId } })
		navigate('/')
	}

	return <>

		<button onClick={() => handleDelete()} className='delete small' disabled={loading}>
			Delete collection
		</button>

		{ loading && <Spinner /> }
		{ error && <Alert error={error} /> }

	</>
}



import Alert from '../utils/Alert'
import FieldLabel from './FieldLabel'

import ID from './IdInput.jsx'
import URI from './UriInput.jsx'
import TEXT from './TextInput.jsx'
import HTML from './HtmlInput.jsx'
import FILE from './FileInput.jsx'
import STRUCT from './StructInput.jsx'
import JSONINPUT from './JsonInput.jsx'
import NUMERIC from './NumericInput.jsx'
import BOOLEAN from './BooleanInput.jsx'
import MODIFIED from './ModifiedInput.jsx'
import DATETIME from './DatetimeInput.jsx'
import REFERENCE from './ReferenceInput.jsx'

const FIELD_TYPES_INPUTS = {
	ID,
	URI,
	TEXT,
	JSON: JSONINPUT,
	HTML,
	FILE,
	STRUCT,
	NUMERIC,
	BOOLEAN,
	MODIFIED,
	DATETIME,
	REFERENCE,
}


export default function Field({ 
	field,
	value, 
	update,
}) {

	const InputComponent = FIELD_TYPES_INPUTS[field.type]

	return <div className='Field'>

		<FieldLabel field={field} empty={!value} />

		{ !InputComponent
			? <Alert error={`No input component for field type: ${field.type}`} />
			: <InputComponent field={field} value={value} update={update} /> }

	</div>
}
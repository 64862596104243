
import { useRecoilValue } from 'recoil'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import Collections from './Collections'
import BuildInfo from './BuildInfo'
import Avatar from '../users/Avatar'
import { userAtom } from './atoms'

const LINKS = [
	['/collections',"Collections",	['ADMIN', 'EDITOR']],
	['/files',		"Files",		['ADMIN', 'EDITOR']],
	['/urls',		"URLs",			['ADMIN', 'EDITOR', 'GUEST']],
	['/import',		"Import",		['ADMIN']],
	['/logs',		"Logs",			['ADMIN']],
	['/users',		"Users",		['ADMIN']],
]


export default function Navbar() {

	const user = useRecoilValue(userAtom)
	const links = useMemo(() => LINKS.filter((link) => link[2].includes(user.role)), [user])

	return <nav id='Navbar'>

		<NavLink to='/' id='SiteLogo'>
			<img src={`${process.env.DAISY_CMS_URL}/favicon.png`} alt='' width='1.5rem' />
			<span id='SiteName'>
				{ process.env.DAISY_TITLE || "Daisy CMS" }
			</span>
		</NavLink>

		<hr />

		<Collections />

		<hr />

		<NavLink to='/build' id='BuildLink'>
			Build&nbsp; <BuildInfo />
		</NavLink>

		{ links.map(([href, label]) => 
			<NavLink key={href} to={href}>
				{label}
			</NavLink>
		)}

		<hr />

		{ !!user && <NavLink to='/account' className='UserLink'>
			<Avatar emailHash={user.emailHash} />
			<span>{user.name}</span>
		</NavLink>}

	</nav>
}



import Field from './Field.js'
import TextField from './TextField.js'


export default class HtmlField extends Field {

	type = 'HTML'
	_columnType = 'TEXT'
	_gqlType = 'HTML'

	constructor(initVals) {
		super(initVals)
	}

	_parseValue(value) {
		return TextField.parseValue(value)
	}
	
}
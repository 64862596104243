
import { useMemo } from 'react'

import FileItem from './FileItem'
import FolderItem from './FolderItem'


export default function FilesFolder({ folder }) {

	const parentItem = useMemo(() => {
		if (folder.path === '/') return null
		return {
			path: folder.path.split('/').slice(0, -1).join('/'),
			name: '..'
		}
	}, [folder])

	return <>

		<div className='Folders'>

			{ !!parentItem && 
				<FolderItem item={parentItem} /> }

			{ folder.folders.length > 0 &&
				folder.folders.map(f => 
					<FolderItem key={f.path} item={f} />
				)}

		</div>

		{ folder.files.length > 0 && 
			<div className='Files'>
				{ folder.files.map(f => <FileItem key={f.path} item={f} /> ) }
			</div>
		}

	</>
}

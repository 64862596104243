
import { useEffect } from 'react'


export default function Title({ children }) {

	useEffect(() => {
		document.title = `${children} | ${process.env.DAISY_TITLE}`
	}, [children])

	return null
}

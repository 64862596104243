
import Field from './Field.js'
import IdField from './IdField.js'


export default class UriField extends Field {

	type = 'URI'
	_columnType = 'TEXT'
	_gqlType = 'URI'

	fieldId = 'uri'
	
	constructor(initVals) {
		super(initVals)
	}

	_parseValue(value) {
		return IdField.parseValue(value, this.collectionId ?? this.fieldId)
	}

	_exportValue(value) {
		const url = `${this.config?.prefix || ''}${value || ''}`
		return {
			uri:	value,
			prefix:	this.config?.prefix || null,
			slug:	url.split('/').pop(),
			url:	`/${url}/`,
		}
	}
}

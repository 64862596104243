
import { useState, useEffect } from 'react'


export default function DatetimeInput({ field, value, update }) {

	const [inputValue, setInputValue] = useState('')

	function handleChange(event) {
		const date = new Date(event.target.value)
		update(isNaN(date) ? null : date.toISOString())
	}

	useEffect(() => {
		if (!value) {
			setInputValue('')
			return
		}
		try {
			const date = new Date(value)
			date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
			setInputValue(date.toISOString().slice(0, 16))
		} catch (e) { setInputValue('') }
	}, [value])

	return <div className='FieldInput'>

		<input type="datetime-local"
			value={inputValue}
			onChange={handleChange}
		/>
		{' '}
		<button onClick={() => update(null)}>x</button>

	</div>
}


import Field from './Field.js'


export default class NumericField extends Field {

	type = 'NUMERIC'
	_columnType = 'NUMERIC'
	_gqlType = 'Float'
	
	constructor(initVals) {
		super(initVals)
	}

	_parseValue(value) {
		let parsedValue = Number(value)
		if (isNaN(parsedValue)) parsedValue = null
		return parsedValue
	}
}
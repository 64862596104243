
import Field from './Field.js'
import { parseJsonValue, serializeJsonValue } from '../utils/json.js'


export default class JsonField extends Field {

	type = 'JSON'
	_columnType = 'JSON'
	_gqlType = 'JSON'

	options = {}

	constructor(initVals) {
		super(initVals)
	}

	static parseValue(value) {
		return parseJsonValue(value)		
	}

	static serializeValue(value) {
		return serializeJsonValue(value)
	}

	_parseValue(value) {
		return JsonField.parseValue(value)
	}

	_serializeValue(value) {
		return JsonField.serializeValue(value)
	}
}
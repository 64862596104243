
import { Link } from 'react-router-dom'
import { useContext, useCallback } from 'react'

import { FilesContext  } from './Files'


export default function FilesLink({ path, className, children }) {

	const { setPath, isEmbed } = useContext(FilesContext)

	const handleClick = useCallback(event => {
		if (!isEmbed) 
			return true
		event.preventDefault()
		setPath(path)
	}, [path])

	return <Link 
		to={`/files${path}`}
		onClick={handleClick}
		className={className}
	>
		{children}
	</Link>
}

import { useCallback } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'

import Alert from '../utils/Alert'

import { ENTRIES_SCOPES } from './roles'
import { GET_USER } from './UserPage'
import { GET_COLLECTIONS } from '../layout/Collections'

const EDIT_PERMISSIONS = gql`
	mutation EditPermissions($userId: ID!, $target: String!, $scope: String!, $action: String!) {
		editPermissions(userId: $userId, target: $target, scope: $scope, action: $action)
	}`


export default function Permissions({ user }) {

	const [editPermissions, { loading, error }] = useMutation(EDIT_PERMISSIONS, {
		variables: { userId: user.userId },
		refetchQueries: [{ query: GET_USER, variables: { userId: user.userId } }]
	})
	const { data: collectionsData } = useQuery(GET_COLLECTIONS)
	const collections = collectionsData?.collections || []

	const handleChange = useCallback((target, scope) => (event) => {
		editPermissions({ variables: {
			target,
			scope,
			action: event.target.checked ? 'allow' : 'deny'
		}})
	}, [])

	return <>
	
		<h2>Permissions</h2>

		<h3>Entries</h3>

		<table>
			<thead>
				<tr>
					<th>All</th>
					{['ADD', 'EDIT', 'REMOVE'].map(scope =>
						<th key={scope}>
							<label>
								<input type='checkbox' 
									onChange={handleChange('COLLECTIONS', scope)}
									checked={user.permissions.includes(`COLLECTIONS:${scope}`)}
								/> {scope}
							</label>
						</th>
					)}
				</tr>
			</thead>
			<tbody>
				{ collections.map(({collectionId, name, icon}) => 
					<tr key={collectionId}>
						<td>{icon} {name}</td>
						{ ENTRIES_SCOPES.map(scope =>
							<td key={scope}>
								<label>
									<input type='checkbox'
										onChange={handleChange(`COLLECTION/${collectionId}`, scope)}
										checked={user.permissions.includes(`COLLECTION/${collectionId}:${scope}`)}
										disabled={user.permissions.includes(`COLLECTIONS:${scope}`)}
									/> {scope}
								</label>
							</td>
						)}
					</tr>
				)}
			</tbody>
		</table>

		<Alert error={error} />

		{/* <pre>{JSON.stringify(user, null, 2)}</pre> */}

	</>
}


import Field from './Field.js'
import TextField from './TextField.js'


export default class ReferenceField extends Field {

	type = 'REFERENCE'
	_columnType = 'TEXT'
	_gqlType = 'ID'
	
	constructor(initVals) {
		super(initVals)
	}

	get gqlType() {
		return this.config?.collectionId || this._gqlType
	}

	_parseValue(value) {
		return TextField.parseValue(value)
	}
}

import { gql, useMutation } from '@apollo/client'
import { useImmer } from 'use-immer'

import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'
import { GET_FIELDS } from './FieldsPage'
import { GET_COLLECTION } from '../collections/CollectionProvider'
import shallowMerge from '../utils/shallowMerge'

import Field from '../../common/fields/Field'
import { FIELD_TYPES } from '../../common/fields/fields'

import ID from './IdOptions.jsx'
import URI from './UriOptions.jsx'
import TEXT from './TextOptions.jsx'
import HTML from './HtmlOptions.jsx'
import FILE from './FileOptions.jsx'
import STRUCT from './StructOptions.jsx'
import JSON_OPTIONS from './JsonOptions.jsx'
import NUMERIC from './NumericOptions.jsx'
import BOOLEAN from './BooleanOptions.jsx'
import MODIFIED from './ModifiedOptions.jsx'
import DATETIME from './DatetimeOptions.jsx'
import REFERENCE from './ReferenceOptions.jsx'

const FIELD_TYPES_OPTIONS = {
	ID,
	URI,
	TEXT,
	JSON: JSON_OPTIONS,
	HTML,
	FILE,
	STRUCT,
	NUMERIC,
	BOOLEAN,
	MODIFIED,
	DATETIME,
	REFERENCE,
}


const DELETE_FIELD = gql`
	mutation DeleteField($collectionId:ID, $fieldId:ID!) {	
		deleteField(collectionId:$collectionId, fieldId:$fieldId)
	}`

const emptyField = new Field()
const DEFAULT_FIELD = emptyField.json

export default function FieldEditor({ 
	field,
	handleSave = () => {},
	onDelete = () => {},
	onCancel = () => {},
}) {

	const [draft, setDraft] = useImmer(() => shallowMerge(DEFAULT_FIELD, field))
	// const [saveField,	{ loading: isSaving, error: saveError }] = useMutation(SAVE_FIELD)
	const [deleteField,	{ loading: isDeleting, error: deleteError }] = useMutation(DELETE_FIELD)

	// async function handleSave() {
	// 	const variables = { 
	// 		collectionId: field.collectionId, 
	// 		fieldId: field.fieldId || draft.fieldId
	// 	}
	// 	const options = {
	// 		variables: { ...variables, values: draft },
	// 		refetchQueries: [
	// 			{ query: GET_COLLECTION, variables },
	// 			{ query: GET_FIELDS, variables },
	// 		]
	// 	}
	// 	await saveField(options)
	// 	onSave()
	// }

	async function handleDelete() {
		const variables = { 
			collectionId: field.collectionId, 
			fieldId: field.fieldId 
		}
		await deleteField({
			variables,
			refetchQueries: [
				{ query: GET_COLLECTION, variables },
				{ query: GET_FIELDS, variables },
			]
		})
		onDelete()
	}

	function updateConfig(producer) {
		return setDraft(d => { producer(d.config) })
	}

	function handleCancel() {
		onCancel()
	}

	const OptionsComponent = FIELD_TYPES_OPTIONS[draft.type]

	return <div className='Form FieldEditor'>

		<fieldset>
			<legend>Field type:</legend>
			<select value={draft.type || ''} onChange={e => setDraft(d => { d.type = e.target.value })} className='w-fit'>
				<option value=''></option>
				{ FIELD_TYPES.map(type => 
					<option key={type} value={type}>{type}</option> )}
			</select>
		</fieldset>

		{ !!draft.type && <>

			<fieldset>
				<legend>Name:</legend>
				<input type='text' value={draft.config.name || ''} onChange={e => setDraft(d => { d.config.name = e.target.value })} />
			</fieldset>

			<fieldset>
				<legend>ID:</legend>
				<input type='text' value={draft.fieldId || ''} onChange={e => setDraft(d => { d.fieldId = e.target.value })} />
			</fieldset>

			{/* <fieldset>
				<legend>Collection:</legend>
				<input type='text' disabled value={draft.collectionId || ''} />
			</fieldset>		 */}

			<fieldset>
				<legend>Description:</legend>
				<input type='text' value={draft.config.description || ''} 
					onChange={e => setDraft(d => { d.config.description = e.target.value })} 
				/>
			</fieldset>

			<fieldset>
				<label>
					<input type='checkbox' checked={!!draft.config?.multi} 
						onChange={e => setDraft(d => { d.config.multi = e.target.checked })}
					/> allow multiple values
				</label>
			</fieldset>

			<fieldset>
				<label>
					<input type='checkbox'
						checked={!!draft.required}
						onChange={e => setDraft(d => { d.config.required = e.target.checked })}
					/> required
				</label>
			</fieldset>

			{ !!OptionsComponent && 
				<OptionsComponent config={draft.config} update={updateConfig} /> }

		</>}

		<div className='Submit'>
			<button onClick={() => handleDelete()} disabled={isDeleting} className='delete'>Delete</button>
			<button onClick={() => handleCancel()}>Cancel</button>
			<button onClick={() => handleSave(draft)} disabled={!draft.type || !draft.fieldId} className='primary'>Save</button>
			{ isDeleting && <Spinner /> }
		</div>

		{ deleteError && <Alert error={deleteError} /> }

		{/* <pre>{JSON.stringify(draft, null, 2)}</pre> */}

	</div>
}


import { Component } from 'react'

import Alert from './Alert'


export default class ErrorBoundary extends Component {

	constructor(props) {
		super(props)
		this.state = { error: null }
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { error }
	}

	componentDidCatch(error, errorInfo) {
		console.error(error)
		console.log(errorInfo)
	}

	render() {
		return !!this.state.error
			? <Alert error={this.state.error} />
			: this.props.children
	}
}


export default function formatFileSize(bytes) {
	if (bytes < 1024) {
		return bytes.toFixed(1) + ' B'
	} else if (bytes < 1024 * 1024) {
		return (bytes < 1024 * 10) ? (bytes / 1024).toFixed(1)+' kB' : Math.round(bytes / 1024)+' kB'
	} else {
		return (bytes / (1024 * 1024)).toFixed(1)+' MB'
	}
}


import { gql, useQuery } from '@apollo/client'
import { NavLink } from 'react-router-dom'

import Alert from '../utils/Alert'


export const GET_COLLECTIONS = gql`
	query Collections {
		collections {
			collectionId
			name
			icon
		}
	}
`


export default function CollectionsNav() {

	const { loading, error, data } = useQuery(GET_COLLECTIONS)

	if (error) return <Alert error={error} />
	if (!data || loading) return null

	return <>

		{ data.collections.map(({collectionId, name, icon}) => 
			<NavLink key={collectionId}
				to={`/collection/${collectionId}`}
				className='CollectionLink'
			>
				<span>
					{icon}
				</span>
				<span>
					{name}
				</span>
			</NavLink>
		)}

	</>
}

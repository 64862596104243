
export default function BooleanOptions({ config, update }) {

	return <div className='FieldOptions FieldOptionsCustom'>

		<fieldset>
			<legend>Input type:</legend>
			<div className='RadioInput'>
				<label>
					<input type='radio'
						checked={config.inputType === 'checkbox' || !config.inputType}
						onChange={e => update(draft => {
							draft.inputType = e.target.checked ? 'checkbox' : 'radio'
						})}
					/> checkbox
				</label>
				<label>
					<input type='radio'
						checked={config.inputType === 'radio'}
						onChange={e => update(draft => {
							draft.inputType = e.target.checked ? 'radio' : 'checkbox'
						})}
					/> radio buttons
				</label>
			</div>
		</fieldset>

		<fieldset>
			<legend>{ config.inputType === 'checkbox' ? "Label:" : <><em>true</em> label:</> }</legend>
			<input type='text'
				value={config.trueLabel || ''}
				onChange={e => update(draft => { draft.trueLabel = e.target.value })}
				className='TextInput'
			/>
		</fieldset>

		{ config.inputType === 'radio' && 
			<fieldset>
				<legend><em>false</em> label:</legend>
				<input type='text'
					value={config.falseLabel || ''}
					onChange={e => update(draft => { draft.falseLabel = e.target.value })}
					className='TextInput'
				/>
			</fieldset> }

	</div>
}



import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import QueryStatus from '../utils/QueryStatus'

const CHANGE_PASSWORD = gql`
	mutation ChangePassword($oldpass: String!, $newpass: String!) {
		changePassword(oldpass: $oldpass, newpass: $newpass)
	}
`

export default function ChangePassword({}) {

	const [changePassword, { loading, error }] = useMutation(CHANGE_PASSWORD)

	const handleSubmit = useCallback(async (event) => {
		event.preventDefault()
		const form = new FormData(event.target)
		if (form.get('newpass') !== form.get('confirmpass'))
			return alert("New passwords don't match.")
		try {
			await changePassword({ variables: {
				oldpass: form.get('oldpass'),
				newpass: form.get('newpass'),
			}})
			alert("Password changed. Sign out and in again.")
		} catch (error) { console.error(error) }
	}, [])

	return <form onSubmit={handleSubmit} className='Form width-sm'>

		<h2>
			Change password
		</h2>
		
		<fieldset>
			<legend>Current password</legend>
			<input type='password' required
				name='oldpass'
				defaultValue=''
			/>
		</fieldset>

		<fieldset>
			<legend>New password</legend>
			<input type='password' required
				name='newpass'
				defaultValue=''
			/>
		</fieldset>

		<fieldset>
			<legend>
				Confirm
			</legend>
			<input type='password' required
				name='confirmpass'
				defaultValue=''
			/>
		</fieldset>
		
		<div className='Submit'>
			<button type='submit'>
				Change password
			</button>
		</div>
		
		<QueryStatus loading={loading} error={error} />

	</form>
}
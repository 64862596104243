
import { useState, useMemo, useEffect } from 'react'
import { produce } from 'immer'

import ColumnStats from '../utils/ColumnStats'
import { Note } from '../utils/Notifications'
import { FIELD_TYPES } from '../../common/fields/fields'


function suggestFieldType(source) {
	let type = Object.entries(source.types).sort((a, b) => b[1] - a[1])[0][0]
	switch (type) {
		case 'number': return 'NUMERIC'
		case 'boolean': return 'BOOLEAN'
		case 'string': return 'TEXT'
		default:
		case 'object': return 'JSON'
	}
}


export default function ImportMapNew({ fieldsMap, setFieldsMap, sourceFields, collection }) {

	const rows = useMemo(() => {

		const rows = []

		if (!!sourceFields) {
			for (const sourceKey of Object.keys(sourceFields)) {
				const row = rows.find(([sKey]) => sKey === sourceKey)
				if (!row) rows.push([sourceKey, null])
			}
		}

		if (!!fieldsMap) {
			for (let [sourceKey, field] of Object.entries(fieldsMap)) {
				const rowWithSource = rows.find(([sKey]) => sourceKey === sKey)
				if (!!rowWithSource) {
					rowWithSource[1] = field
				}
			}
		}

		return rows.map(([sourceKey, field]) => {
			return [
				(!!sourceKey && !!sourceFields?.[sourceKey]) 
					? { key: sourceKey, stats: sourceFields[sourceKey] } 
					: sourceKey, 
				field
			]
		})
	}, [collection, fieldsMap, sourceFields])

	// const rows = useMemo(() => {
	// 	const rows = []
	// 	if (!!profile?.stats) {
	// 		for (let sourceKey of Object.keys(profile.stats)) {
	// 			rows.push([sourceKey, null])
	// 		}
	// 	}
	// 	if (!!config.fieldsMap) {
	// 		for (let [sourceKey, field] of Object.entries(config.fieldsMap)) {
	// 			const row = rows.find(([sKey]) => sKey === sourceKey)
	// 			if (!row) rows.push([sourceKey, field])
	// 				else row[1] = field
	// 		}
	// 	}
	// 	if (!!collection?.fields) {
	// 		for (let field of collection.fields) {
	// 			const row = rows.find(([sKey, f]) => field.fieldId === f?.fieldId && field.type === f?.type)
	// 			if (!row) rows.push([null, field])
	// 				else row[1] = field
	// 		}
	// 	}
	// 	return rows.map(([sKey, dest]) => {
	// 		return [
	// 			!!sKey ? { key: sKey, stats: profile?.stats?.[sKey] } : null, 
	// 			dest
	// 		]
	// 	})
	// }, [config.fieldsMap, profile.stats, collection])


	function toggleSource(sourceKey) {
		setFieldsMap(produce(fieldsMap, draft => {
			if (draft[sourceKey]) delete draft[sourceKey]
				else draft[sourceKey] = { fieldId: sourceKey, type: suggestFieldType(sourceFields[sourceKey]) }
		}))
	}

	function updateField(sourceKey, values) {
		setFieldsMap(produce(fieldsMap, draft => {
			draft[sourceKey] = { ...draft[sourceKey], ...values }
		}))
	}

	// function toggleSelectAll(event) {
	// 	if (!profile.stats) return
	// 	if (event.target.checked) {
	// 		setConfig(d => {
	// 			for (let sourceKey of Object.keys(profile.stats)) {
	// 				if (!d.fieldsMap[sourceKey]) d.fieldsMap[sourceKey] = { fieldId: sourceKey, type: 'TEXT' }
	// 			}
	// 		})
	// 	} else setConfig(d => { d.fieldsMap = {} })
	// }

	return <>

		{/* { !!profile.stats && <p>Entries count: {profile.entryCount ?? 0}</p> } */}

		{/* { !hasIdField && !!profile.stats && <Note>No ID field specified. An ID field will be created with random values.</Note> } */}

		{/* <div className='IMLinks'> */}

			{/* { !!profile.stats && <div className='ImportMapLink'>
				<div></div>
				<div>
					<input type='checkbox' 
						checked={!!config?.fieldsMap && rows.length === Object.keys(config.fieldsMap).length} 
						disabled={!profile.stats} 
						onChange={toggleSelectAll}
					/>
				</div>
				<div><em>select all</em></div>
			</div>} */}

			{ rows.map(([source, field]) => 
				<div key={`${source?.key || `${source}`}-${field?.fieldId || '_field'}`} className='IMLink'>

					<div className='IMSource'>
						{ !!source?.stats
							?	<div>
									<code className='float-right'>
										{!!source.stats?.types && 
											Object.entries(source.stats.types).map(([type, count]) => `${type}(${count})`).join(',') }
										{' '}
										{source.stats.count - source.stats.nulls}/
										{source.stats.count - source.stats.empty}/
										{(source.stats.count - source.stats.duplicates)}
									</code>
									<strong>{source.key}</strong>
									<ColumnStats stats={source.stats} />
								</div>
							: 	!!source?.key
							?	<em>{source.key}</em>
							:	<em>-</em>
						}
					</div>

					<div className='IMDestination'>
						
						{ !collection && <>

							<input type='checkbox' checked={!!field} disabled={!source?.stats}
								onChange={() => toggleSource(source.key)} />

							{ !!field && <>
								<input type='text' defaultValue={field?.fieldId} 
									onBlur={e => updateField(source.key, { fieldId: e.target.value })} />
								<select value={field?.type} onChange={e => updateField(source.key, { type: e.target.value })}>
									{ FIELD_TYPES.map(type => <option key={type} value={type}>{type}</option>) }
								</select>
							</>}

						</>}
						
						{ !!collection && <>
							<select value={field?.fieldId || ''} 
								onChange={e => handleUpdate(source.key, e.target.value)}
								disabled={!source?.key}
							>
								<option value=''></option>
								{ collection?.fields?.map(f => <option value={f.fieldId} key={f.fieldId}>{f.fieldId} [{f.type}]</option>) }
							</select>
						</>}

					</div>
				</div>
			)}
{/* 
			{ rows.map(([source, dest]) => 
				<div key={`${source?.key}-${dest?.fieldId}`} className='IMLink'>

					<div className='IMSource'>
						{ !!source?.stats
							?	<div>
									<code className='float-right'>{source.stats?.count}/{source.stats?.duplicates}/{source.stats?.empty}</code>
									<strong>{source.key}</strong>
									<ColumnStats stats={source.stats} />
								</div>
							: 	!!source?.key
							?	<em>{source.key}</em>
							:	<em>-</em>
						}
					</div>

					<div className='IMDestination'>
						
						<input type='checkbox' checked={!!dest} 
							onChange={() => toggleLink(source?.key)} 
							disabled={!source?.stats}
						/>
					
						{ (!!dest && !!collection) && <>
							<select value={dest.fieldId} onChange={e => updateDestination(source, { fieldId: e.target.value })}>
								<option value=''></option>
								{ collection.fields.map(f => <option key={f.fieldId} value={f.fieldId}>
									{f.fieldId} [{f.type}]
								</option>) }
							</select>
						</>}

						{ (!!dest && !collection) && <>
							<input type='text' defaultValue={dest?.fieldId} onBlur={e => updateDestination(source, { fieldId: e.target.value })} />
							<select value={dest?.type} onChange={e => updateDestination(source, { type: e.target.value })}>
								{ FIELD_TYPES.map(type => <option key={type} value={type}>{type}</option>) }
							</select>
						</>}

					</div>
				</div>
			)} */}

		{/* </div> */}

	</>
}

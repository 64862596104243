
import { gql, useMutation } from '@apollo/client'
import { arrayMoveImmutable } from 'array-move'

import useCollection from './useCollection'
import UnclaimedColumns from './UnclaimedColumns'
import { GET_COLLECTION } from './CollectionProvider'
import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'
import FieldsEditor from '../fields/FieldsEditor'

const REORDER_FIELDS = gql`
	mutation ReorderFields($collectionId: ID!, $positions: [ID]!) {
		reorderFields(collectionId: $collectionId, positions: $positions)
	}
`

const ADD_FIELD = gql`
	mutation AddField($collectionId:ID, $values:JSON!) {	
		addField(collectionId:$collectionId, values:$values)
	}
`

export default function CollectionFieldsPage() {

	const { collection } = useCollection()
	
	const [reorderFields, { loading: loadingReorder, error: reorderError }] = useMutation(REORDER_FIELDS, {
		refetchQueries: [{ query: GET_COLLECTION, variables: { collectionId: collection.collectionId } }],
	})
	const [addField, { loading: loadingAdd, error: addError }] = useMutation(ADD_FIELD, {
		refetchQueries: [{ query: GET_COLLECTION, variables: { collectionId: collection.collectionId } }],
	})

	function handleSortEnd(oldIndex, newIndex) {
		const newOrder = arrayMoveImmutable(collection.fields, oldIndex, newIndex)
		const positions = newOrder.map(({fieldId}) => fieldId)
		reorderFields({ variables: { collectionId: collection.collectionId, positions } })
	}

	async function handleAddField(values) {
		await addField({ variables: { collectionId: collection.collectionId, values } })
		return true
	}

	return <>

		{ !!reorderError && <Alert error={reorderError} /> }

		<FieldsEditor fields={collection.fields} 
			handleAddField={handleAddField} 
			handleSortEnd={handleSortEnd}
		/>

		{ loadingReorder && <Spinner /> }
		{ loadingAdd && <Spinner /> }

		{ !!addError && <Alert error={addError} /> }

		<UnclaimedColumns />
		
	</>
}

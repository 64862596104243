
import Avatar from './Avatar'


export default function UserSnippet({ user }) {
	return <div className='UserSnippet'>
		<Avatar emailHash={user.emailHash} size={256} />
		<div>
			<h3>{user.name}</h3>
			<p>{user.email}</p>
			<p><em>{user.role}</em></p>
		</div>
	</div>
}

export default function Avatar({ 
	emailHash, 
	size = 64,
	// width = 64,
	defaultImage = 'identicon',
	className = 'avatar',
}) {
	return <img
		className={className}
		src={`https://www.gravatar.com/avatar/${emailHash}?s=${size}&d=${defaultImage}`}
	/>
}
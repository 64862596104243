

export default function ColumnStats({ stats }) {

	return <div className='ColumnStats'>

		count:{stats.count}<br />
		duplicates:{stats.duplicates}<br />
		empty:{stats.empty}<br />
		nulls:{stats.nulls}<br />

		min length: {stats.lengths.min}<br />
		max length: {stats.lengths.max}<br />
		avg length: {stats.lengths.avg}<br />

		Types:
		<ul>
			{Object.entries(stats.types).map(([type, count]) => <li key={type}>{type}:{count}</li>)}
		</ul>

		Sample:
		<ul>
			{stats.distinct.map((value, idx) => <li key={idx}>{value}</li>)}
		</ul>

		{/* <pre>{JSON.stringify(stats, null, 2)}</pre> */}
		
	</div>
}
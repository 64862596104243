
import FieldsEditor from './FieldsEditor'


export default function StructOptions({ config, update }) {

	// const handleUpdateFields = useCallback((action, fieldVals) => {
	// 	update(draft => { 
	// 		if (!Array.isArray(draft.fields)) draft.fields = []
	// 		switch (action) {
	// 			case 'ADD':
	// 				draft.fields.push(fieldVals)
	// 				break
	// 		}
	// 	})
	// }, [])

	function handleAddField(fieldVals) {
		update(draft => { 
			if (!Array.isArray(draft.fields)) draft.fields = []
			draft.fields.push(fieldVals)
		})
		return true
	}

	return <fieldset className='STRUCToptions'>

		<legend>Fields</legend>

		<div className='STRUCTfieldsEditor'>
			<FieldsEditor 
				fields={config.fields || []}
				handleAddField={handleAddField}
				// updateFields={handleUpdateFields}
			/>
		</div>

	</fieldset>
}
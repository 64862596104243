
import { useState, useMemo, useEffect } from 'react'
import useCollection from '../collections/useCollection'
import { gql, useQuery } from '@apollo/client'

import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'
import { useImmer } from 'use-immer'
import BulkEntry from '../collections/BulkEntry'
import jason from '../utils/jason'

export const GET_ENTRY_SNIPPETS = gql`
	query GetBulkRunEntriesIds($collectionId:ID!) {
		entrySnippets(collectionId:$collectionId) {
			entryId
			collectionId
			values
		}
	}`


export default function BulkTaskRun({ task }) {

	const { collection } = useCollection()
	const [isRunning, setIsRunning] = useState(false)
	const [concurrent, setConcurrent] = useState(1)

	const { data: entriesData, loading: loadingEntries, error: entriesError} = useQuery(GET_ENTRY_SNIPPETS, { 
		variables: { collectionId: collection.collectionId },
	})

	const [entries, setEntries] = useImmer({})

	const stats = useMemo(() => Object.entries(entries).reduce((acc, [entryId, { status }]) => {
			acc[status]++
			acc.total++
			return acc
		}, { total: 0, LOADING:0, DONE:0, SAVING:0, SAVED:0 })
	, [entries])

	function handleSaveAll() {
		setEntries(draft => {
			for (const [entryId, entry] of Object.entries(draft)) {
				if (entry.status === 'DONE') {
					draft[entryId].status = 'SAVING'
				}
			}
			// draft.forEach(entry => {
			// 	if (entry.status === 'DONE' && !!entry.draft)
			// 		entry.status = 'SAVING'
			// })
			// for (const entry of draft) {
			// 	if (entry.status === 'DONE' && !!entry.draft)
			// 		draft.set(entryId, { ...entry, status: 'SAVING' })
			// }
		})
	}

	// function setEntry(entryId, vals) {
	// 	setEntries(draft => {
	// 		const entryIdx = draft.findIndex(e => e.entry.entryId === entryId)
	// 		if (entryIdx > -1) draft[entryIdx] = { ...draft[entryIdx], ...vals }
	// 	})
	// }

	useEffect(() => {
		if (!entriesData?.entrySnippets?.length) return;
		setEntries(Object.fromEntries(entriesData.entrySnippets.map(entry => [entry.entryId, { status: null, entry }])))
	}, [entriesData])

	useEffect(() => {
		if (!isRunning || stats.total === 0) return;
		let loading = 0
		// const updates = []
		for (const [entryId, entry] of Object.entries(entries)) {
			if (entry.status === 'DONE') {
				continue
			} else if (entry.status === 'LOADING') {
				loading++
				continue
			} else if (!entry.status && loading < concurrent) {
				// updates.push({ entryId, status: 'LOADING' })
				console.log('start', entryId)
				setEntries(draft => {
					draft[entryId].status = 'LOADING'
				})
				// setEntries(draft => { 
				// 	const entryIdx = draft.findIndex(e => e.entry.entryId === entry.entry.entryId)
				// 	if (entryIdx > -1) draft[entryIdx].status = 'LOADING'
				// })
				break
			}
		}
		// if (updates.length) {
		// 	setEntries(draft => {
		// 		for (const { entryId, status } of updates) 
		// 			draft[entryId].status = status
		// 	})
		// }
	}, [isRunning, entries])

	return <>

		{ entriesError && <Alert error={entriesError} /> }

		<div className='Menu'>

			<button onClick={() => setIsRunning(pv => !pv)} className='primary'>
				{ isRunning ? 'Stop' : 'Run all' }
			</button>

			<div>
				Concurrent:<select onChange={e => setConcurrent(e.target.value)} value={concurrent}>
					{ [1,2,3,4,5].map(n => <option key={n} value={n}>{n}</option>) }
				</select>
			</div>

			<small>{stats.total} entries &middot; {stats.LOADING} loading &middot; {stats.DONE} done &middot; {stats.SAVING} saving &middot; {stats.SAVED} saved</small>

			<button onClick={() => handleSaveAll()}>Save all</button>
		</div>

		<div className='GridTable BulkEntries' style={{ "--columns": collection.snippet.length }}>
			{ Object.entries(entries).map(([entryId, entry]) =>
				<BulkEntry key={entryId} 
					task={task} 
					entry={entry}
					setEntries={setEntries} 
				/>
			)}
		</div>

		{ loadingEntries && <Spinner>Loading entries...</Spinner> }

		{jason(entries, 'entries')}


	</>
}



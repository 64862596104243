
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { gql, useLazyQuery } from '@apollo/client'
import Cookies from 'js-cookie'

import SignInForm from './SignInForm'
import Spinner from '../utils/Spinner'
import { userAtom } from './atoms'

const GET_USER = gql`
	query GetUser($token: String!) {
		user(token: $token) {
			userId
			name
			email
			emailHash
			role
		}
	}`


export default function Auth({ children }) {

	const [user, setUser] = useRecoilState(userAtom)
	const [getUser, { loading, data }] = useLazyQuery(GET_USER, { 
		// fetchPolicy: 'cache-and-network' 
	})

	useEffect(() => {
		if (data?.user) {
			const token = localStorage.getItem('token')
			if (!!token) Cookies.set('token', token, { expires: 30, domain: '.content.catering' })
			setUser(data.user)
		}
	}, [data])

	useEffect(() => { 
		const token = localStorage.getItem('token')
		if (!!token) {
			try {
				getUser({ variables: { token } })
			} catch (e) { console.error(e) }
		}
	}, [])

	if (!!user) return children

	return <div id='AuthScreen'>

		<SignInForm />

		{ loading && <Spinner>Restoring previous session...</Spinner> }

	</div>
}


import { useMutation } from '@apollo/client'
import { produce } from 'immer'

import useCollection from './useCollection'
import { GET_COLLECTION } from './CollectionProvider'
import Alert from '../utils/Alert'
import Spinner from '../utils/Spinner'
import { EDIT_COLLECTION } from './EditCollection'


export default function ConfigEntryTitle() {

	const { collection } = useCollection()
	const { collectionId } = collection
	const [editCollection, { error, loading }] = useMutation(EDIT_COLLECTION, {
		refetchQueries: [{ query: GET_COLLECTION, variables: { collectionId } }]
	})

	const fields = collection?.title?.map(({ fieldId }) => fieldId) ?? []

	async function handleChange(fieldId, checked) {
		const title = produce(fields, draft => {
			if (!Array.isArray(draft)) draft = []
			const idx = draft.findIndex(fId => fId === fieldId)
			if (checked && idx === -1) draft.push(fieldId)
				else if (!checked && idx > -1) draft.splice(idx, 1)
		})
		await editCollection({ variables: { collectionId, values: { title } } })
	}

	return <>

		<h3>Entry title</h3>

		<div className='EntrySnippetEdit'>
			{ collection.fields.map(({ fieldId, config: { name } }) => 
				<label key={fieldId}>
					<input type='checkbox' 
						checked={fields.includes(fieldId)}
						onChange={e => handleChange(fieldId, e.target.checked)}
					/> {name}
				</label>
			)}
		</div>

		{ error && <Alert error={error} /> }
		{ loading && <Spinner /> }

		{/* <pre>{JSON.stringify(collection, null, 2)}</pre> */}

	</>
}



export default function IdOptions({ config, update }) {

	return <div className='FieldOptions FieldOptionsCustom'>

		<fieldset>
			<legend>Prefix:</legend>
			<input type='text' value={config.prefix || ''}
				onChange={e => update(d => { d.prefix = e.target.value })} 
			/>
		</fieldset>

	</div>
}
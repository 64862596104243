
import { useContext } from 'react'

import useCollection from '../collections/useCollection'
import EntryContext from '../entries/EntryContext'
import generateGPTPrompt from '../../common/utils/generateGPTPrompt'

const GPT_MODELS = [
	'gpt-4-turbo-preview',
	'gpt-4',
	'gpt-4-1106-preview',
	'gpt-3.5-turbo-1106',
]


export default function ChatGPT({ config, setConfig }) {

	const { collection: { fields } } = useCollection()
	const { draft } = useContext(EntryContext)
	const ioPrompt = generateGPTPrompt(config, fields, draft)

	function toggleFields(which, id) {
		setConfig(d => {
			if (!d[which]) d[which] = []
			if (d[which].includes(id)) d[which].splice(d[which].indexOf(id), 1)
				else d[which].push(id)
		})
	}

	return <>

		<fieldset>
			<legend>Model:</legend>
			<select value={config.model} onChange={e => setConfig(d => { d.model = e.target.value })} className='w-fit'>
				{ GPT_MODELS.map(model =>
					<option key={model} value={model}>{model}</option>
				)}
			</select>
		</fieldset>

		<fieldset>
			<legend>Instructions:</legend>
			<textarea value={config.instructions} onChange={e => setConfig(d => { d.instructions = e.target.value })} placeholder="You are a helpful assistant..." />
		</fieldset>

		<fieldset>
			<legend>Prompt:</legend>
			<textarea value={config.prompt} onChange={e => setConfig(d => { d.prompt = e.target.value })} rows={10} className='w-100' />
		</fieldset>
		
		<fieldset className='InOutFields'>
			<div>
				<legend>Input fields:</legend>
				<ul>
					{ fields.map(({fieldId, config: { name }}) =>
						<li key={fieldId}><label>
							<input type='checkbox'
								checked={!!config.input?.includes(fieldId)}
								onChange={() => toggleFields('input', fieldId)}
							/> {name}
						</label></li>
					)}
				</ul>
			</div>
			<div>
				<legend>Output fields:</legend>
				<ul>
					{ fields.map(({fieldId, config: { name }}) =>
						<li key={fieldId}><label>
							<input type='checkbox'
								checked={!!config.output?.includes(fieldId)}
								onChange={() => toggleFields('output', fieldId)}
								/> {name}
						</label></li>
					)}
				</ul>
			</div>
		</fieldset>

		<fieldset>
			<details>
				<summary>Input/Output prompt</summary>
				<pre className='wrap'>{ioPrompt.trim()}</pre>
			</details>
		</fieldset>

	</>
}


import ID from './IdField.js'
import URI from './UriField.js'
import TEXT from './TextField.js'
import HTML from './HtmlField.js'
import JSON from './JsonField.js'
import FILE from './FileField.js'
import STRUCT from './StructField.js'
import NUMERIC from './NumericField.js'
import BOOLEAN from './BooleanField.js'
import MODIFIED from './ModifiedField.js'
import DATETIME from './DatetimeField.js'
import REFERENCE from './ReferenceField.js'

const FIELDS = {
	ID,
	URI,
	TEXT,
	HTML,
	JSON,
	FILE,
	STRUCT,
	NUMERIC,
	BOOLEAN,
	MODIFIED,
	DATETIME,
	REFERENCE,
}

export default FIELDS

export const FIELD_TYPES = Object.keys(FIELDS)


import { useEffect } from 'react'
import { RecoilRoot } from 'recoil'
import { defaultDataIdFromObject, ApolloClient, InMemoryCache, ApolloProvider, split, HttpLink } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { enableMapSet } from 'immer'

enableMapSet()

import Auth from './Auth'
import Navbar from './Navbar'
import HomePage from './HomePage'
import BuildRouter from '../build/BuildRouter'
import CollectionsPage from '../collections/CollectionsPage'
import CollectionRouter from '../collections/CollectionRouter'
import FilesPage from '../files/FilesPage'
import UrlsRouter from '../urls/UrlsRouter'
import UsersPage from '../users/UsersPage'
import AccountPage from '../users/AccountPage'
import LogsPage from '../logs/LogsPage'
import ImportPage from '../import/ImportPage'
import ErrorBoundary from '../utils/ErrorBoundary'


function getApolloClient() {

	const GQL_URL = `${process.env.DAISY_CMS_URL}${process.env.DAISY_CMS_ADMIN_GQL_URI}`
	const WS_URL = `${process.env.DAISY_CMS_URL}/subscriptions`.replace(/^http/, 'ws')

	const httpLink = new HttpLink({ uri: GQL_URL })
	
	const wsLink = new GraphQLWsLink(createClient({
		url: WS_URL,
		connectionParams: { token: localStorage.getItem('token') || null }
	}))

	const link = split(({ query }) => {
		const definition = getMainDefinition(query)
		return (definition.kind === 'OperationDefinition' && definition.operation === 'subscription')
	}, wsLink, httpLink)

	const authLink = setContext((_, { headers }) => ({
		headers: {
			...headers,
			Authorization: `Bearer ${localStorage.getItem('token') || ''}`
		}
	}))

	return new ApolloClient({
		link: authLink.concat(link),
		cache: new InMemoryCache({
			dataIdFromObject(responseObject) {
				switch (responseObject.__typename) {
					// case 'Entry': return `Entry:${responseObject.collectionId || ''}:${responseObject.entryId}`
					case 'Build': return `Build:${responseObject.buildId}`
					case 'Collection': return `Collection:${responseObject.collectionId}`
					default: return defaultDataIdFromObject(responseObject)
				}
			},
			typePolicies: {
				// Entry: { keyFields: ['entryId', 'collectionId'] },
				// Collection: { keyFields: ['collectionId'] },
				Build: { merge: true, },
				// This fucks up bulk edit
				// Query: {
				// 	fields: {
				// 		entries: {
				// 			...offsetLimitPagination(['collectionId', 'entryIds']),
				// 			read(existing, { args: { offset, limit }}) {
				// 				// return undefined to signal that the query missed cache
				// 				return existing && existing.slice(offset, offset + limit)
				// 			},
				// 		}
				// 	},
				// }
			}
		}),
		defaultOptions: {
			watchQuery: {
				fetchPolicy: 'cache-and-network',
			}
		},
		connectToDevTools: false,
	})
}

const apolloClient = getApolloClient()


export default function App() {

	useEffect(() => {
		const link = document.createElement('link')
		link.rel = 'shortcut icon'
		link.href = `${process.env.DAISY_CMS_URL}/favicon.png`
		link.type = 'image/png'
		document.head.appendChild(link);
		return () => document.head.removeChild(link)
	}, [])

	return <BrowserRouter>
		<ApolloProvider client={apolloClient}>
			<RecoilRoot>
				<Auth>
					<Navbar />
					<div id='Main'>
						<ErrorBoundary>
							<Routes>
								<Route index element={<HomePage />} />
								<Route path='/collection/:collectionId/*' element={<CollectionRouter />} />
								<Route path='/build/*' element={<BuildRouter />} />
								<Route path='/collections' element={<CollectionsPage />} />
								<Route path='/files/*' element={<FilesPage />} />
								<Route path='/urls/*' element={<UrlsRouter />} />
								<Route path='/import' element={<ImportPage />} />
								<Route path='/logs' element={<LogsPage />} />
								<Route path='/users/*' element={<UsersPage />} />
								<Route path='/account' element={<AccountPage />} />
								<Route path='*' element={<div><h1>404</h1><p>Page not found</p></div>} />
							</Routes>
						</ErrorBoundary>
					</div>
				</Auth>
			</RecoilRoot>
		</ApolloProvider>
	</BrowserRouter>
}

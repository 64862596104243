
import ExtractFiles from './ExtractFiles'
import ChatGPT from './ChatGPT'
import RegexReplace from './RegexReplace'
import MoveValues from './MoveValues'
import StripHTML from './StripHTML'

export default {
	ExtractFiles,
	ChatGPT,
	RegexReplace,
	MoveValues,
	StripHTML,
}


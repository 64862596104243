
import { useState, useMemo, useContext } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import EntryChanges from './EntryChanges'
import useCollection from '../collections/useCollection'
import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'
import { GET_ENTRIES } from './Entries'
import EntryContext, { EntryProvider, GET_ENTRY } from './EntryContext'
import Title from '../utils/Title'
import EntryTasks from './EntryTasks'
import getValuesDiff from '../../common/utils/getValuesDiff'
import DetailsButton from '../utils/DetailsButton'
import Field from '../fields/Field'
import jason from '../utils/jason'

export const UPDATE_ENTRY = gql`
	mutation UpdateEntry($collectionId:ID!, $entryId:ID!, $values:JSON!, $changes:JSON) {
		updateEntry(collectionId:$collectionId, entryId:$entryId, values:$values, changes:$changes)
	}`

const REMOVE_ENTRY = gql`
mutation RemoveEntry($collectionId: ID!, $entryId: ID!) {
		removeEntry(collectionId: $collectionId, entryId: $entryId)
	}`


export default function EntryPage() {
	const { collectionId, entryId } = useParams()
	return <EntryProvider collectionId={collectionId} entryId={entryId}>
		<EntryPageContent />
	</EntryProvider>
}


function EntryPageContent() {

	const navigate = useNavigate()
	const { collection } = useCollection()
	const { 
		collectionId, 
		entryId, 
		entry, 
		draft, 
		setDraft,
		title,
	} = useContext(EntryContext)
	
	const [isSaved, setIsSaved] = useState(false)
	const [showChanges, setShowChanges] = useState(false)
	const [showTasks, setShowTasks] = useState(false)

	const changes = useMemo(() => {
		if (!entry?.values) return null
		const changes = getValuesDiff(entry.values, draft)
		return !changes.length ? null : changes
	}, [entry, draft])

	const [updateEntry, { loading: loadingSave, error: saveError }] = useMutation(UPDATE_ENTRY, {
		refetchQueries: [{ query: GET_ENTRY }]
	})
	async function handleSave() {
		const saveResponse = await updateEntry({ variables: { collectionId, entryId, values: draft, changes }})
		if (saveResponse?.data?.updateEntry) {
			setIsSaved(true)
		}
	}

	const [removeEntry, { loading: loadingRemove, error: removeError }] = useMutation(REMOVE_ENTRY, {
		variables: { collectionId, entryId },
		refetchQueries: [{ query: GET_ENTRIES, variables: { collectionId } }]
	})
	async function handleRemove() {
		if (!window.confirm(`Delete entry ${entryId}?`)) return false
		await removeEntry()
		navigate(`/collection/${collectionId}`)
	}

	return <>

		<Title>{title}</Title>

		<h2 className='EntryTitle'>{title}</h2>
		
		<div className={classNames('EntryMenu', { sticky: !!changes })}>

			<DetailsButton isOpen={showTasks} toggle={() => setShowTasks(v => !v)}>
				Tasks
			</DetailsButton>

			<DetailsButton isOpen={showChanges} toggle={() => setShowChanges(v => !v)}>
				Changes
			</DetailsButton>
			
			<button onClick={handleSave} 
				disabled={!changes || loadingSave}
				className={!!changes ? 'primary' : ''}
			>
				Save
			</button> 

			{ loadingSave
				? <Spinner />
				: isSaved
				? <span>Changes saved.</span>
				: <span className='Changes'>{ !!changes
						? `${changes.length} change${ changes.length > 1 ? "s" : "" }`
						: ""
					}</span>
			}

		</div>

		{ saveError && <Alert error={saveError} /> }

		{ showChanges && <EntryChanges /> }
		{ showTasks && <EntryTasks /> }

		<div className='EntryFields'>
			{ collection.fields.map(field => 

				<Field key={field.fieldId}
					field={field} 
					value={draft[field.fieldId]} 
					update={value => setDraft(d => { d[field.fieldId] = value })} 
					// status={!!changes && changes.some(c => c.path[0] === field.fieldId) ? 'changed' : 'none'}
				/>

			)}		
		</div>

		<hr className='dangerZone' />

		<button onClick={handleRemove} className='delete small'>Delete entry</button>
		{ removeError && <Alert error={removeError} /> }
		{ loadingRemove && <Spinner /> }

		<hr />

		{jason(entry, 'entry')}
		{jason(draft, 'draft')}

	</>
}


import { useState } from 'react'
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move'

import FieldItem from '../fields/FieldItem'
import FieldEditor from '../fields/FieldEditor'


export default function FieldsEditor({ 
	fields = [],
	handleAddField,
	handleSortEnd,
}) {

	// const { collection } = useCollection()
	const [newField, setNewField] = useState(null)
	
	// const [reorderFields, { loading: loadingReorder, error: reorderError }] = useMutation(REORDER_FIELDS, {
	// 	refetchQueries: [{ query: GET_COLLECTION, variables: { collectionId: collection.collectionId } }],
	// })
	// const [addField, { loading: loadingAdd, error: addError }] = useMutation(ADD_FIELD, {
	// 	refetchQueries: [{ query: GET_COLLECTION, variables: { collectionId: collection.collectionId } }],
	// })

	// function handleSortEnd(oldIndex, newIndex) {
	// 	const newOrder = arrayMoveImmutable(fields, oldIndex, newIndex)
	// 	const positions = newOrder.map(({fieldId}) => fieldId)
	// 	// reorderFields({ variables: { collectionId: collection.collectionId, positions } })
	// }

	async function handleSaveAddField(values) {
		if (await handleAddField(values)) setNewField(null)
	}

	return <>

		<SortableList className='GridTable FieldsList' draggedItemClassName='dragged'
			onSortEnd={handleSortEnd} 
		>
			{ Array.isArray(fields) && fields.map(f => 
				<SortableItem key={f.fieldId}>
					<div className='Item'>
						<SortableKnob>
							<div> ↕️ </div>
						</SortableKnob>
						<FieldItem field={f} 

						/>
					</div>
				</SortableItem>
			)}
		</SortableList>
		
		{ !newField && <button onClick={() => setNewField(pf => !!pf ? null : {} )}>
			Add field
		</button>}

		{ !!newField &&
			<FieldEditor field={newField} 
				handleSave={handleSaveAddField} 
				onCancel={() => setNewField(null)} 
			/>
		}

	</>
}

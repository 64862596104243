
import Field from './Field.js'


export default class BooleanField extends Field {

	type = 'BOOLEAN'
	_columnType = 'BOOLEAN'
	_gqlType = 'Boolean'
	
	constructor(initVals) {
		super(initVals)
	}

	_parseValue(value) {
		return !!value
	}

	_serializeValue(value) {
		return value ? 1 : 0
	}
}

import { Routes, Route } from 'react-router-dom'

import UsersList from './UsersList'
import AddUser from './AddUser'
import UserPage from './UserPage'


export default function UsersPage() {
	return <>
		<Routes>
			<Route index element={<UsersList />} />
			<Route path='/add' element={<AddUser />} />
			<Route path='/:userId' element={<UserPage />} />
		</Routes>
	</>
}

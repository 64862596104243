
export default function jason(value, title) {
	let summary = title
	if (!summary && typeof value === 'object') {
		const keys = Object.keys(value)
		summary = keys.length > 0 ? keys.length === 1 ? keys[0] : `${keys[0]}...` : "json"
	}
	return <details>
		<summary>{summary}</summary>
		<pre>
			{JSON.stringify(value, null, 2)}
		</pre>
	</details>
}

import { gql, useMutation } from '@apollo/client'
import { useSetRecoilState } from 'recoil'

import QueryStatus from '../utils/QueryStatus'
import Spinner from '../utils/Spinner'
import { userAtom } from './atoms'


const SIGN_IN = gql`
	mutation SignIn($email: String!, $password: String!) {
		signIn(email: $email, password: $password) {
			user {
				userId
				name
				email
				emailHash
				role
			}
			token
		}
	}`

function setCookie(name, value, days = 7) {
	const date = new Date();
	date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
	// TODO: enable SSL
	// document.cookie = `${name}=${value}${expires}; path=/; SameSite=Strict; Secure`;
	const daisyUrl = process.env.DAISY_CLIENT_URL
	const mainDomainAndTld = daisyUrl.match(/([a-z0-9-]+\.[a-z0-9-]+)$/i)[1]
	// console.log(mainDomainAndTld)
	document.cookie = `${name}=${value}; expires=${date.toUTCString()}; domain=.${mainDomainAndTld}; path=/;`;
}


export default function SignInForm() {

	const [signIn, { loading, error }] = useMutation(SIGN_IN)
	const setUser = useSetRecoilState(userAtom)

	function handleSubmit(event) {
		event.preventDefault()
		const formData = new FormData(event.target)
		const email = formData.get('email')
		const password = formData.get('password')	
		handleSignIn(email, password)
	}

	async function handleSignIn(email, password) {
		try {
			const { data } = await signIn({ variables: { email, password }})
			localStorage.setItem('token', data.signIn.token)
			setCookie('token', data.signIn.token, 7)
			setUser(data.signIn.user)
		} catch (error) { console.error(error) }
	}

	return <>
	
		<form onSubmit={handleSubmit} className='Form'>

			<fieldset>
				<legend>
					Email
				</legend>
				<input required
					name='email'
					type='email'
					autoComplete='username'
					className='Input'
				/>
			</fieldset>
			
			<fieldset>
				<legend>
					Password
				</legend>
				<input required
					name='password'
					type='password'
					className='Input'
					autoComplete='current-password'
				/>
			</fieldset>

			<div className='Submit'>

				<button type='submit' 
					className='Primary'
					disabled={!!loading}
				>
					Sign in
				</button>

				{ loading && <Spinner>Authenticating...</Spinner> }

			</div>

		</form>

		<QueryStatus error={error} />

	</>
}


// const FILETYPES = [
// 	'jpg', 'png', 'webp', 'mp3', 'psd',
// ]

// const FileALLOWED = [
// 	'minValue',
// 	'maxValue',
// 	'step',
// 	'prefix',
// 	'unit',	
// ]

// export { FileALLOWED }

export default function FileOptions({ field, update }) {

	// function onChangeFiletype({ target: { value, checked }}) {
	// 	update(draft => {
	// 		if (!Array.isArray(draft.allowedFiletypes)) draft.allowedFiletypes = []
	// 		const idx = draft.allowedFiletypes.findIndex(ext => ext === value)
	// 		if (checked && idx === -1) {
	// 			draft.allowedFiletypes.push(value)
	// 		} else if (!checked && idx > -1) {
	// 			draft.allowedFiletypes.splice(idx, 1)
	// 		}
	// 	})
	// }

// 	function handleChange({ target: { name, value, type } }) {
// 		if (value === '') return update(draft => { delete draft[name] })
// 		if (type === 'number') value = Number(value)
// 		if (name === 'suggested') value = value.split(`
// `).map(v => v.trim())
// 		update(draft => { draft[name] = value })
// 	}

	return <>

		{/* <div>
			Allowed file types:
			{ FILETYPES.map(ext => 
				<label key={ext}>
					<input type='checkbox'
						value={ext}
						checked={Array.isArray(field.allowed) && field.allowed.includes(ext)}
						onChange={onChangeFiletype}
					/> {ext}
				</label>
			)}
		</div> */}

	</>
}

import { useState, useMemo } from 'react'
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { useImmer } from 'use-immer'
import { Link } from 'react-router-dom'

import Files from '../files/Files'
import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'
import jason from '../utils/jason'
import shallowMerge from '../../common/utils/shallowMerge'
import ImportMapNew from './ImportMapNew'
import ImportMapExisting from './ImportMapExisting'
import { GET_IMPORT_STUFF } from './ImportPage'

const GET_COLLECTIONS = gql`
	query GetCollectionsWithFields {
		collections {
			collectionId
			name
			icon
			fields
		}
	}
`

const GET_FILE_PROFILE = gql`
	query GetFileProfile($config:JSON!) {
		fileProfile(config:$config)
	}`

const UPDATE_IMPORT_MAP = gql`
	mutation UpdateImportMap($importMapId:ID!, $config:JSON!) {
		updateImportMap(importMapId:$importMapId, config:$config)
	}`
const DELETE_IMPORT_MAP = gql`
	mutation DeleteImportMap($importMapId:ID!) {
		deleteImportMap(importMapId:$importMapId)
	}`
const START_IMPORT = gql`
	mutation StartImport($config:JSON!) {
		startImport(config:$config)
	}`

const DEFAULT_IMPORT_MAP_CONFIG = {
	name: "Import Map",
	filename: '',
	destination: '',
	insertStrategy: 'insert',
	linkBy: 'id',
	uniqueErrorStrategy: 'error',
	// parser: '',
	fieldsMap: {}
}

// const PARSERS = ['JSON', 'CSV', 'WordPressXML']

const INSERT_STRATEGIES = ['insert', 'merge']

const UNIQUE_ERROR_STRATEGIES = {
	'error':	"Throw an error. Abort the import.",
	'skip':	"Ignore the error. Skip the entry.",
	'create':	"Create a new entry. Replace duplicate ID with a random ID.",
	// 'update':	"Update the existing entry with the new data. Keep unchanged data.",
	'replace':	"Replace the existing entry with the new data.",
}


export default function ImportMap({ importMap, files = []}) {

	const [editingName, setEditingName] = useState(false)
	const [config, setConfig] = useImmer(shallowMerge(DEFAULT_IMPORT_MAP_CONFIG, importMap.config))
	// const [fieldsMap, setFieldsMap] = useImmer(config.fieldsMap)
	const hasChanged = useMemo(() => {
		return JSON.stringify(importMap.config) !== JSON.stringify(config)
	}, [importMap.config, config])
	const [showFiles, setShowFiles] = useState(false)

	const { loading: loadingCollections, error: collectionsError, data:collectionsData } = useQuery(GET_COLLECTIONS)
	const collections = collectionsData?.collections || []
	const collection = collections.find(c => c.collectionId === config.destination)

	const [getFileProfile, { data, loading, error }] = useLazyQuery(GET_FILE_PROFILE)
	const profile = data?.fileProfile || {}

	const [deleteImportMap, { loading: loadingDelete, error: deleteError }] = useMutation(DELETE_IMPORT_MAP, {
		variables: { importMapId: importMap.importMapId },
		refetchQueries: [GET_IMPORT_STUFF]
	})
	const [updateImportMap, { loading: loadingUpdate, error: updateError }] = useMutation(UPDATE_IMPORT_MAP, {
		refetchQueries: [GET_IMPORT_STUFF]
	})
	const [startImport, { loading: loadingImport, error: importError, data: importData }] = useMutation(START_IMPORT, {
		refetchQueries: [GET_COLLECTIONS],
	})

	async function handleDelete() {
		if (confirm("Delete Import Map?")) await deleteImportMap()
	}
	async function handleUpdate() {
		await updateImportMap({ variables: { importMapId: importMap.importMapId, config } })
	}	
	async function handleImport() {
		if (!config.destination) return alert("Please select a destination collection name")
		await startImport({ variables: { config }})
	}

	function setFieldsMap(nextFieldsMap) {
		setConfig(d => { d.fieldsMap = nextFieldsMap })
	}

	function handleSelectFile(filenames) {
		// console.log('handleSelectFile', filename)
		if (Array.isArray(filenames))
			setConfig(d => { d.filename = filenames[0] })
		setShowFiles(false)
	}

	// const hasIdField = useMemo(() => {
	// 	return !!config.fieldsMap && !!Object.values(config.fieldsMap).find(f => f.fieldId === 'id' && f.type === 'ID')
	// }, [config.fieldsMap])

	// useEffect(() => {
	// 	if (!!config?.filename) getFileProfile({ variables: { filename: config.filename } })
	// }, [config])

	return <div className='ImportMap'>

		<div className='IMHeader'>
			{ !editingName
				?	<h3>{config?.name || "An Import Map"} <button onClick={() => setEditingName(true)}>edit</button></h3>
				:	<input type='text' value={config?.name || ''} 
						onChange={e => setConfig(d => { d.name = e.target.value })} 
						onBlur={() => setEditingName(false)}
					/>
			}
		</div>

		<div>

			<h3>Source</h3>

			<fieldset>
				<legend>Source file:</legend>
				<p><em>{config?.filename}</em> <button onClick={() => setShowFiles(p => !p)}>Select file</button></p>
				{ showFiles && <>
					<Files 
						isEmbed={true}
						isModal={true}
						onSelected={handleSelectFile}
						initialPath={'/'}
						initialSelected={config?.filename}
					/> 
				</>}
				{/* <select value={config?.filename} onChange={e => setConfig(d => { d.filename = e.target.value })}>
					<option value='' disabled>Select a file</option>
					{ files.map(file => <option key={file.filename} value={file.filename}>
						{file.filename}
					</option>) }
				</select> */}
				<br />
				{/* Parser: <select value={config?.filename} onChange={e => setConfig(d => { d.filename = e.target.value })}>
					<option value=''>auto</option>
					{ PARSERS.map(parser => <option key={parser} value={parser}>{parser}</option>) }
				</select>
				<br /> */}

				{ !!config?.filename && <div>
					{ config?.filename?.substr(-4) === '.json' && <div>
						Address: <input type='text'
							value={config?.address || ''} 
							onChange={e => setConfig(d => { d.address = e.target.value })} />
					</div>}
					{ config?.filename?.substr(-4) === '.csv' && <div>
						Delimiter: <input type='text'
							value={config?.delimiter || ''} 
							placeholder=','
							onChange={e => setConfig(d => { d.delimiter = e.target.value })} />
					</div>}
				</div>}

				<button onClick={() => getFileProfile({ variables: { config } })}>Load</button> 

				{ loading && <Spinner /> }
				{ error && <Alert error={error} /> }
			</fieldset>

		</div>

		<div>

			<h3>Destination</h3>

			<fieldset>
				<legend>Destination:</legend>
				<select value={config.destination} onChange={e => setConfig(d => { d.destination = e.target.value })}>
					<option value=''>New collection</option>
					{ collections.map(({ collectionId, name, icon }) => <option key={collectionId} value={collectionId}>{icon} {name}</option>) }
				</select>
				{ !collection && <div>
					Collection name:
					<input type='text' value={config.destination || ''} onChange={e => setConfig(d => { d.destination = e.target.value })} />
				</div>}

				{ !!collection && <div>

					Insert strategy:
					<select value={config.insertStrategy} onChange={e => setConfig(d => { d.insertStrategy = e.target.value })}>
						{ INSERT_STRATEGIES.map(s => <option key={s} value={s}>{s}</option>) }
					</select>
					
					{ config.insertStrategy === 'insert' && <div>
						Unique constraint fail handling strategy:
						<select value={config.uniqueErrorStrategy} onChange={e => setConfig(d => { d.uniqueErrorStrategy = e.target.value })}>
							{ Object.keys(UNIQUE_ERROR_STRATEGIES).map(s => <option key={s} value={s}>{s}</option>) }
						</select> <small>{ UNIQUE_ERROR_STRATEGIES[config.uniqueErrorStrategy] }</small>
					</div>}

					{ config.insertStrategy === 'merge' && <div>
						Link by field:
						<input type='text' value={config.linkBy || ''} onChange={e => setConfig(d => { d.linkBy = e.target.value })} />
					</div>}

				</div>}

			</fieldset> 

		</div>
		
		{ !!collection
			?	<ImportMapExisting sourceFields={profile?.stats} collection={collection}
					fieldsMap={config?.fieldsMap} setFieldsMap={setFieldsMap}  />
			:	<ImportMapNew sourceFields={profile?.stats} 
					fieldsMap={config?.fieldsMap} setFieldsMap={setFieldsMap} />
		}

		<div className='IMFooter'>
			<p>
				<button onClick={() => handleDelete()} className='delete'>Delete</button>
				<button onClick={() => handleUpdate()} disabled={!hasChanged} className={hasChanged ? 'primary' : ''}>Save</button>
				<button onClick={() => handleImport()} disabled={!config?.filename || loadingImport} className='primary'>Import</button>
			</p>
			{ loadingUpdate && <Spinner /> }
			{ loadingDelete && <Spinner /> }
			{ loadingImport && <Spinner>Importing...</Spinner> }
			{ importError && <Alert error={importError} /> }
			{ updateError && <Alert error={importError} /> }
			{ deleteError && <Alert error={deleteError} /> }

			{ !!importData?.startImport && <span className='success'>
				Imported {importData.startImport?.entryCount} entries to <Link to={`/collection/${importData.startImport?.collectionId}`}>{importData.startImport?.collectionId}</Link>.
			</span>}

			{jason(importMap, 'importMap')}
			{jason(config, 'config')}
			{jason(profile, 'profile')}

		</div>

	</div>
}


import useCollection from '../collections/useCollection'


export default function EditHTML({ config, setConfig }) {

	const { collection: { fields } } = useCollection()

	function toggleField(fieldId) {
		setConfig(config => {
			if (!config.fields) config.fields = []
			if (config.fields.includes(fieldId)) {
				config.fields.splice(config.fields.indexOf(fieldId), 1)
			} else {
				config.fields.push(fieldId)
			}
		})
	}

	// function toggleFlag(flag) {
	// 	setConfig(config => {
	// 		if (!config.flags) config.flags = ''
	// 		if (config.flags.includes(flag)) config.flags = config.flags.replace(flag, '')
	// 			else config.flags += flag
	// 	})
	// }

	return <>

		<p>Modify HTML code.</p>

		{/* <fieldset>
			<legend>Remove tags:</legend>
		</fieldset> */}

		<fieldset>
			<legend>Target fields:</legend>
			<ul>
				{ fields.map(({fieldId, config: { name }}) =>
					<li key={fieldId}><label>
						<input type='checkbox'
							checked={!!config.fields?.includes(fieldId)}
							onChange={() => toggleField(fieldId)}
						/> {name}
					</label></li>
				)}
			</ul>
		</fieldset>

	
	</>
}
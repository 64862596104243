

export default function shallowMerge(obj1 = {}, initObj2, depth = 1) {
	
	let merged = { ...obj1 }

	let obj2 = initObj2

	if (typeof obj2 === 'string' && obj2.length > 0) {
		try { obj2 = JSON.parse(obj2) }
			catch (_) { obj2 = null }
	}


	if (typeof obj2 !== 'object' || obj2 === null)
		return merged

	for (let key in obj2) {
		if (obj2.hasOwnProperty(key)) {
			// If both objects have the same key and their values are objects, merge them
			if (depth > 0 && typeof obj2[key] === 'object' && obj2[key] !== null && typeof merged[key] === 'object' && merged[key] !== null) {
				merged[key] = {
					...merged[key],
					...obj2[key]
				}
			} else {
				// Otherwise, simply set/overwrite the value in the merged object
				merged[key] = obj2[key]
			}
		}
	}

    return merged
}
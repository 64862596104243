
import useCollection from '../collections/useCollection'


export default function ExtractFiles({ config, setConfig }) {

	const { collection: { fields } } = useCollection()

	function toggleField(fieldId) {
		setConfig(config => {
			if (!config.fields) config.fields = []
			if (config.fields.includes(fieldId)) {
				config.fields.splice(config.fields.indexOf(fieldId), 1)
			} else {
				config.fields.push(fieldId)
			}
		})
	}

	return <>

		<p>This will search for urls to external files, download them to <em>Files</em>, and replace the url with the new <em>Files</em> path.</p>

		<fieldset>
			<legend>Target fields:</legend>
			<ul>
				{ fields.map(({fieldId, config: { name }}) =>
					<li key={fieldId}><label>
						<input type='checkbox'
							checked={!!config.fields?.includes(fieldId)}
							onChange={() => toggleField(fieldId)}
						/> {name}
					</label></li>
				)}
			</ul>
		</fieldset>

		<fieldset>
			<legend>Overwrite destination folder:</legend>
			<input type='text' 
				value={config.destFolder} 
				onChange={e => setConfig(c => { c.destFolder = e.target.value })} 
				placeholder="e.g. /article-images/"
			/>
		</fieldset>

	</>
}
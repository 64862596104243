
// import { useState } from 'react'
import { Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import Title from '../utils/Title'
import Spinner from '../utils/Spinner'
import Alert from '../utils/Alert'
import Icon from '../utils/Icon'
// import Redirect from './Redirect'


const GET_URL_LIST = gql`
	query UrlList {
		urlList
	}
`

export default function URLsPage() {

	const { data, loading, error } = useQuery(GET_URL_LIST)
	
	return <>

		<Title>URLs</Title>

		<Alert error={error} />

		{ !!data?.urlList && <>

			<p>{ data.urlList.length === 0 ? "No URLs." : `Total: ${data.urlList.length}`}</p>

			<div className='GridTable UrlsList'>
				{ data.urlList.map((url, idx) => 
					<UrlLink key={`${url[0]  || ''}/${url[1]}/${idx}`} url={url} /> )}
			</div>

		</>}

		{ loading && <Spinner /> }
	</>
}


function UrlLink({ url: [prefix, uri, entryId, collectionId, collectionName, redirects] }) {

	const urlPath = `${prefix || ''}${uri}`
	// const [showRedirects, setShowRedirects] = useState(false)

	return <>

		<div className='UrlsListItem Item'>

			<a href={`${process.env.DAISY_WEBSITE_URL}/${urlPath}`} target='_blank'>
				<Icon i='out' />
			</a>

			<div>{collectionName}</div>

			{/* { !redirects ? <div></div>
				: 	<a onClick={() => setShowRedirects(v=>!v)} className='redirectsBtn'>
						<Icon i='redirect' />{redirects.length}
					</a>
			} */}

			<Link to={`/collection/${collectionId}/id/${entryId}`}>
				{`/${prefix || ''}${uri}/`}
			</Link>

		</div>

		{/* { showRedirects && <div className='UrlsListRedirects'>
			{ redirects.map(r => <Redirect key={r.redirectId} redirect={r} noDest />) }
		</div> } */}
	</>
}

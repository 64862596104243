
import { useState } from 'react'
import useCollection from '../collections/useCollection'





export default function MoveValues({ config, setConfig }) {

	// const { collection: { fields } } = useCollection()
	// const 

	// function toggleField(fieldId) {
	// 	setConfig(config => {
	// 		if (!config.fields) config.fields = []
	// 		if (config.fields.includes(fieldId)) {
	// 			config.fields.splice(config.fields.indexOf(fieldId), 1)
	// 		} else {
	// 			config.fields.push(fieldId)
	// 		}
	// 	})
	// }

	// function toggleFlag(flag) {
	// 	setConfig(config => {
	// 		if (!config.flags) config.flags = ''
	// 		if (config.flags.includes(flag)) config.flags = config.flags.replace(flag, '')
	// 			else config.flags += flag
	// 	})
	// }

	return <>

		<p>Copy, move, and remove fields' values.</p>

		<fieldset>
			<legend>Actions:</legend>

			{/* <ul>
				{ fields.map(({fieldId, config: { name }}) =>
					<li key={fieldId}><label>
						<input type='checkbox'
							checked={!!config.fields?.includes(fieldId)}
							onChange={() => toggleField(fieldId)}
						/> {name}
					</label></li>
				)}
			</ul> */}

			{ Array.isArray(config.actions) && <ul>
				{ config.actions.map((a, idx) => <li key={`${a.command}-${a.fromFieldId}-${a.toFieldId}-${idx}`}>
					<button onClick={() => setConfig(d => { d.actions.splice(idx, 1) })}>x</button>
					<Action action={a} handleUpdate={action => setConfig(d => { d.actions[idx] = action })} />
				</li>)}
			</ul> }

			<button onClick={() => setConfig(d => {
				if (!Array.isArray(d.actions)) d.actions = []
				d.actions.push({})
			})}>Add action</button>

		</fieldset>

	
	</>
}

const COMMANDS = ['copy', 'move', 'remove']

function Action({ action = {}, handleUpdate }) {

	const { collection: { fields } } = useCollection()
	// const [command, setCommand] = useState(action.command || COMMANDS[0])
	// const [fromFieldId, setFromFieldId] = useState(action.fromFieldId || '')
	// const [toFieldId, setToFieldId] = useState(action.toFieldId || '')

	function setValue(key, value) {
		handleUpdate({ ...action, [key]: value })
	}

	return <>

		<select value={action.command || ''} onChange={e => setValue('command', e.target.value)}>
			<option value=''></option>
			{ COMMANDS.map(c => <option key={c} value={c}>{c}</option>) }
		</select> 
		from
		<select value={action.fromFieldId || ''} onChange={e => setValue('fromFieldId', e.target.value)}>
			<option value=''></option>
			{ fields.map(({fieldId, type, config: { name }}) => <option key={fieldId} value={fieldId}>{name} [{type}]</option>) }
		</select>

		{ (action.command !== 'remove') && <>
			to
			<select value={action.toFieldId} onChange={e => setValue('toFieldId', e.target.value)}>
				<option value=''></option>
				{ fields.map(({fieldId, type, config: { name }}) => <option key={fieldId} value={fieldId}>{name} [{type}]</option>) }
			</select>
		</>}

	</>
}
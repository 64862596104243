
import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useRecoilState } from 'recoil'

import ChangePassword from './ChangePassword'
import UserCard from './UserSnippet'
import Title from '../utils/Title'
import { userAtom } from '../layout/atoms'


function deleteCookie(name) {
	document.cookie = `${name}=; expires=Thu, 01 Jan 1970 04:20:00 UTC; path=/;`;
}

const SIGN_OUT = gql`
	mutation SignOut {
		signOut
	}
`

export default function AccountPage() {

	const [user, setUser] = useRecoilState(userAtom)
	const [signOut] = useMutation(SIGN_OUT)

	const handleSignOut = useCallback(async () => {
		try {
			await signOut()
		} catch (error) { 
			console.error(error) 
		} finally {
			setUser(null)
			localStorage.removeItem('token')
			deleteCookie('token')
		}
	}, [])

	return <>

		<Title>Your account</Title>

		<div className='Heading'>
			<h1>Your account</h1>
			<nav><button onClick={handleSignOut}>Sign out &rarr;</button></nav>
		</div>

		<UserCard user={user} />

		<hr />

		<ChangePassword />

	</>
}
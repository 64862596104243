
import { useParams } from 'react-router-dom'

import Files from './Files'
import Title from '../utils/Title'


export default function FilesPage() {

	const { '*': uri } = useParams()
	
	const path = decodeURIComponent(`/${uri}`)

	return <>

		<Title>Files</Title>
		
		<Files forcePath={path || '/'} />

	</>
}